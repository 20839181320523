import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appPortalFAQLink]',
})
export class PortalFAQLinkDirective {
  @HostListener('click')
  onClick(): void {
    window.open('https://www.techspert.com/portal-faqs', '_blank');
  }
}
