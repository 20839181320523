import { Component } from '@angular/core';

@Component({
  selector: 'app-assistant-unauthorised',
  template: ` <div class="auth-container flex flex-col">
    <h3>You need to be logged in to see this!</h3>

    <p>
      Click to login, or contact your project manager to set up your account.
    </p>

    <p>
      Any questions? Visit our
      <a href="https://www.techspert.com/portal-faqs" target="_blank">
        help portal.
      </a>
    </p>

    <div class="flex flex-row">
      <button
        class="flex grow"
        type="submit"
        mat-flat-button
        color="primary"
        routerLink="/secure"
      >
        Login
      </button>
    </div>
  </div>`,
  styles: [
    `
      .auth-container {
        max-width: 500px;
        margin: auto;
      }
    `,
  ],
})
export class AssistantUnauthorisedComponent {}
