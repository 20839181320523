import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUser } from '@techspert-io/auth';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { IClient } from '../../../shared/models/client.interface';
import { IOpportunity } from '../models/opportunity.interface';

export type DashboardClient = Pick<IClient, 'clientId' | 'clientName'>;

@Injectable()
export class OpportunityService {
  private readonly baseUrl = '/client-portal/opportunities';
  private contactsCache: Observable<IUser[]>;

  constructor(private http: HttpClient) {}

  getDashboardEntities(): Observable<{
    projects: IOpportunity[];
    clients: DashboardClient[];
  }> {
    return this.http.get<{
      projects: IOpportunity[];
      clients: DashboardClient[];
    }>('/client-portal/entities');
  }

  getOpportunityByDisplayId(
    publicOpportunityId: string
  ): Observable<IOpportunity> {
    return this.http.get<IOpportunity>(
      `${this.baseUrl}/${publicOpportunityId}`
    );
  }

  getClientContacts(publicOpportunityId: string): Observable<IUser[]> {
    if (!this.contactsCache) {
      this.contactsCache = this.http
        .get<IUser[]>(`${this.baseUrl}/${publicOpportunityId}/client-contacts`)
        .pipe(
          map((contacts) =>
            contacts.sort(
              (a, b) =>
                a.firstName.localeCompare(b.firstName) ||
                a.lastName.localeCompare(b.lastName)
            )
          ),
          shareReplay({ bufferSize: 1, refCount: true })
        );
    }
    return this.contactsCache;
  }

  getActiveOpportunities(): Observable<IOpportunity[]> {
    return this.http.get<IOpportunity[]>(`${this.baseUrl}/active`);
  }
}
