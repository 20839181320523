import { Pipe, PipeTransform } from '@angular/core';
import { marked } from 'marked';

@Pipe({ name: 'echoMarkdown' })
export class ExpertEchoMarkdownPipe implements PipeTransform {
  transform(value: string): string {
    return marked(value, {
      breaks: true,
    })
      .replace(/<h2/g, '<h2 class="marked-h2"')
      .trim();
  }
}
