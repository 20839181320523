import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NotificationComponent } from './components/notification/notification.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { MarkdownPipe } from './pipes/markdown.pipe';
import { NotificationService } from './services/notifications.service';

@NgModule({
  declarations: [NotificationsComponent, NotificationComponent, MarkdownPipe],
  exports: [NotificationsComponent, NotificationComponent, MarkdownPipe],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatSlideToggleModule,
    MatTooltipModule,
  ],
  providers: [NotificationService],
})
export class NotificationsModule {}
