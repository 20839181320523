import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-expert-hexagon',
  template: `<div
    [ngClass]="[viewStage, 'expert-icon']"
    class="flex flex-basis gap-2 justify-start items-center"
  >
    <div>
      <mat-icon data-test-id="expert-icon">{{ icon }}</mat-icon>
    </div>

    <div *ngIf="tagline" data-test-id="expert-tagline">
      <span class="font-tagline">{{ tagline }}</span>
    </div>
  </div>`,
  styleUrls: ['./expert-hexagon.component.scss'],
})
export class ExpertHexagonComponent {
  @Input() viewStage = 'new';
  @Input() tagline: string;

  get icon(): string {
    switch (this.viewStage) {
      case 'requires-approval':
        return 'lock';
      case 'new':
        return 'person_outline';
      case 'accepted':
        return 'schedule';
      case 'completed':
        return 'check_circle';
      case 'declined':
        return 'thumb_down';
      default:
        return 'help';
    }
  }
}
