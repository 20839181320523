import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FileModule } from '@techspert-io/files';
import { NavigationModule } from '@techspert-io/navigation';
import { PatternsModule } from '@techspert-io/patterns';
import { ClientResultComponent } from './components/omnisearch-result/client-result/client-result.component';
import { OmnisearchResultComponent } from './components/omnisearch-result/omnisearch-result.component';
import { OpportunityResultComponent } from './components/omnisearch-result/opportunity-result/opportunity-result.component';
import { OmnisearchResultsContainerComponent } from './components/omnisearch-results-container/omnisearch-results-container.component';
import { OmnisearchComponent } from './components/omnisearch/omnisearch.component';
import { BioEllipisPipe } from './pipes/bio-ellipsis.pipe';
import { EntityIconPipe } from './pipes/entityIcon.pipe';
import { PortalPhasePipe } from './pipes/portalPhase.pipe';
import { SearchHighlightPipe } from './pipes/search-highlight.pipe';
import { UrlTooltipPipe } from './pipes/url-tooltip.pipe';
import { OmnisearchService } from './services/omnisearch.service';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    RouterModule,
    PatternsModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatMenuModule,
    FileModule,
    NavigationModule,
    MatDialogModule,
  ],
  exports: [OmnisearchComponent, OmnisearchResultsContainerComponent],
  providers: [OmnisearchService],
  declarations: [
    OmnisearchComponent,
    OmnisearchResultComponent,
    UrlTooltipPipe,
    EntityIconPipe,
    PortalPhasePipe,
    BioEllipisPipe,
    OmnisearchResultsContainerComponent,
    SearchHighlightPipe,
    ClientResultComponent,
    OpportunityResultComponent,
  ],
})
export class OmnisearchModule {}
