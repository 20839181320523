import { Injectable } from '@angular/core';
import { IOpportunity, OpportunityService } from '@techspert-io/opportunities';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class OpportunityStoreService {
  private opportunityCache: Record<string, Observable<IOpportunity>> = {};

  constructor(private opportunityService: OpportunityService) {}

  getOpportunity(id: string): Observable<IOpportunity> {
    if (!this.opportunityCache[id]) {
      this.opportunityCache[id] = this.opportunityService
        .getOpportunityByDisplayId(id)
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }
    return this.opportunityCache[id];
  }

  getActiveOpportunities(): Observable<IOpportunity[]> {
    return this.opportunityService.getActiveOpportunities();
  }
}
