import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CognitoAuthService,
  IClientFeature,
  UserType,
} from '@techspert-io/auth';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
  IRedisExpertFields,
  OmnisearchQueryResponse,
} from '../models/omnisearch.models';

interface IRedisOpportunityFields {
  name?: boolean;
}

export enum QueryType {
  query = 'query',
}

@Injectable()
export class OmnisearchService {
  private readonly baseUrl = '/omnisearch/portal';

  private queryInner$ = new BehaviorSubject('');

  private expertFields$ = new BehaviorSubject<IRedisExpertFields>({
    expertise: true,
    name: true,
    bio: true,
    geographicTarget: true,
    portalAffiliations: true,
    profileType: true,
  });
  private opportunityFields$ = new BehaviorSubject<IRedisOpportunityFields>({
    name: true,
  });
  private showOmnisearch$ = new BehaviorSubject<boolean>(false);
  private omniseachEnabledInner$ = new BehaviorSubject<boolean>(false);

  viewingOmnisearchProjectName$ = new BehaviorSubject<string>('');
  omnisearchFocused$ = new BehaviorSubject<boolean>(false);
  loading$ = new BehaviorSubject<boolean>(false);

  omnisearchFilters$ = combineLatest([
    this.expertFields$,
    this.opportunityFields$,
  ]);

  omnisearchEnabled$ = this.omniseachEnabledInner$.asObservable();

  omnisearchResults$ = combineLatest([
    this.queryInner$,
    this.omnisearchFilters$,
  ]).pipe(
    switchMap(([query, [expertFields, opportunityFields]]) =>
      this.query(query, expertFields, opportunityFields)
    )
  );

  queryType$ = this.queryInner$.pipe(
    map((query) => {
      if (query.length > 1) {
        return QueryType.query;
      }
    })
  );

  searchForm = new FormGroup({
    search: new FormControl(null, [
      Validators.minLength(1),
      Validators.required,
    ]),
  });

  constructor(
    private http: HttpClient,
    private cognitoAuthService: CognitoAuthService,
    private gaService: GoogleAnalyticsService,
    private toastService: ToastrService
  ) {}

  setExpertFields(fields: IRedisExpertFields): void {
    this.expertFields$.next(fields);
  }

  setOpportunityFields(fields: IRedisOpportunityFields): void {
    this.opportunityFields$.next(fields);
  }

  setQuery(query: string): void {
    this.queryInner$.next(query);
  }

  clearQuery(): void {
    this.toastService.clear();
    this.searchForm.setValue({
      search: '',
    });
    this.queryInner$.next('');
  }

  getQuery(): BehaviorSubject<string> {
    return this.queryInner$;
  }

  setShowOmnisearch(show: boolean): void {
    this.showOmnisearch$.next(show);
  }

  getShowOmnisearch(): Observable<boolean> {
    return this.showOmnisearch$;
  }

  setFocused(focused: boolean): void {
    this.omnisearchFocused$.next(focused);
  }

  setViewedProject(projectName: string): void {
    this.viewingOmnisearchProjectName$.next(projectName);
  }

  setEnabled(enabled: boolean): void {
    this.omniseachEnabledInner$.next(enabled);
  }

  isOmnisearchFeatureEnabled(
    feature: keyof Pick<IClientFeature, 'omnisearchEnabled'>,
    userTypes: UserType[] = ['PM', 'Client']
  ): Observable<boolean> {
    return this.cognitoAuthService.loggedInConnect$.pipe(
      map(
        (user) =>
          !!user &&
          !!user.clients &&
          Object.keys(user.clients).length < 2 &&
          Object.values(user.clients).some(
            (client) => client.features[feature]
          ) &&
          userTypes.includes(user.userType)
      )
    );
  }

  private query(
    query: string,
    expertFields: IRedisExpertFields,
    oppFields: IRedisOpportunityFields
  ): Observable<OmnisearchQueryResponse[]> {
    if (query.length < 2) {
      return of([]);
    }

    return this.constructQuery(query, expertFields, oppFields);
  }

  private constructQuery(
    query: string,
    expertFields: IRedisExpertFields,
    oppFields: IRedisOpportunityFields
  ) {
    this.loading$.next(true);
    const params = this.getQueryParams(query, expertFields, oppFields);

    return this.http
      .get<OmnisearchQueryResponse[]>(`${this.baseUrl}/search`, {
        params,
      })
      .pipe(
        tap(() => this.loading$.next(false)),
        tap((res) =>
          this.gaService.gtag('event', 'search', {
            event_category: 'omnisearch',
            event_label: query,
            value: res.length,
            dimension1: this.cognitoAuthService.loggedInUser.id,
          })
        ),
        catchError(() => of([]))
      );
  }

  private getQueryParams(
    query: string,
    expertFields: IRedisExpertFields,
    oppFields: IRedisOpportunityFields
  ): HttpParams {
    const enabledExpertFields = expertFields
      ? this.getValidFields(expertFields)
      : [];
    const enabledOpportunityFields = oppFields
      ? this.getValidFields(oppFields)
      : [];

    return [
      expertFields ? `portalExperts=${enabledExpertFields.join(',')}` : '',
      oppFields ? `opportunities=${enabledOpportunityFields.join(',')}` : '',
    ]
      .filter(Boolean)
      .reduce((acc, curr) => {
        const [entity, fields] = curr.split('=');
        return acc.append(entity, fields);
      }, new HttpParams().set('query', query).set('clientId', Object.keys(this.cognitoAuthService.loggedInUser.clients).find(Boolean)));
  }

  private getValidFields(
    expertFields: IRedisExpertFields | IRedisOpportunityFields
  ): string[] {
    return Object.entries(expertFields)
      .filter(([, v]) => v)
      .map(([k]) => k);
  }
}
