import { InjectionToken } from '@angular/core';

export const LOCAL_STORAGE = new InjectionToken<Storage>('LOCAL_STORAGE', {
  providedIn: 'root',
  factory: (): Storage => localStorage,
});
export const AUTH0_DOMAIN = new InjectionToken<string>('AUTH0_DOMAIN');
export const CONNECT_API_BASE_URL = new InjectionToken<string>(
  'CONNECT_API_BASE_URL'
);
