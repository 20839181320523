import { Component, Input, OnInit } from '@angular/core';
import { ExpertFile } from '@techspert-io/expert-files';
import { Observable, map } from 'rxjs';
import { FileStoreService } from '../../../../../shared/services/filestore.service';

@Component({
  selector: 'app-echo',
  template: `<div class="container">
    <div
      *ngIf="fileContent$ | async as fileContent; else loader"
      class="flex flex-col gap-4"
    >
      <div class="markdown" [innerHTML]="fileContent | echoMarkdown"></div>

      <div
        class="flex flex-row items-center nav-button"
        matTooltip="Project insights are available for projects that started in or after 2024"
        [matTooltipDisabled]="showEchoAsk"
      >
        <button
          [disabled]="!showEchoAsk"
          mat-flat-button
          color="primary"
          [routerLink]="[]"
          [queryParams]="{
            tab: 'echoAsk',
            resource: 'expert',
            resourceId: file.expertId
          }"
        >
          Generate more insights
        </button>
      </div>
    </div>
    <ng-template #loader>
      <app-loader></app-loader>
    </ng-template>
  </div>`,
  styles: [
    `
      .container {
        min-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;

        .nav-button {
          margin-left: auto;
          margin-right: auto;
        }
      }
    `,
  ],
})
export class ExpertEchoComponent implements OnInit {
  @Input() file: ExpertFile;
  @Input() showEchoAsk: boolean;

  fileContent$: Observable<string>;

  constructor(private fileStoreService: FileStoreService) {}

  ngOnInit() {
    this.fileContent$ = this.fileStoreService
      .getFileContent(this.file.fileKey)
      .pipe(map((fileContent) => fileContent.replace(/^#\s.*$/gm, '').trim()));
  }
}
