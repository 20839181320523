import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExpertFilesStateService } from '../../../../shared/services/filestore-state.service';
import {
  BookingType,
  RequestEngagementService,
} from '../../../../shared/services/request-engagement.service';
import {
  IOmnisearchExpert,
  OmnisearchQueryResponse,
} from '../../models/omnisearch.models';
import { OmnisearchService } from '../../services/omnisearch.service';

@Component({
  selector: 'app-omnisearch-result',
  templateUrl: './omnisearch-result.component.html',
  styleUrls: ['./omnisearch-result.component.scss'],
})
export class OmnisearchResultComponent {
  @Input() omnisearchResponse: OmnisearchQueryResponse;
  @Output() tabChange = new EventEmitter<number>();

  engagementRequested: boolean;

  loadingFiles$ = this.filesState.state$;
  downloadedFiles: Record<string, boolean> = {};
  showAttachments = false;

  get searchTerm(): string {
    return this.omnisearchService.searchForm.get('search').value;
  }

  constructor(
    private requestEngagementService: RequestEngagementService,
    private omnisearchService: OmnisearchService,
    private filesState: ExpertFilesStateService
  ) {}

  requestEngagement(expert: IOmnisearchExpert, bookingType: BookingType): void {
    this.requestEngagementService
      .request(expert.id, 'omnisearch_results', bookingType)
      .subscribe(() => {
        this.engagementRequested = ['booking'].includes(bookingType);
      });
  }
}
