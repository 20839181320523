import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  EMPTY,
  Subject,
  catchError,
  switchMap,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { AssistantStatus } from '../../../models/assistant.models';
import {
  AssistantService,
  IQueryInitialiseAssistantRes,
  IQueryableEntity,
} from '../../../services/assistant.service';

@Component({
  selector: 'app-thread-configuration-dialog',
  template: `<form
      *ngIf="!loading"
      [formGroup]="entityForm"
      (submit)="confirm()"
      class="flex flex-col justify-between"
    >
      <div class="flex justify-between content-center">
        <h3 mat-dialog-title>Select new audience</h3>
        <button mat-icon-button mat-dialog-close>
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <mat-dialog-content class="flex flex-col gap-2">
        <span>
          Available
          {{ data.resource === 'expert' ? 'experts' : 'segments' }} (<span
            class="selection-underline"
            (click)="makeSelection()"
            >{{ someSelected ? 'deselect all' : 'select all' }}</span
          >)
        </span>

        <div class="entity-container flex flex-col gap-1">
          <mat-checkbox
            *ngFor="let entity of data.entities"
            [formControlName]="entity.resourceId"
          >
            {{ entity.name }} ({{ entity.callCount }}
            {{ entity.callCount > 1 ? 'calls' : 'call' }})
          </mat-checkbox>
        </div>

        <mat-error *ngIf="entityForm.dirty && entityForm.invalid">
          Please select at least one
          {{ data.resource === 'expert' ? 'expert' : 'segment' }}
        </mat-error>
      </mat-dialog-content>

      <mat-dialog-actions class="flex gap-2">
        <button
          type="button"
          mat-stroked-button
          mat-dialog-close
          color="primary"
          class="flex-1 primaryColor-border"
        >
          Close window
        </button>
        <button class="flex-1" type="submit" mat-flat-button color="primary">
          Confirm
        </button>
      </mat-dialog-actions>
    </form>
    <div
      *ngIf="loading"
      class="loading-container flex flex-col items-center justify-center gap-5"
    >
      <img src="../../../../../../assets/images/ai-initiate-assistant.gif" />
      <div class="flex flex-col items-center justify-center gap-2">
        <span>Generating your new AI Assistant</span>
        <span>with your custom audience</span>
      </div>
    </div>`,
  styles: [
    `
      .entity-container {
        max-height: 200px;
        min-height: 200px;
        overflow-y: auto;
      }
      .selection-underline {
        cursor: pointer;
        text-decoration: underline;
      }
      .loading-container {
        height: 100%;
        font-size: 18px;

        img {
          height: 48px;
          width: 48px;
        }
      }
    `,
  ],
})
export class ThreadConfigurationDialogComponent implements OnInit, OnDestroy {
  private stopAssistantInit$ = new Subject<boolean>();

  entityForm: FormGroup;
  loading = false;
  failedMessage: string;

  get someSelected() {
    return Object.values(this.entityForm.value).some(Boolean);
  }

  constructor(
    private dialogRef: MatDialogRef<ThreadConfigurationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      entities: IQueryableEntity[];
      resource: 'segment' | 'expert';
    },
    private assistantService: AssistantService
  ) {}

  ngOnInit() {
    this.entityForm = this.data.entities.reduce((acc, entity) => {
      acc.addControl(entity.resourceId, new FormControl(true));
      return acc;
    }, new FormGroup({}, this.atLeastOneTrueValidator()));
  }

  ngOnDestroy() {
    this.stopAssistantInit$.next(true);
  }

  confirm() {
    this.pollInitialise().subscribe();
  }

  makeSelection() {
    if (this.someSelected) {
      this.entityForm.reset();
    } else {
      this.data.entities.forEach((entity) =>
        this.entityForm.get(entity.resourceId).setValue(true)
      );
    }
  }

  private pollInitialise(): Observable<IQueryInitialiseAssistantRes> {
    this.entityForm.markAsDirty();

    if (this.entityForm.invalid) {
      return EMPTY;
    }

    this.loading = true;

    const selectedResourceIds = Object.entries(this.entityForm.value)
      .filter(([, value]) => value)
      .map(([key]) => key);

    return timer(0, 10000).pipe(
      takeUntil(this.stopAssistantInit$),
      switchMap(() =>
        this.assistantService.initialise(
          selectedResourceIds,
          this.data.resource
        )
      ),
      tap(({ status, externalFileMap }) => {
        if (status === AssistantStatus.Active) {
          this.stopAssistantInit$.next(true);
          this.loading = false;
          this.dialogRef.close({
            entities: selectedResourceIds,
            externalFileMap,
          });
        }
        if (status === AssistantStatus.Failed) {
          this.stopAssistantInit$.next(true);
          throw new Error('Failed to initialise assistant');
        }
      }),
      catchError(() => {
        this.loading = false;
        this.failedMessage =
          'Failed to initialise assistant - please try again soon';
        return EMPTY;
      })
    );
  }

  private atLeastOneTrueValidator(): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const controls = formGroup.value;
      const hasAtLeastOneTrue = Object.values(controls).some(Boolean);
      return hasAtLeastOneTrue ? null : { atLeastOneTrue: true };
    };
  }
}
