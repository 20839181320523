import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchHighlight',
})
export class SearchHighlightPipe implements PipeTransform {
  transform(value: string, searchTerm: string): string {
    if (!searchTerm?.trim()) {
      return value;
    }

    const searchWords = [
      ...new Set(
        searchTerm
          .trim()
          .split(/\s+/g)
          .map((str) => str.toLowerCase().trim())
      ),
    ];

    return value.replace(
      new RegExp(`(?<=\\b)(${searchWords.join('|')})`, 'gi'),
      '<span class="omnisearch-result-tag">$1</span>'
    );
  }
}
