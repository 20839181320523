import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { CognitoAuthService } from '@techspert-io/auth';
import { ExpertFile } from '@techspert-io/expert-files';
import { IExpertFile } from '@techspert-io/files';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import {
  EMPTY,
  Subject,
  catchError,
  combineLatest,
  distinctUntilChanged,
  filter,
  finalize,
  interval,
  map,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  tap,
  timer,
} from 'rxjs';
import { AssistantStatus, IThreadMessage } from '../../models/assistant.models';
import { IAssistantThread } from '../../models/assistantThread.models';
import {
  IDefaultPrompt,
  multipleFilePrompts,
  singleFilePrompts,
} from '../../models/prompts';
import {
  AssistantService,
  EnrichedFile,
  IQueryableEntity,
} from '../../services/assistant.service';
import { AssistantFilesService } from '../../services/assistantFiles.service';
import { ThreadConfigurationDialogComponent } from './thread-configuration-dialog/thread-configuration-dialog.component';
import { ThreadDeletionConfirmationComponent } from './thread-deletion-confirmation/thread-deletion-confirmation.component';

type Resource = 'segment' | 'opportunity' | 'expert';

@Component({
  selector: 'app-assistant',
  templateUrl: './assistant.component.html',
  styleUrls: ['./assistant.component.scss'],
})
export class AssistantComponent implements OnInit, OnDestroy {
  @Input() opportunityId: string;
  @ViewChild('scrollContainer') private scrollContainer: ElementRef;

  private stopAssistantInit$ = new Subject<boolean>();
  private destroy$ = new Subject<boolean>();

  loading = true;
  loadingThreads = true;
  polling = false;

  messageForm = new FormGroup({
    message: new FormControl<string>('', [
      Validators.required,
      Validators.minLength(1),
    ]),
  });

  resourceIds: string[];
  resource: Resource = 'opportunity';
  threadId: string;

  failed: boolean;

  types: Resource[] = ['opportunity', 'segment', 'expert'];
  availableEntities: IQueryableEntity[] = [];

  entityMap: Record<string, IQueryableEntity> = {};

  failedMessage: string;
  unauthorised: boolean;

  availablePrompts: IDefaultPrompt[] = multipleFilePrompts;

  threadMessagesInner: IThreadMessage[] = [];

  externalFileMap: Record<string, IExpertFile> = {};

  threads: { group: string; threads: IAssistantThread[] }[] = [];
  selectedThread: IAssistantThread;

  initialisedState = true;

  tabIndex = 0;

  mappedEntityFiles: Record<string, ExpertFile[]> = {};

  set threadMessages(messages: IThreadMessage[]) {
    this.threadMessagesInner = messages;

    setTimeout(() => {
      if (this.scrollContainer?.nativeElement) {
        this.scrollContainer.nativeElement.scrollTop =
          this.scrollContainer.nativeElement.scrollHeight;
      }
    }, 0);
  }

  get transcriptEntityFiles(): EnrichedFile[] {
    return this.entityFiles.filter(
      (f) => f.type === 'enhancedTranscript' || f.type === 'zoomTranscript'
    );
  }

  get entityFiles(): EnrichedFile[] {
    return (
      this.availableEntities
        .filter(
          (e) =>
            this.resourceIds.includes(e.resourceId) ||
            (this.selectedThread &&
              this.selectedThread.entityIds.includes(e.resourceId))
        )
        .flatMap((e) => e.files) || []
    );
  }

  get loggedInName() {
    return this.cognitoAuthService.loggedInUser?.firstName || '';
  }

  constructor(
    private assistantService: AssistantService,
    private assistantFilesService: AssistantFilesService,
    private cognitoAuthService: CognitoAuthService,
    private activatedRoute: ActivatedRoute,
    private gaService: GoogleAnalyticsService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.resourceIds = [this.opportunityId];

    this.activatedRoute.queryParams
      .pipe(
        take(1),
        filter(
          (params) => !params.resourceId && !params.resource && !params.fileId
        ),
        switchMap(() => this.initialise()),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.activatedRoute.queryParams
      .pipe(
        filter(
          (params) =>
            !!params.resourceId || !!params.resource || !!params.fileId
        ),
        distinctUntilChanged(
          (prev, curr) =>
            prev.resourceId === curr.resourceId &&
            prev.resource === curr.resource &&
            prev.fileId === curr.fileId
        ),
        switchMap((params) => this.initialise(params)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.stopAssistantInit$.next(true);
    this.destroy$.next(true);
  }

  initChat() {
    this.loading = true;

    this.resourceIds = [this.opportunityId];
    this.resource = 'opportunity';

    this.initialisedState = true;
    this.threadMessages = [];
    this.selectedThread = undefined;
    this.threadId = undefined;
    this.mappedEntityFiles = this.generateSegmentFileMap(
      this.transcriptEntityFiles
    );
    this.assistantFilesService.setSelectedFile(null);
    this.availablePrompts =
      this.transcriptEntityFiles.length > 1
        ? multipleFilePrompts
        : singleFilePrompts;
    this.tabIndex = 0;
    this.initialiseAssistant().subscribe();
  }

  selectThread(thread: IAssistantThread) {
    this.threadId = thread.assistantThreadId;
    this.resourceIds = thread.entityIds;
    this.resource = thread.entityType;
    this.selectedThread = thread;
    this.polling = false;
    this.stopAssistantInit$.next(true);
    this.initialisedState = false;

    this.mappedEntityFiles = this.generateSegmentFileMap(
      this.transcriptEntityFiles
    );
    this.availablePrompts =
      this.transcriptEntityFiles.length > 1
        ? multipleFilePrompts
        : singleFilePrompts;

    this.loading = true;

    this.gaService.gtag('event', 'click', {
      event_category: 'thread_selection',
      event_label: thread.entityType,
      value: thread.entityIds.join(','),
      dimension1: this.cognitoAuthService.loggedInUser.id,
    });

    this.assistantFilesService.setSelectedFile(null);

    this.assistantService
      .getThreadMessages(this.threadId)
      .subscribe((messages) => {
        this.threadMessages = messages;

        const fileGroupsIds = [
          ...new Set(
            this.entityFiles.filter((f) => f.groupId).map((f) => f.groupId)
          ),
        ];

        if (fileGroupsIds.length === 1) {
          const audioFile = this.entityFiles.find(
            (f) => f.groupId === fileGroupsIds[0] && f.type === 'zoomRecording'
          );

          this.assistantFilesService.setSelectedFile({
            transcriptFile: this.transcriptEntityFiles.find(Boolean),
            audioFile,
          });

          this.tabIndex = 1;
        } else {
          this.tabIndex = 0;
        }

        this.loading = false;
      });
  }

  deleteThread(thread: IAssistantThread) {
    this.dialog
      .open<ThreadDeletionConfirmationComponent, string, boolean>(
        ThreadDeletionConfirmationComponent,
        { width: '520px', autoFocus: false, data: thread.label }
      )
      .afterClosed()
      .pipe(
        filter((v) => v),
        switchMap(() =>
          this.assistantService.deleteThread(thread.assistantThreadId)
        ),
        tap(() => {
          this.threads = this.threads
            .map((g) => ({
              ...g,
              threads: g.threads.filter(
                (t) => t.assistantThreadId !== thread.assistantThreadId
              ),
            }))
            .filter((g) => g.threads.length);

          if (
            this.selectedThread &&
            this.selectedThread.assistantThreadId === thread.assistantThreadId
          ) {
            this.selectedThread = undefined;
            this.threadId = undefined;
            this.initialisedState = true;
            this.resource = 'opportunity';
            this.resourceIds = [this.opportunityId];
            this.mappedEntityFiles = this.generateSegmentFileMap(
              this.transcriptEntityFiles
            );
            this.availablePrompts =
              this.transcriptEntityFiles.length > 1
                ? multipleFilePrompts
                : singleFilePrompts;
            this.assistantFilesService.setSelectedFile(null);
            this.tabIndex = 0;

            this.threadMessages = [];
          }
        })
      )
      .subscribe();
  }

  makeSelection(resource: 'expert' | 'segment') {
    this.dialog
      .open<
        ThreadConfigurationDialogComponent,
        { entities: IQueryableEntity[]; resource: 'segment' | 'expert' },
        { entities: string[]; externalFileMap: Record<string, IExpertFile> }
      >(ThreadConfigurationDialogComponent, {
        width: '520px',
        height: '420px',
        autoFocus: false,
        data: {
          entities: this.availableEntities.filter(
            (e) => e.resource === resource
          ),
          resource,
        },
      })
      .afterClosed()
      .pipe(
        filter((v) => !!v),
        tap(({ entities, externalFileMap }) => {
          this.externalFileMap = externalFileMap;
          this.resource = resource;
          this.resourceIds = entities;
          this.threadId = undefined;
          this.mappedEntityFiles = this.generateSegmentFileMap(
            this.transcriptEntityFiles
          );
          this.availablePrompts =
            this.transcriptEntityFiles.length > 1
              ? multipleFilePrompts
              : singleFilePrompts;

          const fileGroupsIds = [
            ...new Set(
              this.entityFiles.filter((f) => f.groupId).map((f) => f.groupId)
            ),
          ];

          if (fileGroupsIds.length === 1) {
            const audioFile = this.entityFiles.find(
              (f) =>
                f.groupId === fileGroupsIds[0] && f.type === 'zoomRecording'
            );

            this.assistantFilesService.setSelectedFile({
              transcriptFile: this.transcriptEntityFiles.find(Boolean),
              audioFile,
            });

            this.tabIndex = 1;
          } else {
            this.tabIndex = 0;
          }
        })
      )
      .subscribe();
  }

  viewFile(file: ExpertFile) {
    const audioFile = this.entityFiles.find(
      (f) => f.groupId === file.groupId && f.type === 'zoomRecording'
    );

    this.assistantFilesService.setSelectedFile({
      transcriptFile: file,
      audioFile: audioFile,
    });

    this.tabIndex = 1;
  }

  tabChange(tab: number) {
    this.tabIndex = tab;
  }

  async selectPrompt(prompt: string) {
    if (this.selectedThread && this.selectedThread.archived) {
      return;
    }

    await navigator.clipboard.writeText(prompt);
    this.messageForm.controls.message.setValue(prompt);
  }

  sendMessage(): void {
    if (
      this.polling ||
      this.messageForm.invalid ||
      (this.selectedThread && this.selectedThread.archived)
    ) {
      return;
    }

    this.polling = true;

    this.initialisedState = false;

    const message = this.messageForm.value.message;

    this.messageForm.reset();

    this.threadMessages = this.appendUserPromptToThread(message);

    this.assistantService
      .sendMessage({
        resourceIds: this.resourceIds,
        resourceType: this.resource,
        message,
        openAiThreadId: this.threadId,
      })
      .pipe(
        catchError(() => {
          this.polling = false;

          this.threadMessages = [
            ...this.threadMessagesInner,
            this.createFailedMessage(
              'Failed to send message - please try again soon'
            ),
          ];
          return EMPTY;
        })
      )
      .pipe(
        tap(({ openAiThreadId }) => {
          const placeholderThread =
            this.generatePlaceholderThread(openAiThreadId);

          if (!this.threadId) {
            const threads = [
              ...this.threads.flatMap((g) => g.threads),
              placeholderThread,
            ].sort((a, b) => b.lastMessageActivity - a.lastMessageActivity);

            this.threads = this.buildThreadGroups(threads);

            this.selectedThread = placeholderThread;
          }

          this.threadId = openAiThreadId;
        }),
        switchMap(({ runId, openAiThreadId }) =>
          this.pollMessages(this.resourceIds, runId, openAiThreadId)
        )
      )
      .subscribe();
  }

  sendMessageOnEnter(event: KeyboardEvent): void {
    if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey) {
      event.preventDefault();
      this.sendMessage();
    }
  }

  private initialise(params: Params = {}) {
    return combineLatest([
      this.initialiseAvailableEntities(),
      this.assistantService.getThreads(this.opportunityId),
    ]).pipe(
      tap(([entities, threads]) => {
        this.availableEntities = entities;

        this.entityMap = entities.reduce(
          (acc, entity) => ({ ...acc, [entity.resourceId]: entity }),
          {}
        );

        this.threads = this.buildThreadGroups(threads);

        const filteredEntities = this.filterEntities(params, entities);

        this.setSelectedFile(params);

        if (filteredEntities.length) {
          this.resource = filteredEntities.find(Boolean).resource as Resource;
          this.resourceIds = filteredEntities.map((e) => e.resourceId);
        }

        this.mappedEntityFiles = this.generateSegmentFileMap(
          this.transcriptEntityFiles
        );

        this.loadingThreads = false;
      }),
      map(([entities]) =>
        entities
          .flatMap((e) => e.files)
          .filter(
            (f) =>
              f.type === 'enhancedTranscript' || f.type === 'zoomTranscript'
          )
      ),
      switchMap((entityTranscriptFiles) =>
        combineLatest(
          entityTranscriptFiles.map((f) =>
            this.assistantFilesService.getAssistantTranscriptFileContents(f)
          )
        )
      ),
      switchMap(() => this.initialiseAssistant())
    );
  }

  private initialiseAssistant() {
    return timer(0, 10000).pipe(
      takeUntil(this.stopAssistantInit$),
      switchMap(() =>
        this.assistantService.initialise(this.resourceIds, this.resource)
      ),
      tap(({ status, externalFileMap }) => {
        if (status === AssistantStatus.Active) {
          this.stopAssistantInit$.next(true);
          this.externalFileMap = externalFileMap;
          this.loading = false;
        }
        if (status === AssistantStatus.Failed) {
          this.stopAssistantInit$.next(true);
          throw new Error('Failed to initialise assistant');
        }
      }),
      catchError(() => {
        this.loading = false;
        this.failedMessage =
          'Failed to initialise assistant - please try again soon';
        return EMPTY;
      })
    );
  }

  private generateSegmentFileMap(expertFiles: EnrichedFile[]) {
    return expertFiles.reduce(
      (acc, file) => ({
        ...acc,
        [file.segmentName]: (acc[file.segmentName] || []).concat(file),
      }),
      {}
    );
  }

  private buildThreadGroups(threads: IAssistantThread[]) {
    const groupedThreads = threads.reduce<Record<string, IAssistantThread[]>>(
      (acc, thread) => ({
        ...acc,
        [thread.group]: [...(acc[thread.group] || []), thread],
      }),
      {}
    );

    return Object.entries(groupedThreads).map(([group, threads]) => ({
      group,
      threads,
    }));
  }

  private generatePlaceholderThread(openAiThreadId: string): IAssistantThread {
    const month = new Intl.DateTimeFormat('en', {
      month: 'short',
    }).format(new Date());
    const year = new Date().getFullYear();

    return {
      assistantThreadId: openAiThreadId,
      entityIds: this.resourceIds,
      entityType: this.resource,
      archived: false,
      lastMessageActivity: Math.round(Date.now() / 1000),
      label: this.availableEntities
        .filter((e) => this.resourceIds.includes(e.resourceId))
        .map((e) => e.name)
        .join(', '),
      group: `${month} ${year}`,
    };
  }

  private setSelectedFile(params: Params) {
    if (params.fileId) {
      const files = this.availableEntities.flatMap((f) => f.files);

      const file = files.find((f) => f.fileId === params.fileId);

      if (file) {
        const closestTimestamp = params.startTime
          ? this.transformTimestamp(params.startTime)
          : null;

        const audioFile = files.find(
          (f) => f.groupId === file.groupId && f.type === 'zoomRecording'
        );

        this.assistantFilesService.setSelectedFile({
          transcriptFile: file,
          audioFile,
          closestTimestamp,
        });
      }
    }
  }

  private transformTimestamp(number: number) {
    const hours = Math.floor(number / 3600);
    const minutes = Math.floor((number % 3600) / 60);
    const seconds = Math.floor(number % 60);
    const milliseconds = Math.round((number % 1) * 1000);

    const secondsAdjusted = [
      seconds.toString().padStart(2, '0'),
      milliseconds.toString().padStart(3, '0'),
    ].join('.');

    return [
      hours.toString().padStart(2, '0'),
      minutes.toString().padStart(2, '0'),
      secondsAdjusted,
    ].join(':');
  }

  private initialiseAvailableEntities() {
    const getAvailableEntities$ = this.assistantService
      .getAssistantEntities(this.opportunityId)
      .pipe(
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            if ([500, 404, 0].includes(err.status)) {
              this.loading = false;
              this.failedMessage =
                'Oops, something went wrong. Please contact your project manager for assistance.';
            } else if (err.status === 403) {
              this.loading = false;
              this.failedMessage =
                'You do not have access to ECHO Ask. Please contact your project manager for access.';
            } else if (err.status === 400) {
              this.loading = false;
              this.failedMessage =
                'No files available for this project - please check back soon';
            } else {
              this.loading = false;
              this.failedMessage =
                'Failed to get available records - please try again soon';
            }
          }

          return EMPTY;
        })
      );

    return combineLatest([
      this.cognitoAuthService.loggedInAuth0$,
      this.cognitoAuthService.loggedInConnect$,
    ]).pipe(
      tap(([auth0, connect]) => {
        this.unauthorised = !connect || !auth0;
      }),
      filter(([auth0, connect]) => !!connect && !!auth0),
      switchMap(() => getAvailableEntities$)
    );
  }

  private appendUserPromptToThread(message: string) {
    return [
      ...this.threadMessagesInner,
      {
        id: '0',
        created_at: Math.round(Date.now() / 1000),
        expertFileMap: {},
        expert_file_ids: [],
        content: [
          {
            type: 'text' as const,
            text: { value: message, annotations: [] },
          },
        ],
        role: 'user' as const,
      },
    ].sort((a, b) => a.created_at - b.created_at);
  }

  private pollMessages(
    resourceIds: string[],
    runId: string,
    openAiThreadId: string
  ) {
    return interval(5000).pipe(
      switchMap(() =>
        this.assistantService.pollMessages(
          resourceIds,
          this.resource,
          runId,
          openAiThreadId
        )
      ),
      filter(({ status }) => status !== 'pending'),
      tap(({ thread, status }) => {
        this.threadMessages = (
          status === 'failed' ? [...thread, this.createFailedMessage()] : thread
        )
          .filter(
            (t) => t.content.length && t.content.every((c) => c.text.value)
          )
          .sort((a, b) => a.created_at - b.created_at);
      }),
      takeWhile(({ status }) => !['completed', 'failed'].includes(status)),
      takeUntil(this.stopAssistantInit$),
      finalize(() => (this.polling = false))
    );
  }

  private filterEntities(params: Params, entities: IQueryableEntity[]) {
    const resource = params.resource;
    const resourceId = params.resourceId;

    if (resource && resourceId) {
      return entities.filter(
        (e) => e.resource === resource && e.resourceId === resourceId
      );
    }

    return [];
  }

  private createFailedMessage(
    message = 'Failed to get messages - please try again soon'
  ) {
    return {
      id: '0',
      created_at: Math.round(Date.now() / 1000),
      expertFileMap: {},
      expert_file_ids: [],
      content: [
        {
          type: 'text' as const,
          text: { value: message, annotations: [] },
        },
      ],
      role: 'assistant' as const,
    };
  }
}
