<div class="advanced-search" *ngIf="showAdvancedSearch$ | async">
  <div class="search-container flex flex-col gap-2">
    <div class="flex justify-between items-center">
      <div class="flex flex-col gap-2">
        <a *ngIf="showClose" (click)="closeOmnisearch()">
          {{viewedOpportunityName ? 'Back to project' : 'Back'}}
        </a>
      </div>
      <button *ngIf="searchType !== 'recommended'" color="primary" class="actions" mat-stroked-button
        (click)="showAdvanced = !showAdvanced">
        <h2 class="flex justify-center items-center">
          Advanced search
          <mat-icon>{{showAdvanced ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
        </h2>
      </button>
    </div>

    <div [style.display]="showAdvanced ? 'flex' : 'none'" class="flex flex-col gap-2 gt-sm:flex-row">
      <div class="flex gap-8 basis-1/3 sm:basis-1">
        <form [formGroup]="expertForm" class="flex flex-col basis-1/2">
          <mat-checkbox (change)="checkExpert($event.checked)" formControlName="experts">
            <b>Experts</b>
          </mat-checkbox>
          <mat-checkbox formControlName="expertise">Expertise</mat-checkbox>
          <mat-checkbox formControlName="name">Name</mat-checkbox>
          <mat-checkbox formControlName="bio">Biography</mat-checkbox>
          <mat-checkbox formControlName="geographicTarget">Geography</mat-checkbox>
          <mat-checkbox formControlName="portalAffiliations">Affiliations</mat-checkbox>
          <mat-checkbox formControlName="profileType">Profile type</mat-checkbox>
        </form>
        <form [formGroup]="opportunityForm" class="flex flex-col basis-1/2">
          <mat-checkbox (change)="checkOpportunity($event.checked)" formControlName="projects">
            <b>Projects</b>
          </mat-checkbox>
          <mat-checkbox formControlName="name">Title</mat-checkbox>
        </form>
      </div>
      <div class="info flex gap-2 basis-2/3 sm:basis-1">
        <mat-icon class="visible">info_outline</mat-icon>
        <div *ngIf="tooltip">{{tooltip}}</div>
      </div>
    </div>
  </div>
</div>

<div class="page-container flex flex-col">
  <div class="get-started flex flex-col justify-center items-center" *ngIf="!searchType">
    <h1 data-test-id="omnisearch-results-container-header">Get started with</h1>
    <img alt="Omnisearch" src="../../../../../assets/images/Omnisearch_white-bg.gif">
    <p data-test-id="omnisearch-results-container-omnisearch-introduction">
      Search across your organization's active and historic projects and experts.<br>
      Book calls with historic experts in one click.<br>
      Access information faster!
    </p>
  </div>

  <ng-container *ngIf="!loading">
    <h3 class="result-count" data-test-id="omnisearch-results-summary">
      <ng-container *ngIf="searchType === 'query'">
        <span *ngIf="results.length < resultLimit">Your search returned {{results.length |
          i18nPlural:unitsMapping}}:</span>
        <span *ngIf="results.length >= resultLimit">Top {{results.length}} results for your search:</span>
      </ng-container>
    </h3>

    <div class="no-results" *ngIf="!results.length && searchType === 'query'" data-test-id="omnisearch-results-summary"
      class="flex flex-col justify-center content-center gap-8 items-center text-center">
      <h1>It looks like we haven't connected you with experts matching those search terms.</h1>
      <span>Send us a project request and we can get started.</span>

      <button mat-flat-button color="primary" (click)="request()">
        <span class="flex justify-start content-center gap-3">
          <mat-icon>mail_outline</mat-icon>
          <span>SUBMIT A REQUEST</span>
        </span>
      </button>
    </div>

    <app-omnisearch-result *ngFor="let res of results" class="flex flex-col gap-2" (tabChange)="tabChange.emit($event)"
      [omnisearchResponse]="res" data-test-id="omnisearch-results-container-result-item">
    </app-omnisearch-result>
  </ng-container>
</div>