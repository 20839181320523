<p *ngIf="!files.length">
  Set up your AI Assistant in order to view transcripts and play call recordings.
</p>

<p *ngIf="files.length">
  Select a file below to view the expert transcript and call recording in this window.
</p>

<div *ngIf="loadingFiles$ | async as loadingFiles" class="file-container flex flex-col gap-2">
  <div *ngFor="let fileList of mappedFiles | keyvalue" class="flex flex-col gap-1">
    <span class="file-key">{{fileList.key}}</span>
    <div class="file" *ngFor="let file of fileList.value" (click)="viewFile(file)">
      <div class="file-in-progress" *ngIf="loadingFiles[file.fileKey]">
        <span>{{loadingFiles[file.fileKey].progress}}%</span>
        <mat-icon class="file-icon" (click)="cancelDownload(file.fileKey)">
          cancel
        </mat-icon>
      </div>

      <div [class.dimmed]="loadingFiles[file.fileKey]" class="flex flex-row grow justify-start items-center gap-2">
        <span class="file-name flex grow">
          {{file.fileName}}
        </span>

        <mat-icon class="icon-color cta" (click)="$event.stopPropagation(); downloadFile(file)"
          matTooltip="Download file" matTooltipPosition="left">
          {{loadingFiles[file.fileKey] ? 'downloading' : downloadedFiles[file.fileKey] ? 'download_done' : 'download'}}
        </mat-icon>
      </div>
    </div>
  </div>
</div>