import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExpertFile } from '@techspert-io/expert-files';
import { ExpertFilesStateService } from '../../../../../shared/services/filestore-state.service';
import { AssistantFilesService } from '../../../services/assistantFiles.service';

type ExtendedExpertFile = ExpertFile & { segmentName: string };

@Component({
  selector: 'app-assistant-files',
  templateUrl: './assistant-files.component.html',
  styleUrls: ['./assistant-files.component.scss'],
  providers: [ExpertFilesStateService],
})
export class AssistantFilesComponent {
  @Input() files: ExpertFile[] = [];
  @Input() mappedFiles: Record<string, ExpertFile[]> = {};
  @Output() viewFileEvent = new EventEmitter<ExtendedExpertFile>();

  loadingFiles$ = this.assistantFilesService.loadingFiles$;
  downloadedFiles = this.assistantFilesService.downloadedFiles;

  constructor(private assistantFilesService: AssistantFilesService) {}

  downloadFile(file: ExtendedExpertFile): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { segmentName, ...rest } = file;
    this.assistantFilesService.downloadFile(rest);
  }

  cancelDownload(fileName: string): void {
    this.assistantFilesService.cancelDownload(fileName);
  }

  viewFile(file: ExtendedExpertFile): void {
    this.viewFileEvent.emit(file);
  }
}
