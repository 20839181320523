import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IOpportunity } from '@techspert-io/opportunities';
import { EMPTY, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { BookingType } from '../../../../../../shared/services/request-engagement.service';
import { OpportunityStoreService } from '../../../../../../shared/state/opportunity.service';

type SegmentOption = { id: string; segment: string; geography: string };

@Component({
  selector: 'app-request-expert-engagement',
  templateUrl: './request-expert-engagement.component.html',
  styleUrls: ['./request-expert-engagement.component.scss'],
  animations: [
    trigger('fade', [
      state(
        'a',
        style({
          opacity: 0,
        })
      ),
      state(
        'b',
        style({
          opacity: 1,
        })
      ),
      transition('a => b', animate('400ms ease-in')),
      transition('b => a', animate('400ms ease-out')),
    ]),
  ],
})
export class RequestExpertEngagementComponent implements OnInit {
  engagementForm = new UntypedFormGroup({
    project: new FormControl(0, Validators.required),
    segmentId: new FormControl(
      { value: null, disabled: true },
      Validators.required
    ),
    notes: new FormControl(null, Validators.required),
  });

  segments$: Observable<SegmentOption[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { opps: IOpportunity[]; bookingType: BookingType },
    private oppService: OpportunityStoreService,
    private dialogRef: MatDialogRef<RequestExpertEngagementComponent>
  ) {}

  ngOnInit(): void {
    this.segments$ = this.engagementForm.get('project').valueChanges.pipe(
      tap((project) => {
        const control = this.engagementForm.get('segmentId');
        !!project ? control.enable() : control.disable();

        control.setValue(null);
      }),
      switchMap((project) =>
        !!project
          ? this.oppService.getOpportunity(project.opportunityId).pipe(
              map((opp) =>
                opp.opportunitySegments.map(
                  ({ opportunitySegmentId, segment, geography }) => ({
                    id: opportunitySegmentId,
                    segment,
                    geography,
                  })
                )
              )
            )
          : EMPTY
      )
    );
  }

  sendRequest(): void {
    this.dialogRef.close(this.engagementForm.value);
  }
}
