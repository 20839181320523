<div class="flex flex-col auth-dialog">
  <div class="flex justify-between content-center">
    <h3 mat-dialog-title data-test-id="auth-dialog-header">
      You need to be logged in for this!
    </h3>
    <button mat-icon-button mat-dialog-close data-test-id="auth-dialog-close">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="flex flex-col gap-2">
    <p data-test-id="auth-dialog-instruction-text">
      Click to login, or contact your project manager to set up your account.
    </p>

    <p data-test-id="auth-dialog-faq-text">
      Any questions? Visit our
      <a href="https://www.techspert.com/portal-faqs" target="_blank" data-test-id="auth-dialog-faq-link">
        help portal.
      </a>
    </p>
  </mat-dialog-content>

  <mat-dialog-actions class="flex gap-2">
    <button type="button" mat-stroked-button mat-dialog-close color="primary" class="flex-1 primaryColor-border"
      data-test-id="auth-dialog-cancel">
      Close window
    </button>
    <button class="flex-1" type="submit" mat-flat-button color="primary" routerLink='/secure'
      data-test-id="auth-dialog-login">
      Login
    </button>
  </mat-dialog-actions>
</div>