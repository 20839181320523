<div class="auth-container" *ngIf="auth$ | async">
  <div>
    <app-lost-user-svg></app-lost-user-svg>

    <h1 data-test-id="landing-page-header-text">
      Which project are you looking for?
    </h1>

    <p data-test-id="landing-page-body-text">
      Projects can only be accessed via the secure links included in your project kick off email. <br />
      Please ask your Project Manager if you need this link to be re-sent.
    </p>

  </div>
</div>