import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterTestingModule } from '@angular/router/testing';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { CognitoAuthService } from '../services/cognito-auth.service';
import { AUTH0_DOMAIN, LOCAL_STORAGE } from '../services/token';
import { createAuthServiceMock } from './authMock';

interface IAuthTestingModuleOptions {
  authToken: string;
  auth0Domain: string;
}

const authToken = 'authTokenVal';
const auth0Domain = 'auth0DomainVal';

const defaultOptions: Partial<IAuthTestingModuleOptions> = {
  authToken,
  auth0Domain,
};

@NgModule({
  imports: [RouterTestingModule],
})
export class AuthTestingModule {
  static forRoot(
    options = defaultOptions
  ): ModuleWithProviders<AuthTestingModule> {
    return {
      ngModule: AuthTestingModule,
      providers: [
        {
          provide: LOCAL_STORAGE,
          useValue: localStorage,
        },
        {
          provide: AUTH0_DOMAIN,
          useValue: options.auth0Domain || auth0Domain,
        },
        {
          provide: Auth0Service,
          useValue: createAuthServiceMock(null, options.authToken),
        },
        CognitoAuthService,
      ],
    };
  }
}
