<app-cta-banner *ngIf="banner" [banner]="banner" (closeBanner)="closeBanner()"></app-cta-banner>

<app-top-bar></app-top-bar>

<ng-container *ngIf="omnisearchData$ | async as ob">
  <div [style.display]="ob.omnisearchFocused ? 'none' : 'block'">
    <router-outlet></router-outlet>
  </div>

  <app-omnisearch-results-container *ngIf="ob.omnisearchFocused" [showClose]="showClose"
    [results]="ob.omnisearchResults" [searchType]="ob.queryType" [viewedOpportunityName]="ob.omnisearchProjectName">
  </app-omnisearch-results-container>
</ng-container>