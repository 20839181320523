import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FileModule } from '@techspert-io/files';
import { SharedModule } from '../../shared/shared.module';
import { OpportunitySegmentsService } from '../opportunity-segments/services/opportunitySegments.service';
import { AssistantFilesComponent } from './components/assistant/assistant-files/assistant-files.component';
import { AssistantPlaybackComponent } from './components/assistant/assistant-playback/assistant-playback.component';
import { AssistantUnauthorisedComponent } from './components/assistant/assistant-unauthorised/assistant-unauthorised.component';
import { AssistantComponent } from './components/assistant/assistant.component';
import { ThreadConfigurationDialogComponent } from './components/assistant/thread-configuration-dialog/thread-configuration-dialog.component';
import { ThreadDeletionConfirmationComponent } from './components/assistant/thread-deletion-confirmation/thread-deletion-confirmation.component';
import { AudioTimeDirective } from './directives/audio-time.directive';
import { PromptResponseDirective } from './directives/prompt-response.directive';
import { AssistantService } from './services/assistant.service';
import { AssistantFilesService } from './services/assistantFiles.service';
import { AssistantQuotesService } from './services/savedQuote.service';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    FileModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatDialogModule,
    SharedModule,
  ],
  declarations: [
    AssistantComponent,
    AssistantFilesComponent,
    AssistantUnauthorisedComponent,
    AssistantPlaybackComponent,
    ThreadDeletionConfirmationComponent,
    ThreadConfigurationDialogComponent,
    PromptResponseDirective,
    AudioTimeDirective,
  ],
  exports: [AssistantComponent],
  providers: [
    AssistantService,
    OpportunitySegmentsService,
    AssistantFilesService,
    AssistantQuotesService,
  ],
})
export class AssistantModule {}
