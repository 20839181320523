import { Pipe, PipeTransform } from '@angular/core';
import { IExpertFile } from '../models/expert-file.model';

@Pipe({ name: 'fileDisplayIcon' })
export class FileDisplayIconPipe implements PipeTransform {
  transform(file: IExpertFile): string {
    const iconMap = {
      png: 'image',
      jpg: 'image',
      jpeg: 'image',
      pdf: 'picture_as_pdf',
      txt: 'text_snippet',
      docx: 'description',
      doc: 'description',
      xlsx: 'description',
      xls: 'description',
      mp4: 'movie',
      m4a: 'audiotrack',
      wav: 'audiotrack',
      mp3: 'audiotrack',
      vtt: 'speaker_notes',
    };

    const ext = `${file.fileExtension}`.trim().toLowerCase();

    return iconMap[ext] || 'help_center';
  }
}
