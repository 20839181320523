<div class="container">
  <div>
    <app-not-authorised-svg></app-not-authorised-svg>

    <h1>
      Your account is not currently authorised to access this project
    </h1>

    <p>
      Please contact your Project Manager to request access
    </p>

    <div>
      <button mat-flat-button color="primary" (click)="logout()">
        Logout to continue
      </button>
    </div>
  </div>
</div>