<div class="result-container flex flex-col gt-sm:flex-row">
    <div class="info flex gap-3 flex-grow justify-start items-start">
        <mat-icon class="icon opportunity-icon" matTooltip="Project">
            insert_chart_outlined
        </mat-icon>
        <div class="title" [innerHTML]="omnisearchResponse.name | searchHighlight: searchTerm | safeHtml"
            data-test-id="omnisearch-result-opportunity-name">
        </div>
    </div>
    <div class="button-container flex flex-col gap-2 justify-start content-start">
        <button mat-stroked-button color="primary" class="flex-grow"
            [matTooltip]="omnisearchResponse.entity | urlTooltip" (click)="goToOpportunity()"
            data-test-id="omnisearch-result-opportunity-view-in-project-button">
            <span class="stroked">View project</span>
        </button>
    </div>
</div>