import { Injectable } from '@angular/core';
import { Observable, OperatorFunction, Subject } from 'rxjs';
import { map, scan, shareReplay, startWith } from 'rxjs/operators';

enum Actions {
  Add = 'add',
  Delete = 'delete',
  UpdateProgress = 'updateProgress',
}

interface IFileState {
  progress?: number;
}

interface IState {
  ids: string[];
  files: Record<string, IFileState>;
}

interface IAction {
  type: Actions;
  fileName: string;
  progress: number;
}

export class Add implements IAction {
  public type = Actions.Add;
  constructor(public fileName: string, public progress = 0) {}
}

export class Delete implements IAction {
  public type = Actions.Delete;
  constructor(public fileName: string, public progress = 0) {}
}

export class UpdateProgress implements IAction {
  public type = Actions.UpdateProgress;
  constructor(public fileName: string, public progress: number) {}
}

type ActionTypes = Add | Delete | UpdateProgress;

@Injectable({
  providedIn: 'root',
})
export class ExpertFilesStateService {
  private loadingFilesInner$ = new Subject<IAction>();

  state$ = this.loadingFilesInner$.pipe(this.scanState());

  dispatch(action: IAction): void {
    this.loadingFilesInner$.next(action);
  }

  private scanState(): OperatorFunction<IAction, Record<string, IFileState>> {
    const initialState = {
      files: {},
      ids: [],
    };

    return (source): Observable<Record<string, IFileState>> =>
      source.pipe(
        scan<IAction, IState>(this.updateState.bind(this), initialState),
        startWith(initialState),
        map((f) => f.files),
        shareReplay(1)
      );
  }

  private updateState(prev: IState, curr: ActionTypes): IState {
    switch (curr.type) {
      case Actions.Delete: {
        const ids = prev.ids.filter((p) => p !== curr.fileName);
        return {
          ids,
          files: this.reduceFiles(ids, prev.files),
        };
      }
      case Actions.Add: {
        const ids = [...prev.ids, curr.fileName];
        return {
          ids,
          files: this.reduceFiles(ids, prev.files),
        };
      }
      case Actions.UpdateProgress:
        return {
          ...prev,
          files: {
            ...prev.files,
            [curr.fileName]: {
              progress: curr.progress,
            },
          },
        };
      default:
        return prev;
    }
  }

  private reduceFiles(
    ids: string[],
    files: Record<string, IFileState>
  ): Record<string, IFileState> {
    return ids.reduce(
      (p, c) => ({
        ...p,
        [c]: files[c]
          ? files[c]
          : {
              progress: 0,
            },
      }),
      {}
    );
  }
}
