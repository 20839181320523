<div class="nav primaryColorBG flex gap-8 justify-start content-center">
  <app-txp-logo (click)="navigateRoot()" *ngIf="(!showOmnisearch || innerWidth > 600)" data-test-id="txp-logo">
  </app-txp-logo>

  <app-omnisearch class="omnisearch" *ngIf="showOmnisearch && isOmnisearchAuthorised">
  </app-omnisearch>

  <div class="right-nav-elements" *ngIf="(loggedInAuth0 | async) === false">
    <button mat-icon-button [matMenuTriggerFor]="noAuthMenu" data-test-id="top-bar-user-menu-button">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #noAuthMenu="matMenu">
      <button mat-menu-item [routerLink]="['/secure']" data-test-id="top-bar-user-menu-login">
        <mat-icon>login</mat-icon>
        <span data-test-id="top-bar-user-menu-login-label">Login</span>
      </button>

      <button mat-menu-item appPortalFAQLink data-test-id="top-bar-user-menu-help">
        <mat-icon>help_outline</mat-icon>
        <span data-test-id="top-bar-user-menu-help-label">Help</span>
      </button>
    </mat-menu>
  </div>

  <div class="right-nav-elements" *ngIf="(!showOmnisearch || innerWidth > 600) && loggedInAuth0 | async">
    <button mat-icon-button *ngIf="isOmnisearchAuthorised && !showOmnisearch" (click)="showOmni()">
      <mat-icon>search</mat-icon>
    </button>

    <button mat-icon-button [matMenuTriggerFor]="authMenu" data-test-id="top-bar-user-menu-button">
      <mat-icon>person</mat-icon>
    </button>

    <mat-menu #authMenu="matMenu">
      <div class="top-portion" *ngIf="loggedInUser">
        <div class="icon-edit-container">
          <div class="profile-icon primaryColorBG" data-test-id="top-bar-user-menu-logged-in-user-initials">
            {{loggedInUser.firstName[0] | uppercase}}{{loggedInUser.lastName[0] | uppercase}}
          </div>
        </div>
        <div class="name" [attr.user-id]="loggedInUser.id"
          data-test-id="top-bar-user-menu-logged-in-user-welcome-message">
          Hi {{loggedInUser.firstName}}
        </div>
      </div>

      <button mat-menu-item appPortalNewLink data-test-id="top-bar-user-menu-new">
        <mat-icon>rocket_launch</mat-icon>
        <span data-test-id="top-bar-user-menu-new-label">What's New</span>
      </button>

      <button *ngIf="showReportsItem" mat-menu-item [routerLink]="['/reports']"
        data-test-id="top-bar-user-menu-reports">
        <mat-icon>summarize</mat-icon>
        <span data-test-id="top-bar-user-menu-reports-label">Usage Reports</span>
      </button>

      <button mat-menu-item appPortalFAQLink data-test-id="top-bar-user-menu-help">
        <mat-icon>help_outline</mat-icon>
        <span data-test-id="top-bar-user-menu-help-label">Help</span>
      </button>

      <button mat-menu-item (click)="logout()" data-test-id="top-bar-user-menu-logout">
        <mat-icon>logout</mat-icon>
        <span data-test-id="top-bar-user-menu-logout-label">Log Out</span>
      </button>
    </mat-menu>
  </div>
</div>