<table *ngIf="navState$ | async as expertsExpanded" mat-table matSort [matSortActive]="dateSortColumn.key"
  matSortDirection="desc" matSortStart="desc" [dataSource]="expertsFiltered" multiTemplateDataRows class="full-width">

  <ng-container matColumnDef="dropDown">
    <th mat-header-cell *matHeaderCellDef>
      <app-expert-expand-all-toggle [collapsed]="showCollapsed" (toggle)="onToggleExpandAll(expertsExpanded)">
      </app-expert-expand-all-toggle>
    </th>
    <td mat-cell *matCellDef="let element" data-test-id="expert-table-expand-expert">
      <mat-icon>{{expertsExpanded[element.expertId] ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="approval">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="clientApproved">
      <mat-icon class="material-icons" matTooltip="Sort by approval status">lock_person</mat-icon>
    </th>
    <td mat-cell *matCellDef="let element" data-test-id="expert-table-clientApproved">
      <div class="flex gap-2">
        <div [matTooltip]="element.clientApproved | approvalTooltip">
          <mat-icon class="material-icons-outlined approval-lock"
            [ngClass]="element.clientApproved ? 'approved' : 'not-approved'" data-test-id="expert-table-approval-icon">
            {{element.clientApproved ? 'lock_open' : 'lock'}}
          </mat-icon>
        </div>
      </div>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="column.key" *ngFor="let column of expertColumns">
    <th mat-header-cell *matHeaderCellDef [mat-sort-header]="column.key">
      {{column.name}}
    </th>
    <td mat-cell *matCellDef="let element" [attr.data-test-id]="'expert-table-' + column.key">
      <span *ngIf="column.key !== 'unitsPerHour' && column.key !== 'screeningStatus'"
        [class.is-bold]="!isClientUser || (!element.seenByClient && isClientUser)">
        {{element | expertListValue:column.key}}
      </span>

      <div *ngIf="column.key === 'screeningStatus'" [style.min-width]="'100px'">
        <div *ngIf="element.screeningStatus === 'complete-near-pass'" class="flex justify-start items-center gap-2"
          matTooltip="Expert failed screener but was a near pass" data-test-id="expert-table-near-pass-icon">
          <mat-icon class="material-icons-outlined near-pass">
            call_missed
          </mat-icon>
          <span class="near-pass" data-test-id="expert-table-near-pass-icon-label">Near pass</span>
        </div>
      </div>

      <app-expert-unit *ngIf="column.key === 'unitsPerHour'" [expert]="element"></app-expert-unit>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element" data-test-id="expert-table-actions">
      <div class="flex gap-2" [style.min-width]="'48px'">
        <app-screener-btn [files]="element.uploadedFiles" [viewStage]="viewStage"
          [isAuthorised]="isFileDownloadAuthorised"></app-screener-btn>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="favourited">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="favourited">
      <mat-icon matTooltip="Sort by favorites">star_half</mat-icon>
    </th>
    <td mat-cell *matCellDef="let element" data-test-id="expert-table-favourited">
      <div class="flex gap-2">
        <mat-icon class="favourite-star" [fontSet]="element.favourited ? 'material-icons' : 'material-icons-outlined'"
          (click)="onToggleFavourited($event, element)">
          star_rate
        </mat-icon>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
      <div class="expert-element-detail" [class.loading]="expertLoadingIds[element.expertId]">
        <app-expert *ngIf="expertsExpanded[element.expertId]" class="full-width" [viewStage]="viewStage"
          [opportunity]="opportunity" [expertData]="element" [isFileDownloadAuthorised]="isFileDownloadAuthorised"
          [isExpanded]="expertsExpanded[element.expertId]" [isClientUser]="isClientUser"
          [isExpertApprovalRequired]="isExpertApprovalRequired" [showEchoAsk]="showEchoAsk">
        </app-expert>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let element; columns: expertsFiltered.data.length ? columnsToDisplay: [];"
    class="expert-element-row" [attr.id]="element.expertId"
    [class.expert-expanded-row]="expertsExpanded[element.expertId]" (click)="onToggleExpand(element, expertsExpanded)"
    [attr.data-test-id]="'expert-table-expert-summary-' + element.expertId">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expert-detail-row"
    [attr.data-test-id]="'expert-table-expert-details-' + row.expertId"></tr>
</table>