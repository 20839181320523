import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ExpertUpdateType } from '@techspert-io/experts';
import { OpportunityNavigationService } from '@techspert-io/navigation';
import { IOpportunity } from '@techspert-io/opportunities';
import { EMPTY, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IExpert } from '../../../../shared/models/expert.interface';
import { ExpertStoreService } from '../../../../shared/state/expert.service';

interface IColumn {
  name: string;
  key: keyof IExpert;
}

type ExpertsExpanded = Record<string, boolean>;

@Component({
  selector: 'app-expert-table',
  templateUrl: './expert-table.component.html',
  styleUrls: ['./expert-table.component.scss'],
})
export class ExpertTableComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() experts: IExpert[];
  @Input() expertLoadingIds: Record<string, boolean>;
  @Input() viewStage: string = '';
  @Input() opportunity: IOpportunity;
  @Input() isFileDownloadAuthorised: boolean;
  @Input() isClientUser = false;
  @Input() isExpertApprovalRequired: boolean;
  @Input() showEchoAsk: boolean;

  @ViewChild(MatSort) sort: MatSort;

  get dateSortColumn(): IColumn {
    return ['accepted', 'completed'].includes(this.viewStage)
      ? { name: 'Call Date & Time', key: 'callTime' }
      : { name: 'Date Modified', key: 'lastUpdated' };
  }

  get expertColumns(): IColumn[] {
    const screeningStatusColumn =
      this.viewStage === 'new'
        ? [{ name: '', key: 'screeningStatus' as const }]
        : [];

    return this.opportunity.stageName === 'Project in progress'
      ? [
          { name: 'Geography', key: 'geographicTarget' },
          ...screeningStatusColumn,
          { name: 'Expert Name', key: 'firstName' },
          { name: '', key: 'unitsPerHour' },
          { name: 'Profile Type', key: 'profileType' },
          this.dateSortColumn,
        ]
      : [
          { name: 'Geography', key: 'geographicTarget' },
          ...screeningStatusColumn,
          { name: 'Expert Name', key: 'firstName' },
          { name: 'Profile Type', key: 'profileType' },
          this.dateSortColumn,
        ];
  }

  get columnsToDisplay(): string[] {
    return [
      'dropDown',
      ...(this.isExpertApprovalRequired ? ['approval'] : []),
      ...this.expertColumns.map((c) => c.key),
      'actions',
      'favourited',
    ];
  }

  expertsFiltered = new MatTableDataSource();
  showCollapsed = true;

  navState$: Observable<ExpertsExpanded> = EMPTY;

  constructor(
    private opportunityNavigationService: OpportunityNavigationService,
    private expertStoreService: ExpertStoreService
  ) {}

  ngOnInit(): void {
    this.navState$ = this.opportunityNavigationService.state$.pipe(
      tap(
        (res) =>
          (this.showCollapsed = !this.experts
            .map((e) => res[e.expertId])
            .every((e) => e))
      )
    );

    this.expertsFiltered.data = this.experts;
  }

  ngAfterViewInit(): void {
    this.expertsFiltered.sort = this.sort;
  }

  ngOnChanges(): void {
    this.expertsFiltered.data = this.experts;
  }

  onToggleExpandAll(expertsExpanded: ExpertsExpanded): void {
    const showCollapsed = !this.experts
      .map((e) => expertsExpanded[e.expertId])
      .every((e) => e);

    const expertsToExpand = this.experts.reduce(
      (prev, expert) => ({
        ...prev,
        [expert.expertId]: showCollapsed,
      }),
      {}
    );

    this.opportunityNavigationService.setNavigationState(expertsToExpand);

    this.experts
      .filter((e) => !e.seenByClient && this.isClientUser)
      .forEach(({ expertId }) => this.emitSeenByClientUpdate({ expertId }));
  }

  onToggleExpand(
    { expertId, seenByClient }: IExpert,
    expertsExpanded: ExpertsExpanded
  ): void {
    this.opportunityNavigationService.setNavigationState({
      [expertId]: !!!expertsExpanded[expertId],
    });

    if (!seenByClient && this.isClientUser) {
      this.emitSeenByClientUpdate({ expertId });
    }
  }

  onToggleFavourited(e: Event, expert: IExpert): void {
    e.stopPropagation();

    this.expertStoreService.updateExpert({
      expertId: expert.expertId,
      type: ExpertUpdateType.FavouriteExpert,
      payload: { favourited: !expert.favourited },
    });
  }

  private emitSeenByClientUpdate(
    expert: Partial<IExpert> & { expertId: string }
  ): void {
    this.expertStoreService.updateExpert({
      expertId: expert.expertId,
      type: ExpertUpdateType.SeenByClient,
    });
  }
}
