import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-on-hold-reason-dialog',
  templateUrl: './on-hold-reason-dialog.component.html',
})
export class OnHoldReasonDialogComponent {
  public onHoldReason: string;
  public confirmReason = false;

  constructor(private dialogRef: MatDialogRef<OnHoldReasonDialogComponent>) {}

  confirm(): void {
    this.dialogRef.close({
      onHoldReason: this.onHoldReason,
      onHold: true,
    });
  }
}
