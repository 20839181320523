import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map, mergeMap, switchMap } from 'rxjs/operators';
import { CognitoAuthService } from './cognito-auth.service';

@Injectable()
export class BearerTokenInterceptor implements HttpInterceptor {
  private authHeaderName = 'Authorization';

  constructor(private cognitoAuthService: CognitoAuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.headers.has(this.authHeaderName)) {
      return next.handle(request);
    }
    return this.cognitoAuthService.loggedInAuth0$.pipe(
      first(),
      switchMap((loggedIn) =>
        loggedIn
          ? this.cognitoAuthService.getAccessToken().pipe(
              map((authToken) =>
                this.updateHeaders(authToken, request.headers)
              ),
              map((headers) => request.clone({ headers })),
              mergeMap((newReq) => next.handle(newReq))
            )
          : next.handle(
              request.clone({
                headers: this.updateHeaders('', request.headers),
              })
            )
      )
    );
  }

  private updateHeaders(
    token: string,
    originalHeaders: HttpHeaders
  ): HttpHeaders {
    return originalHeaders.set(this.authHeaderName, `Bearer ${token}`);
  }
}
