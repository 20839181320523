<form [formGroup]="filterForm" autocomplete="off" class="flex flex-wrap justify-start items-center gap-2">
  <mat-form-field appearance="outline">
    <mat-label>Geography</mat-label>
    <mat-select formControlName="geographicTargets" multiple>
      <mat-option *ngFor="let geography of geographiesList" [value]="geography">
        {{geography}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Profile type</mat-label>
    <mat-select formControlName="profileTypes" multiple>
      <mat-option *ngFor="let profileType of profileTypesList" [value]="profileType">
        {{profileType}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Affiliations</mat-label>
    <mat-select formControlName="affiliations" multiple>
      <mat-option *ngFor="let affiliation of affiliationsList" [value]="affiliation">
        {{affiliation}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Name</mat-label>
    <input matInput formControlName="expertName">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Bio/Screener content</mat-label>
    <input matInput formControlName="bioContent">
  </mat-form-field>

  <mat-checkbox *ngIf="viewStage === 'new'" formControlName="showNearPass"
    data-test-id="expert-filter-show-near-pass-experts">Show near pass experts</mat-checkbox>

  <button mat-icon-button color="primary" [disabled]="!formHasValues" (click)="resetForm()">
    <mat-icon>clear</mat-icon>
  </button>

</form>