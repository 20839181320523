import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { map, take, tap } from 'rxjs/operators';
import { OmnisearchQueryResponse } from '../../models/omnisearch.models';
import {
  OmnisearchService,
  QueryType,
} from '../../services/omnisearch.service';

@Component({
  selector: 'app-omnisearch-results-container',
  templateUrl: './omnisearch-results-container.component.html',
  styleUrls: ['./omnisearch-results-container.component.scss'],
})
export class OmnisearchResultsContainerComponent implements OnInit {
  @Input() showClose = true;
  @Input() results: OmnisearchQueryResponse[];
  @Input() viewedOpportunityName?: string;
  @Input() searchType?: QueryType;
  @Output() tabChange = new EventEmitter<number>();

  unitsMapping: Record<string, string> = {
    '=1': '1 result',
    other: '# results',
  };

  tooltip: string;
  resultLimit = 100;

  expertForm = new UntypedFormGroup({
    experts: new FormControl(true),
    expertise: new FormControl(true),
    name: new FormControl(true),
    bio: new FormControl(true),
    geographicTarget: new FormControl(true),
    portalAffiliations: new FormControl(true),
    profileType: new FormControl(true),
  });

  opportunityForm = new UntypedFormGroup({
    projects: new FormControl(true),
    name: new FormControl(true),
  });

  query$ = this.omnisearchService.getQuery();
  showAdvancedSearch$ = this.omnisearchService.getShowOmnisearch();

  loading: boolean;
  innerWidth: number;
  showAdvanced = false;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.innerWidth = window.innerWidth;
  }

  constructor(
    private omnisearchService: OmnisearchService,
    private toastrService: ToastrService
  ) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.toastrService.clear();

    this.omnisearchService.omnisearchFilters$
      .pipe(
        take(1),
        map(([expertFields, opportunityFields]) => [
          expertFields
            ? { ...expertFields, experts: true }
            : {
                experts: false,
                expertise: false,
                name: false,
                bio: false,
                geographicTarget: false,
                portalAffiliations: false,
                profileType: false,
              },
          opportunityFields
            ? { ...opportunityFields, projects: true }
            : {
                projects: false,
                name: false,
              },
        ])
      )
      .subscribe(([expertFields, opportunityFields]) => {
        this.expertForm.setValue(expertFields);
        this.opportunityForm.setValue(opportunityFields);
      });

    this.tooltip = this.formTooltip();

    this.omnisearchService.loading$
      .pipe(tap((loading) => (this.loading = loading)))
      .subscribe();

    this.expertForm.valueChanges
      .pipe(
        tap(({ experts, ...value }) =>
          this.omnisearchService.setExpertFields(experts ? value : undefined)
        ),
        tap(() => (this.tooltip = this.formTooltip()))
      )
      .subscribe();

    this.opportunityForm.valueChanges
      .pipe(
        tap(({ projects, ...value }) =>
          this.omnisearchService.setOpportunityFields(
            projects ? value : undefined
          )
        ),
        tap(() => (this.tooltip = this.formTooltip()))
      )
      .subscribe();
  }

  checkOpportunity(checked: boolean): void {
    ['name'].forEach((field) => {
      if (checked) {
        this.opportunityForm.controls[field].enable();
        this.opportunityForm.controls[field].setValue(true);
      } else {
        this.opportunityForm.controls[field].disable();
        this.opportunityForm.controls[field].setValue(false);
      }
    });
  }

  checkExpert(checked: boolean): void {
    [
      'expertise',
      'name',
      'bio',
      'geographicTarget',
      'portalAffiliations',
      'profileType',
    ].forEach((field) => {
      if (checked) {
        this.expertForm.controls[field].enable();
        this.expertForm.controls[field].setValue(true);
      } else {
        this.expertForm.controls[field].disable();
        this.expertForm.controls[field].setValue(false);
      }
    });
  }

  request(): void {
    window.open(
      'https://www.techspert.com/customer?utm_source=clientportal&utm_medium=omnisearch&utm_campaign=omnisearch'
    );
  }

  clearSearch(): void {
    this.omnisearchService.clearQuery();
  }

  closeOmnisearch(): void {
    this.omnisearchService.setFocused(false);

    if (this.innerWidth <= 600) {
      this.omnisearchService.setShowOmnisearch(false);
    }
  }

  private formTooltip(): string {
    const { experts, ...expertFields } = this.expertForm.getRawValue();
    const { projects, ...projectFields } = this.opportunityForm.getRawValue();

    const expertString =
      experts && Object.values(expertFields).some((v) => !!v)
        ? this.filterValidFields(expertFields, 'experts')
        : undefined;
    const opportunityString =
      projects && Object.values(projectFields).some((v) => !!v)
        ? this.filterValidFields(projectFields, 'projects')
        : undefined;

    const singularInfo = [expertString, opportunityString]
      .filter(Boolean)
      .join(' AND ');

    if (!singularInfo) {
      return 'You are searching on all fields, select an entity if you wish to refine your search';
    }

    return `Your search query will return ${singularInfo}`;
  }

  private filterValidFields(
    input: Record<string, boolean>,
    entity: 'experts' | 'projects'
  ): string {
    const allChecked = Object.values(input).every((v) => !!v);

    if (allChecked) {
      return `${entity} which match your query for any fields`;
    }

    const keys = Object.entries(input)
      .filter(([, v]) => !!v)
      .map(([k]) => (k === 'name' && entity === 'projects' ? 'title' : k));

    const sentence = keys.slice(0, keys.length).join(', ');

    return `${entity} which match your query for ${sentence}`
      .replace('portalAffiliations', 'affiliations')
      .replace('geographicTarget', 'geography')
      .replace('bio', 'biography');
  }
}
