import {
  Component,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgModel } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { CognitoAuthService } from '@techspert-io/auth';
import { IExpertCallAction } from '@techspert-io/call-actions';
import {
  ExpertUpdateType,
  ExpertsService,
  IExpertReadyToAcceptReturn,
} from '@techspert-io/experts';
import { IOpportunity, OpportunityService } from '@techspert-io/opportunities';
import { SharedExpertEmailsService } from '@techspert-io/shared-expert-emails';
import * as moment from 'moment-timezone';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, of, timer } from 'rxjs';
import {
  catchError,
  finalize,
  mergeMap,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs/operators';
import {
  IExpert,
  IExpertAvailability,
  IExpertAvailabilityFormatted,
} from '../../../../shared/models/expert.interface';
import { TIME_ZONE } from '../../../../shared/pipes/availability-date.pipe';
import { RequestEngagementService } from '../../../../shared/services/request-engagement.service';
import { ExpertStoreService } from '../../../../shared/state/expert.service';
import { AuthDialogInceptorService } from '../../services/auth-dialog-inceptor.service';
import { AcceptanceWithdrawalBlockedDialogComponent } from './dialogs/acceptance-withdrawal-blocked-dialog/acceptance-withdrawal-blocked-dialog.component';
import { ExpertNoShowDialogComponent } from './dialogs/expert-no-show-dialog/expert-no-show-dialog.component';
import { OnHoldReasonDialogComponent } from './dialogs/reason-for-on-hold/on-hold-reason-dialog.component';
import { ReasonRejectionDialogComponent } from './dialogs/reason-for-rejection/rejection-reason-dialog.component';
import {
  IRequestConferenceDialogData,
  IRequestConferenceDialogResult,
  RequestConferenceDialogComponent,
} from './dialogs/request-conference-dialog/request-conference-dialog.component';
import {
  IRequestTimeChangeCloseData,
  IRequestTimeChangeData,
  RequestTimeChangeComponent,
} from './dialogs/request-time-change/request-time-change.component';
import {
  ISelectAvailabilityDialogData,
  SelectAvailabilityDialogComponent,
} from './dialogs/select-availability-dialog/select-availability-dialog.component';
import { ShareExpertDialogComponent } from './dialogs/share-expert/share-expert-dialog.component';

type OnHoldRes = {
  onHoldReason: string;
  onHold: boolean;
};

type RejectionRes = {
  rejectionReason: string;
  rejected: boolean;
};

@Component({
  selector: 'app-expert',
  templateUrl: './expert.component.html',
  styleUrls: ['./expert.component.scss'],
})
export class ExpertComponent implements OnInit, OnChanges {
  @Input() expertData: IExpert;
  @Input() opportunity: IOpportunity;
  @Input() viewStage: string;
  @Input() isFileDownloadAuthorised: boolean;
  @Input() isExpanded: boolean;
  @Input() isClientUser: boolean;
  @Input() isExpertApprovalRequired: boolean;
  @Input() showEchoAsk: boolean;

  @ViewChild('clientNotes') clientNotes: NgModel;
  @ViewChild('expertTabGroup', { static: false }) expertTabGroup: MatTabGroup;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.smallScreen = window.innerWidth <= 1280;
  }

  expertAvailability: IExpertAvailabilityFormatted[];
  confirmClicked: boolean;
  smallScreen: boolean;
  requestNewEngagementSent: boolean;
  expertFilePrefix: string = '';
  isCallInProgress: boolean = false;
  expertIcon: string = 'new';
  isAcceptAvailabilityInProgress = false;

  unitsMapping: Record<string, string> = {
    '=1': '1 unit',
    other: '# units',
  };

  constructor(
    private dialog: MatDialog,
    private requestEngagementService: RequestEngagementService,
    private authDialogInceptorService: AuthDialogInceptorService,
    private expertStoreService: ExpertStoreService,
    private opportunityService: OpportunityService,
    private expertsService: ExpertsService,
    @Inject(TIME_ZONE) private timezone: string,
    private toastService: ToastrService,
    private shareExpertActionsService: SharedExpertEmailsService,
    private gaService: GoogleAnalyticsService,
    private cognitoAuthService: CognitoAuthService
  ) {}

  ngOnInit(): void {
    this.onResize();
    this.constructAvailability();
    this.expertFilePrefix = this.constructExpertFilePrefix();
    this.setupIcon();
  }

  ngOnChanges(sc: SimpleChanges): void {
    if (sc.isExpanded?.currentValue) {
      setTimeout(() => {
        this.expertTabGroup.realignInkBar();
      }, 500);
    }

    if (
      sc.expertData?.previousValue?.clientApproved !==
      sc.expertData?.currentValue?.clientApproved
    ) {
      this.setupIcon();
    }

    this.checkCurrentAction();
  }

  requestExpertEngagement(expert: IExpert): void {
    this.requestEngagementService
      .request(expert.expertId, 'opportunity_page')
      .pipe(tap(() => (this.requestNewEngagementSent = true)))
      .subscribe();
  }

  shareExpert(expert: IExpert): void {
    this.authDialogInceptorService.authIntercept(
      this.isFileDownloadAuthorised,
      () => this.shareExpertImpl(expert)
    );
  }

  expertNoShow(expert: IExpert): void {
    this.dialog.open<ExpertNoShowDialogComponent, { expert: IExpert }, string>(
      ExpertNoShowDialogComponent,
      {
        width: '520px',
        minHeight: '200px',
        data: { expert },
        autoFocus: false,
      }
    );
  }

  saveClientNotes(clientNotes: string): void {
    this.expertStoreService.updateExpert({
      expertId: this.expertData.expertId,
      type: ExpertUpdateType.UpdateClientNotes,
      payload: { clientNotes },
    });
    this.clientNotes.reset(clientNotes);
  }

  toggleRejectExpert(clientRejected: boolean, expertId: string): void {
    if (clientRejected) {
      this.dialog
        .open<ReasonRejectionDialogComponent, unknown, RejectionRes>(
          ReasonRejectionDialogComponent,
          { width: '500px', autoFocus: false }
        )
        .afterClosed()
        .pipe(takeWhile((v) => !!v))
        .subscribe(({ rejectionReason, rejected }) => {
          this.expertStoreService.updateExpert({
            expertId,
            type: ExpertUpdateType.ExpertRejection,
            payload: {
              clientRejected: rejected,
              ...(rejectionReason ? { rejectionReason } : {}),
            },
          });
        });
    } else {
      this.expertStoreService.updateExpert({
        expertId,
        type: ExpertUpdateType.ExpertRejection,
        payload: { clientRejected },
      });
    }
  }

  toggleOnHold(onHold: boolean, expertId: string): void {
    if (onHold) {
      this.dialog
        .open<OnHoldReasonDialogComponent, unknown, OnHoldRes>(
          OnHoldReasonDialogComponent,
          { width: '500px', autoFocus: false }
        )
        .afterClosed()
        .pipe(takeWhile((v) => !!v))
        .subscribe(({ onHoldReason, onHold }) => {
          this.expertStoreService.updateExpert({
            payload: { onHold, ...(onHoldReason ? { onHoldReason } : {}) },
            type: ExpertUpdateType.ExpertOnHold,
            expertId,
          });
        });
    } else {
      this.expertStoreService.updateExpert({
        payload: { onHold },
        type: ExpertUpdateType.ExpertOnHold,
        expertId,
      });
    }
  }

  toggleApproval(expert: IExpert): void {
    if (expert.clientApproved && this.viewStage !== 'new') {
      this.dialog.open<AcceptanceWithdrawalBlockedDialogComponent>(
        AcceptanceWithdrawalBlockedDialogComponent,
        {
          width: '450px',
          height: '220px',
        }
      );
      return;
    }

    this.expertStoreService.updateExpert({
      expertId: expert.expertId,
      type: ExpertUpdateType.ExpertApproval,
      payload: { clientApproved: !expert.clientApproved },
    });
  }

  acceptAvailability(availability: IExpertAvailability): void {
    if (this.isAcceptAvailabilityInProgress) {
      return;
    }

    if (this.isPastDate(availability.start)) {
      return;
    }

    if (this.isExpertApprovalRequired && !this.expertData.clientApproved) {
      this.toastService.warning(
        'This expert needs approval before you are able to schedule a call',
        'Compliance approval required'
      );
      return;
    }

    this.authDialogInceptorService.authIntercept(
      this.opportunity.automatedScheduling ? this.isClientUser : true,
      () => this.scheduleOrConfirmExpert(availability)
    );
  }

  requestDifferentTime(expert: IExpert): void {
    if (this.isExpertApprovalRequired && !this.expertData.clientApproved) {
      this.toastService.warning(
        'This expert needs approval before you are able to request a consultation',
        'Compliance approval required'
      );
      return;
    }

    this.dialog
      .open<
        RequestTimeChangeComponent,
        IRequestTimeChangeData,
        IRequestTimeChangeCloseData
      >(RequestTimeChangeComponent, {
        maxHeight: '90vh',
        width: '680px',
        data: {
          expert,
          slotLength: this.opportunity.anticipatedCallTime || 60,
          slotGap: 30,
        },
        autoFocus: false,
      })
      .afterClosed()
      .pipe(
        takeWhile((request) => !!request),
        tap((request) => {
          this.expertStoreService.updateExpert({
            expertId: expert.expertId,
            type: ExpertUpdateType.RequestDifferentTime,
            payload: {
              clientTimezone: request.clientTimezone,
              newTime: request.notes,
              timeSlots: request.slots || [],
            },
          });
        }),
        catchError(() => {
          this.dialog.open(RequestTimeChangeComponent, {
            width: '360px',
            height: '378px',
            data: {
              expert: expert,
              error: 'Failed to submit request - please retry',
            },
          });
          return of(undefined);
        })
      )
      .subscribe();
  }

  requestBio(): void {
    this.expertStoreService.updateExpert({
      type: ExpertUpdateType.RequestBio,
      expertId: this.expertData.expertId,
    });
  }

  isPastDate(date: string): boolean {
    return new Date(date).getTime() < new Date().getTime();
  }

  checkCurrentAction(): void {
    const currentAction = this.getCurrentAction();
    const upcomingAction = this.getUpcomingAction();

    const now = new Date().getTime();
    if (currentAction) {
      this.isCallInProgress = true;

      timer(this.getActionEndTime(currentAction) - now).subscribe(
        () => (this.isCallInProgress = false)
      );
    } else if (upcomingAction) {
      timer(this.getActionStartTime(upcomingAction) - now).subscribe(
        () => (this.isCallInProgress = true)
      );

      timer(this.getActionEndTime(upcomingAction) - now).subscribe(
        () => (this.isCallInProgress = false)
      );
    }
  }

  onAuthTabClick(): void {
    this.authDialogInceptorService.authIntercept(this.isFileDownloadAuthorised);
  }

  private scheduleOrConfirmExpert(availability: IExpertAvailability) {
    this.isAcceptAvailabilityInProgress = true;

    this.expertsService
      .isExpertReadyToAccept(this.expertData.expertId)
      .pipe(
        mergeMap((readyToAccept) =>
          this.opportunity.automatedScheduling &&
          this.isClientUser &&
          readyToAccept.isReady
            ? this.scheduleExpert(availability)
            : this.confirmExpert(availability, readyToAccept)
        ),
        catchError((err) => {
          console.error(err);
          this.toastService.error(
            'Please try again later or contact your Project Manager if the issue persists',
            'Failed to accept availability'
          );
          return EMPTY;
        }),
        finalize(() => {
          this.gaService.gtag('event', 'click', {
            event_category: 'call_scheduled',
            value: this.expertData.expertId,
            ...(this.cognitoAuthService.loggedInUser && {
              dimension1: this.cognitoAuthService.loggedInUser.id,
            }),
          });

          this.isAcceptAvailabilityInProgress = false;
        })
      )
      .subscribe();
  }

  private shareExpertImpl(expert: IExpert) {
    interface ISharedExpertDialogRes {
      recipients: string[];
      notes: string;
    }

    this.dialog
      .open<ShareExpertDialogComponent, undefined, ISharedExpertDialogRes>(
        ShareExpertDialogComponent,
        { width: '520px', autoFocus: false }
      )
      .afterClosed()
      .pipe(
        takeWhile((v) => !!v),
        switchMap((res) =>
          this.shareExpertActionsService.share(expert.expertId, res)
        ),
        tap(() => this.toastService.success('Expert successfully shared'))
      )
      .subscribe();
  }

  private scheduleExpert(availability: IExpertAvailability) {
    return this.opportunityService
      .getClientContacts(this.opportunity.publicDisplayId)
      .pipe(
        switchMap((contacts) =>
          this.dialog
            .open<
              RequestConferenceDialogComponent,
              IRequestConferenceDialogData,
              IRequestConferenceDialogResult
            >(RequestConferenceDialogComponent, {
              width: '450px',
              data: {
                contacts,
                callTime: availability.start,
                expertTimezone: this.expertData.timezoneName,
              },
              autoFocus: false,
            })
            .afterClosed()
            .pipe(
              takeWhile((outcome) => !!outcome),
              tap((res) => {
                this.expertStoreService.updateExpert({
                  expertId: this.expertData.expertId,
                  payload: {
                    primaryRecipient: res.primaryRecipient,
                    recipients: res.recipients,
                    callTime: availability,
                    clientTimezone: res.clientTimezone,
                  },
                  type: ExpertUpdateType.ExpertSchedule,
                });
              })
            )
        )
      );
  }

  private confirmExpert(
    availability: IExpertAvailability,
    readyToAccept: IExpertReadyToAcceptReturn
  ) {
    return this.dialog
      .open<
        SelectAvailabilityDialogComponent,
        ISelectAvailabilityDialogData,
        string
      >(SelectAvailabilityDialogComponent, {
        width: '450px',
        data: { availability: availability.start, readyToAccept },
        autoFocus: false,
      })
      .afterClosed()
      .pipe(
        takeWhile((outcome) => !!outcome),
        tap(() => {
          this.expertStoreService.updateExpert({
            expertId: this.expertData.expertId,
            payload: {
              callTime: availability,
              clientTimezone: moment.tz.guess(),
            },
            type: ExpertUpdateType.ConfirmAvailability,
          });
        })
      );
  }

  private constructExpertFilePrefix(): string {
    return `${this.expertData.firstName.charAt(
      0
    )}${this.expertData.lastName.charAt(0)}`;
  }

  private setupIcon(): void {
    this.expertIcon =
      this.viewStage === 'new' &&
      this.isExpertApprovalRequired &&
      !this.expertData.clientApproved
        ? 'requires-approval'
        : this.viewStage;
  }

  private getUpcomingAction(): IExpertCallAction {
    const now = new Date().getTime();
    return (this.expertData.actions || []).find(
      (action) =>
        action.actionType === 'call' &&
        new Date(action.datetime).getTime() > now
    ) as IExpertCallAction;
  }

  private getCurrentAction(): IExpertCallAction {
    const now = new Date().getTime();
    return (this.expertData.actions || []).find(
      (action) =>
        action.actionType === 'call' &&
        now > this.getActionStartTime(action) &&
        now < this.getActionEndTime(action)
    ) as IExpertCallAction;
  }

  private getActionStartTime(action: IExpertCallAction): number {
    return new Date(action.datetime).getTime();
  }

  private getActionEndTime(action: IExpertCallAction): number {
    return this.getActionStartTime(action) + action.duration * 60 * 1000;
  }

  private constructAvailability(): void {
    const timeSlots = (this.expertData.actions || []).flatMap((d) =>
      d.actionType === 'availabilities' && d.slotRequestType === 'expert'
        ? d.timeSlots.map((slot) => ({
            ...slot,
            expertActionId: d.expertActionId,
          }))
        : []
    );
    this.expertAvailability = this.formatAvailabilities(timeSlots);
  }

  private formatAvailabilities(
    availabilities: IExpertAvailability[]
  ): IExpertAvailabilityFormatted[] {
    const now = moment();

    return (availabilities || [])
      .filter((a) => moment(a.start).isAfter(now))
      .map((obj) => ({
        ...obj,
        date: moment(obj.start).tz(this.timezone).format('YYYY-MM-DD'),
      }))
      .reduce<IExpertAvailabilityFormatted[]>((acc, curr) => {
        const avail = {
          start: curr.start,
          end: curr.end,
          ...(curr.expertActionId
            ? { expertActionId: curr.expertActionId }
            : {}),
        };
        acc.some((object) => object.date === curr.date)
          ? acc
              .filter((object) => object.date === curr.date)[0]
              .times.push(avail)
          : acc.push({
              date: curr.date,
              times: [avail],
            });
        return acc;
      }, []);
  }
}
