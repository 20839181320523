import { NgModule } from '@angular/core';
import { GrowthBook } from '@growthbook/growthbook';
import { environment } from '../../../environments/environment';
import { GROWTHBOOK } from './token';

@NgModule()
export class GrowthbookModule {
  static forRoot() {
    return {
      ngModule: GrowthbookModule,
      providers: [
        {
          provide: GROWTHBOOK,
          useValue: new GrowthBook({
            apiHost: 'https://cdn.growthbook.io',
            clientKey: environment.growthBookClientKey,
            enableDevMode: true,
          }),
        },
      ],
    };
  }
}
