import { Component, Input, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { CognitoAuthService } from '@techspert-io/auth';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  combineLatest,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';
import {
  INotification,
  NotificationTypeMap,
} from '../../models/notification.models';
import { NotificationService } from '../../services/notifications.service';

type GroupedNotification = { date: string; notifications: INotification[] };

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  @Input() opportunityId: string;

  notifications$: Observable<GroupedNotification[]> = EMPTY;
  priority$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  loading: boolean;

  get loggedIn(): boolean {
    return !!this.cognitoAuthService.loggedInUser;
  }

  constructor(
    private notificationsService: NotificationService,
    private cognitoAuthService: CognitoAuthService,
    private router: Router,
    private gaService: GoogleAnalyticsService
  ) {}

  ngOnInit() {
    this.loading = true;

    this.notifications$ = combineLatest([
      this.notificationsService.notifications$,
      this.priority$,
    ]).pipe(
      map(([notifications, priority]) =>
        notifications
          .filter((n) =>
            priority
              ? [
                  NotificationTypeMap.expertMovedToProject,
                  NotificationTypeMap.availabilitiesUpdated,
                ].includes(n.type)
              : true
          )
          .reduce<GroupedNotification[]>((acc, curr) => {
            const now = this.getFormattedDate();

            const formattedDate = this.getFormattedDate(
              new Date(curr.dateCreated * 1000)
            );

            const dateText = formattedDate === now ? 'Today' : formattedDate;

            const dateItem = acc.find((a) => a.date === dateText);

            if (dateItem) {
              dateItem.notifications.push(curr);
            } else {
              acc.push({ date: dateText, notifications: [curr] });
            }

            return acc;
          }, [])
      )
    );

    this.cognitoAuthService.loggedInConnect$
      .pipe(
        switchMap((connect) =>
          connect
            ? this.notificationsService.getNotifications(this.opportunityId)
            : of([])
        ),
        tap(() => (this.loading = false))
      )
      .subscribe();
  }

  archiveNotification(notificationId: string): void {
    this.notificationsService.archiveNotification(notificationId).subscribe();
  }

  routeAction(link: string, notification: INotification): void {
    this.gaService.gtag('event', 'click', {
      event_category: 'event_selection',
      event_label: notification.type,
      value: notification.notificationId,
      ...(this.cognitoAuthService.loggedInUser && {
        dimension1: this.cognitoAuthService.loggedInUser.id,
      }),
    });

    const [url, params] = link.split('?');

    const queryParams = (params || '').split('&').reduce((acc, curr) => {
      const [key, value] = curr.split('=');
      return { ...acc, [key]: value };
    }, {});

    this.router.navigate([decodeURIComponent(url)], {
      onSameUrlNavigation: 'reload',
      queryParams,
    });
  }

  priorityToggleChange(value: MatSlideToggleChange) {
    this.priority$.next(value.checked);
  }

  private getFormattedDate(date: Date = new Date()): string {
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  }
}
