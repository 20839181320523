<ng-container *ngIf="loadingFiles$ | async as loadingFiles">
  <div class="file" *ngFor="let file of files">

    <div class="file-in-progress" *ngIf="loadingFiles[file.fileKey]" data-test-id="expert-files-download-progress">
      <span>{{loadingFiles[file.fileKey].progress}}%</span>
      <mat-icon class="file-icon" (click)="cancelDownload(file.fileKey)">
        cancel
      </mat-icon>
    </div>

    <div [class.dimmed]="loadingFiles[file.fileKey]" class="flex gap-2 justify-start items-center"
      matTooltip="Please log in to download files" [matTooltipDisabled]="isAuthorised" (click)="downloadFile(file)"
      data-test-id="expert-files-list-item">

      <mat-icon class="icon-color" data-test-id="expert-files-list-item-icon">
        {{file | fileDisplayIcon }}
      </mat-icon>

      <span class="flex grow" [matTooltip]="file | fileDisplayName" [matTooltipDisabled]="!isAuthorised"
        data-test-id="expert-files-list-item-file-name">
        {{file | fileDisplayName:28}}
      </span>

      <mat-icon class="icon-color">
        {{
        loadingFiles[file.fileKey]
        ? 'downloading'
        : downloadedFiles[file.fileKey]
        ? 'download_done'
        : 'download'
        }}
      </mat-icon>
    </div>
  </div>
  <br>
</ng-container>