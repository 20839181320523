import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  map,
  scan,
  startWith,
  tap,
} from 'rxjs';
import { INotification } from '../models/notification.models';

@Injectable()
export class NotificationService implements OnDestroy {
  private readonly baseUrl = '/client-portal/notifications';
  private destroy$ = new Subject();
  private notificationsInner$ = new BehaviorSubject<INotification[]>([]);
  private archivedNotificationInner$ = new Subject<string>();
  private archivedNotifications$ = this.archivedNotificationInner$.pipe(
    scan<string, string[]>((acc, curr) => [...acc, curr], []),
    startWith<string[]>([])
  );

  notifications$ = combineLatest([
    this.notificationsInner$,
    this.archivedNotifications$,
  ]).pipe(
    map(([notifs, archived]) =>
      notifs
        .map((n) => ({
          ...n,
          archived: n.archived || archived.includes(n.notificationId),
        }))
        .filter((n) => !n.archived)
    )
  );

  constructor(private http: HttpClient) {}

  setNotificationsInner(notifications: INotification[]) {
    this.notificationsInner$.next(notifications);
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  getNotifications(opportunityId: string): Observable<INotification[]> {
    return this.http
      .get<INotification[]>(`${this.baseUrl}?opportunityId=${opportunityId}`)
      .pipe(tap((n) => this.notificationsInner$.next(n)));
  }

  archiveNotification(notificationId: string): Observable<INotification> {
    return this.http
      .post<INotification>(`${this.baseUrl}/${notificationId}/archive`, {})
      .pipe(tap((n) => this.archivedNotificationInner$.next(n.notificationId)));
  }
}
