import { DatePipe } from '@angular/common';
import { Inject, Pipe, PipeTransform } from '@angular/core';
import * as momentTz from 'moment-timezone';
import { IExpert } from '../../../../shared/models/expert.interface';
import { TIME_ZONE } from '../../../../shared/pipes/availability-date.pipe';

@Pipe({
  name: 'approvalTooltip',
})
export class ApprovalTooltipPipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe,
    @Inject(TIME_ZONE) private timezone: string
  ) {}
  transform(clientApproved?: IExpert['clientApproved']): string {
    if (!clientApproved) {
      return 'Expert approval required';
    }

    const offset = momentTz(clientApproved.dateCreated)
      .tz(this.timezone)
      .format('Z');

    const dateString = this.datePipe.transform(
      clientApproved.dateCreated,
      'medium',
      offset
    );

    if (!clientApproved.firstName && !clientApproved.lastName) {
      return `Approved on ${dateString}`;
    }

    return `Approved by ${clientApproved.firstName} ${clientApproved.lastName} on ${dateString}`;
  }
}
