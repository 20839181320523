import { DatePipe } from '@angular/common';
import { Inject, InjectionToken, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

export const TIME_ZONE = new InjectionToken<string>('TIME_ZONE');
export const TIME_ZONE_ABBR = new InjectionToken<string>('TIME_ZONE_ABBR');

export const getLocale = (): string =>
  `${navigator.language}`.toLowerCase() === 'en-us'
    ? navigator.language
    : 'en-GB';

export const getTimezone = (): string =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getTimezoneAbbr = (timeZone: string): string =>
  moment.tz(timeZone).zoneAbbr();

@Pipe({
  name: 'availabilityDate',
})
export class AvailabilityDatePipe implements PipeTransform {
  constructor(@Inject(TIME_ZONE) private timezone: string) {}

  transform(value: string): string {
    const date = moment(value).tz(this.timezone);
    const transformedDate = date.format('ddd Do MMMM YYYY');

    return `${transformedDate} (${date.zoneAbbr()})`;
  }
}

@Pipe({
  name: 'availabilityTime',
})
export class AvailabilityTimePipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe,
    @Inject(TIME_ZONE) private timezone: string
  ) {}

  transform(value: string): string {
    return this.datePipe.transform(value, 'shortTime', this.timezone);
  }
}
