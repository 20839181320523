import { Component, OnInit } from '@angular/core';
import { CognitoAuthService } from '@techspert-io/auth';

@Component({
  selector: 'app-not-authorised',
  templateUrl: './not-authorised.component.html',
  styleUrls: ['./not-authorised.component.scss'],
})
export class NotAuthorisedComponent implements OnInit {
  constructor(private auth: CognitoAuthService) {}

  ngOnInit(): void {
    this.auth.setRedirectUrl(null);
  }

  logout(): void {
    this.auth.logOut().subscribe();
  }
}
