import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AuthModule as Auth0Module } from '@auth0/auth0-angular';
import { AuthModule } from '@techspert-io/auth';
import { GrowthbookModule } from '@techspert-io/growthbook';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, GA_MEASUREMENT_ID } from './app.component';
import { OmnisearchModule } from './features/omnisearch/omnisearch.module';
import { PatternsModule } from './features/patterns/patterns.module';
import { PrivateModule } from './private/private.module';
import { PublicModule } from './public/public.module';
import { TopBarModule } from './shared/components/top-bar/top-bar.module';

@NgModule({
  imports: [
    PrivateModule,
    BrowserAnimationsModule,
    PublicModule,
    ReactiveFormsModule,
    AppRoutingModule,
    RouterModule,
    TopBarModule,
    NgxGoogleAnalyticsModule.forRoot(environment.gaMeasurementId),
    AuthModule.forRoot(environment.auth0.domain, environment.connectApiBaseUrl),
    Auth0Module.forRoot({
      ...environment.auth0,
      useRefreshTokens: true,
      cacheLocation: 'localstorage',
      authorizationParams: {
        ...environment.auth0.authorizationParams,
        redirect_uri: window.location.origin,
      },
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
    }),
    GrowthbookModule.forRoot(),
    OmnisearchModule,
    PatternsModule,
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    { provide: GA_MEASUREMENT_ID, useValue: environment.gaMeasurementId },
  ],
})
export class AppModule {
  constructor(private iconRegistry: MatIconRegistry) {
    this.iconRegistry.setDefaultFontSetClass('material-icons-outlined');
  }
}
