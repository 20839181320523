<div class="flex flex-col basis-1" style="margin: auto;" *ngIf="!confirmReason" data-test-id="on-hold-reason-dialog">
    <div class="flex justify-between content-center">
        <h3 mat-dialog-title data-test-id="on-hold-reason-dialog-title">Reason for putting this expert on hold</h3>
        <button mat-icon-button mat-dialog-close>
            <mat-icon data-test-id="on-hold-reason-dialog-close">clear</mat-icon>
        </button>
    </div>

    <mat-dialog-content class="flex flex-col gap-2">
        <p data-test-id="on-hold-reason-dialog-instructions">
            Let us know why this expert is being put on hold, so we can re-calibrate our
            searching and improve our recommendations
        </p>

        <mat-form-field style="width: 100%;" appearance="outline" data-test-id="on-hold-reason-dialog-notes">
            <mat-label data-test-id="on-hold-reason-dialog-reason-input-label">On hold notes</mat-label>
            <textarea matInput style="min-height: 200px;  resize: none;" [(ngModel)]="onHoldReason"
                data-test-id="on-hold-reason-dialog-reason-input"></textarea>
        </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions class="flex gap-2">
        <button type="button" mat-stroked-button mat-dialog-close color="primary" class="flex-1 primaryColor-border"
            data-test-id="on-hold-reason-dialog-cancel">
            Close window
        </button>
        <button class="flex-1" type="submit" mat-flat-button color="primary" (click)="confirm()"
            data-test-id="on-hold-reason-dialog-confirm">
            Confirm
        </button>
    </mat-dialog-actions>
</div>