export interface IDefaultPrompt {
  displayText: string;
  value: string;
}

export const multipleFilePrompts: IDefaultPrompt[] = [
  {
    displayText: 'Summarize key findings',
    value:
      'Thoroughly analyze the uploaded transcript files and summarize the key themes discussed. The discussions entail similar questions asked across the files to various experts. I am particularly interested in the responses provided by experts, and I need you to identify instances of agreement, disagreement, as well as the frequency of similar or differing opinions. In your analysis, please ensure that all files are reviewed make sure to include insights from all experts featured in the transcripts.',
  },
  {
    displayText: 'Generate a quote',
    value:
      'Thoroughly analyze the uploaded transcript files and select a mix of noteworthy quotes on [QUOTE TOPIC] from each file. Please provide the quotes along with their corresponding timecodes/locations within their respective documents.',
  },
  {
    displayText: 'Produce a CSV file',
    value: [
      `Create the content for a CSV file with the following specifications:`,
      `Columns: Each column should represent a key theme covered in the call.`,
      `Rows: Each row should represent an expert's response.`,
      `Cells: Each cell should contain a detailed summary of what the expert said about the corresponding theme. If the expert didn’t say anything related to the theme, leave the cell blank.`,
      `Header Row: Include a header row with the names of the key themes.`,
    ].join(' '),
  },
];

export const singleFilePrompts: IDefaultPrompt[] = [
  {
    displayText: 'Summarize key findings',
    value:
      'Thoroughly analyze the uploaded transcript file. Summarize the key findings and insights from the provided transcript file of a discussion with an expert. Include relevant quotes to support key points, and provide the corresponding timecode/location within the document.',
  },
  {
    displayText: 'Generate a quote',
    value:
      'Thoroughly analyze the uploaded transcript file. Identify a noteworthy quote that shows [QUOTE TOPIC] and provide the corresponding timecode/location within the document.',
  },
  {
    displayText: 'Produce a CSV file',
    value: [
      `Create the content for a CSV file with the following specifications:`,
      `Columns: Each column should represent a key theme covered in the call.`,
      `Rows: The single row should represent the expert's response.`,
      `Cells: Each cell should contain a detailed summary of what the expert said about the corresponding theme. If the expert didn’t say anything related to the theme, leave the cell blank.`,
      `Header Row: Include a header row with the names of the key themes.`,
    ].join(' '),
  },
];
