import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-expert-text',
  templateUrl: './expert-text.component.html',
  styleUrls: ['./expert-text.component.scss'],
})
export class ExpertTextComponent {
  @Input() public text: string;
}
