<div class="notification-container flex flex-col gap-3">
  <div class="flex flex-row justify-between">
    <h4>Activity Feed</h4>
    <mat-slide-toggle (change)="priorityToggleChange($event)">
      <h4>Priority</h4>
    </mat-slide-toggle>
  </div>

  <div *ngIf="notifications$ | async as notifications" class="content-container flex flex-col gap-4">
    <div *ngFor="let notification of notifications" class="flex flex-col gap-2">
      <span class="time">{{notification.date}}</span>
      <app-notification *ngFor="let item of notification.notifications" [notification]="item"
        (archiveNotification)="archiveNotification($event)"
        (routeAction)="routeAction($event, item)"></app-notification>
    </div>

    <ng-container *ngIf="!loading">
      <div *ngIf="!loggedIn" class="empty-notifications">
        <p>Log in to view events</p>
      </div>

      <div *ngIf="loggedIn && !notifications.length" class="empty-notifications">
        <p>No notifications at the moment</p>
      </div>
    </ng-container>

    <mat-spinner *ngIf="loading"></mat-spinner>
  </div>
</div>