<div class="omnisearch-container flex justify-start content-center">
  <form [formGroup]="searchForm" class="search-input gap-2 justify-start content-center">
    <mat-icon *ngIf="loadingQuery$ | async as loadingQuery; else notLoading" class="search-input-icon spin">
      cached
    </mat-icon>
    <input #search formControlName="search" class="search-input-input"
      placeholder="Search across your organization's experts & projects..." (keydown.escape)="setFocus(false)"
      (click)="setFocus(true)" data-test-id="omnisearch-search-input">
    <mat-icon class="search-input-icon cancel" (click)="emptySearch()" data-test-id="omnisearch-search-input-clear">
      close
    </mat-icon>
    <ng-template #notLoading>
      <mat-icon class="search-input-icon">search</mat-icon>
    </ng-template>
  </form>
</div>