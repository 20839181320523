<form [formGroup]="conferenceForm" (submit)="confirm()">
  <div class="flex justify-between content-center" data-test-id="request-conference-dialog">
    <span class="text-blue" mat-dialog-title data-test-id="request-conference-dialog-title">Request conference</span>
    <button mat-icon-button mat-dialog-close>
      <mat-icon data-test-id="request-conference-dialog-close">clear</mat-icon>
    </button>
  </div>


  <mat-dialog-content>
    <div class="flex flex-col gap-6">
      <div>
        <div class="flex" [style.font-weight]="'600'">
          <span class="flex basis-[144px]">Primary contact:</span> {{clientCallTime}}
        </div>
        <div class="flex"><span class="flex basis-[144px]">Expert:</span> {{expertCallTime}}</div>
      </div>

      <div class="contact-container flex flex-col gap-4">
        <mat-form-field appearance="outline" class="flex-1">
          <mat-label>Primary contact</mat-label>
          <mat-select formControlName="primaryRecipient"
            data-test-id="request-conference-dialog-primary-contact-select">
            <mat-option *ngFor="let contact of clientContacts" [value]="contact.email"
              data-test-id="request-conference-dialog-primary-contact-option">
              {{contact.firstName}} {{contact.lastName}}
            </mat-option>
          </mat-select>
          <mat-error>Required</mat-error>
        </mat-form-field>

        <ng-container formArrayName="recipients">
          <mat-form-field appearance="outline" class="flex-1"
            *ngFor="let ctrl of recipientsCtrls.controls; index as i; last as isLast">
            <mat-label>Recipient email</mat-label>
            <input matInput type="text" [formControl]="ctrl" (keyup)="isLast && recipientKeyDown($event)"
              (keydown.enter)="$event.preventDefault()" data-test-id="request-conference-dialog-recipient-input">
            <button type="button" matSuffix mat-icon-button (click)="removeRecipient(i)"
              data-test-id="request-conference-dialog-recipient-remove">
              <mat-icon>close</mat-icon>
            </button>
            <mat-error>Enter a valid email</mat-error>
          </mat-form-field>
        </ng-container>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="flex flex-col gap-2 h-full w-full min-h-full min-w-full">
      <button mat-flat-button color="accent" type="button" (click)="addRecipient(null, true)"
        data-test-id="request-conference-dialog-recipient-add">Add recipient email</button>

      <div class="flex">
        <button type="button" mat-stroked-button mat-dialog-close color="primary" class="flex-1 primaryColor-border">
          Cancel
        </button>
        <button class="flex-1" type="submit" mat-flat-button color="primary" data-test-id="request-conference-dialog">
          <span [style.padding-right]="'8px'">Send</span>
          <mat-icon>send</mat-icon>
        </button>
      </div>
    </div>
  </mat-dialog-actions>
</form>