<div class="flex justify-end gap-2.5 unit-cost-container">
  <span *ngIf="['Premium', 'Premium+'].includes(expert.costBand)" class="premium-band flex justify-start content-center"
    data-test-id="expert-unit-cost-band-label">
    {{expert.costBand}}
  </span>

  <span class="flex justify-start items-center unit-cost gap-2" [ngClass]="expert.costBand | lowercase">
    <mat-icon>timer</mat-icon>
    <span data-test-id="expert-unit-rate">{{expert.unitsPerHour || 1 | i18nPlural:unitsMapping}} p/hr</span>
  </span>
</div>