import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { SharedModule } from '../shared/shared.module';
import { OpportunityModule } from './opportunity/opportunity.module';
import { PublicComponent } from './public.component';

@NgModule({
  declarations: [PublicComponent],
  imports: [SharedModule, MatCardModule, OpportunityModule],
  exports: [PublicComponent],
  providers: [],
})
export class PublicModule {}
