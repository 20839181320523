<div class="flex flex-col gap-2" data-test-id="expert-timeline">
  <div *ngFor="let item of items; let last = last" class="timeline-item" [ngClass]="item.class"
    data-test-id="expert-timeline-item">
    <ng-container [ngTemplateOutlet]="expertTimelineItemTmpl"
      [ngTemplateOutletContext]="{icon: item.icon, title: item.title, dateTime: item.dateTime, name: item.name, showTimeline: !last}"></ng-container>
  </div>
</div>

<ng-template #expertTimelineItemTmpl let-icon="icon" let-title="title" let-dateTime="dateTime" let-name="name"
  let-showTimeline="showTimeline">
  <div class="flex gap-2">
    <div class="flex flex-col justify-center items-center timeline-col">
      <mat-icon data-test-id="expert-timeline-item-icon">
        {{icon}}
      </mat-icon>
      <div *ngIf="showTimeline">
        <div class="vertical-line"></div>
      </div>
    </div>

    <div class="detail-col flex flex-col">
      <div class="timeline-title" data-test-id="expert-timeline-item-title">
        {{title}}
      </div>

      <div *ngIf="name" class="timeline-name" data-test-id="expert-timeline-item-name">
        {{name}}
      </div>

      <div *ngIf="dateTime" class="timeline-datetime" data-test-id="expert-timeline-item-datetime">
        {{dateTime | date: 'EEE dd MMMM YYYY'}},
        {{dateTime | date: 'shortTime'}}
      </div>
    </div>
  </div>
</ng-template>