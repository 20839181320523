import { Component, Input } from '@angular/core';
import { CognitoAuthService } from '@techspert-io/auth';
import { ExpertFile } from '@techspert-io/expert-files';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { filter, finalize, takeUntil, tap } from 'rxjs/operators';
import {
  Add,
  Delete,
  ExpertFilesStateService,
  UpdateProgress,
} from '../../../../../shared/services/filestore-state.service';
import { FileStoreService } from '../../../../../shared/services/filestore.service';
import { AuthDialogInceptorService } from '../../../services/auth-dialog-inceptor.service';

@Component({
  selector: 'app-expert-files',
  templateUrl: './expert-files.component.html',
  styleUrls: ['./expert-files.component.scss'],
  providers: [ExpertFilesStateService],
})
export class ExpertFilesComponent {
  @Input() files: ExpertFile[];
  @Input() isAuthorised: boolean;
  @Input() filePrefix: string = '';

  loadingFiles$ = this.filesState.state$;
  downloadedFiles: Record<string, boolean> = {};

  constructor(
    private fileStore: FileStoreService,
    private filesState: ExpertFilesStateService,
    private authDialogInceptorService: AuthDialogInceptorService,
    private gaService: GoogleAnalyticsService,
    private cognitoAuthService: CognitoAuthService
  ) {}

  downloadFile(file: ExpertFile): void {
    this.authDialogInceptorService.authIntercept(this.isAuthorised, () =>
      this.downloadFileImpl(file)
    );
  }

  cancelDownload(fileName: string): void {
    this.filesState.dispatch(new Delete(fileName));
  }

  private downloadFileImpl(file: ExpertFile): void {
    this.filesState.dispatch(new Add(file.fileKey));
    this.fileStore
      .downloadExpertFile(
        file.fileKey,
        `${this.filePrefix ? this.filePrefix + '-' : ''}${file.fileName}.${
          file.fileExtension
        }`
      )
      .pipe(
        takeUntil(this.loadingFiles$.pipe(filter((d) => !d[file.fileKey]))),
        tap((event) =>
          this.filesState.dispatch(
            new UpdateProgress(file.fileKey, event.percentageComplete)
          )
        ),
        finalize(() => {
          this.downloadedFiles[file.fileKey] = true;
          this.filesState.dispatch(new Delete(file.fileKey));

          this.gaService.gtag('event', 'download', {
            event_category: 'expert_files',
            event_label: file.type,
            value: file.fileId,
            dimension1: this.cognitoAuthService.loggedInUser.id,
          });
        })
      )
      .subscribe();
  }
}
