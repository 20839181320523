<div class="transcript-container flex flex-col gap-2">
  <mat-spinner style="margin: auto" *ngIf="loadingFiles"></mat-spinner>

  <ng-container *ngIf="!loadingFiles">
    <div class="transcript-container flex flex-col flex-grow">
      <ng-container *ngIf="(!transcriptFile || !transcriptChunks.length)">
        <b>Transcript</b>
        <span>Please select a file to read</span>
      </ng-container>

      <div class="transcript-content" *ngIf="transcriptFile && transcriptChunks.length">
        <div class="chunk" *ngFor="let chunk of transcriptChunks" [class.highlight]="chunk.highlighted">
          <div class="flex flex-row justify-between items-center">
            <span>{{chunk.start}} --> {{chunk.end}}</span>

            <mat-icon (click)="updateQuote(chunk)" [matTooltip]="chunk.savedQuoteId ? 'Unfavorite' : 'Favorite'">
              {{chunk.loading ? 'autorenew' : chunk.savedQuoteId ? 'star' : 'star_outlined'}}
            </mat-icon>
          </div>
          <span><b>{{chunk.speaker}}</b>: {{chunk.content}}</span>
        </div>
      </div>
    </div>

    <div class="transcript-container" style="min-height: 125px">
      <div class="flex items-center justify-between">
        <b>Audio</b>
        <div *ngIf="transcriptFile && transcriptChunks.length" class="flex gap-2 items-center">
          <span>Auto scroll</span>
          <mat-slide-toggle [(ngModel)]="autoScroll"></mat-slide-toggle>
        </div>
      </div>
      <span *ngIf="!audioData && !transcriptFile">Please select a file to play</span>
      <span *ngIf="!audioData && transcriptFile">Audio file is unavailable</span>

      <audio *ngIf="audioData" [audioTime]="audioStartTime" controls (timeupdate)="updateTime($event)">
        <source [src]="audioData" type="audio/mpeg">
        Your browser does not support the audio element.
      </audio>
    </div>
  </ng-container>
</div>