export const environment = {
  production: false,
  base: 'https://staging.portal.techspert.com',
  connectApiBaseUrl: 'https://staging.api.connect.techspert.io',
  filestoreEndpoint: 'https://staging.api.connect.techspert.io/filestore',
  screenerEndpoint: 'https://staging.screener.techspert.com',
  gaMeasurementId: 'G-LK2X20ZPPE',
  auth0: {
    domain: 'staging.id.techspert.com',
    clientId: 'hk0RS1PaTcrxnBQXe4eoS7bJOcGVFFYv',
    authorizationParams: {
      connection: 'email',
      audience: 'https://staging.api.techspert.io',
    },
  },
  growthBookClientKey: 'sdk-9cjmaSEoFhapMHya',
};
