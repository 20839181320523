import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import * as moment from 'moment-timezone';
import { catchError, tap } from 'rxjs/operators';
import { IExpert } from '../../../../../../shared/models/expert.interface';
import { TIME_ZONE_ABBR } from '../../../../../../shared/pipes/availability-date.pipe';
import { NotificationService } from '../../../../../../shared/services/notification.service';

@Component({
  selector: 'app-expert-no-show-dialog',
  templateUrl: './expert-no-show-dialog.component.html',
  styleUrls: ['./expert-no-show-dialog.component.scss'],
  animations: [
    trigger('fade', [
      state(
        'a',
        style({
          opacity: 0,
        })
      ),
      state(
        'b',
        style({
          opacity: 1,
        })
      ),
      transition('a => b', animate('400ms ease-in')),
      transition('b => a', animate('400ms ease-out')),
    ]),
  ],
})
export class ExpertNoShowDialogComponent {
  public stage: 'confirmation' | 'loading' | 'completed' | 'error' =
    'confirmation';
  public officeHours = {
    start: moment().tz('Europe/London').hours(9).minutes(0),
    end: moment().tz('America/Chicago').hours(17).minutes(0),
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { expert: IExpert },
    @Inject(TIME_ZONE_ABBR) public timezoneAbbr: string,
    public notificationService: NotificationService,
    public dialogRef: MatDialogRef<ExpertNoShowDialogComponent>,
    public dialog: MatDialog
  ) {}

  public sendRequest(): void {
    this.stage = 'loading';
    this.notificationService
      .expertNoShow(this.data.expert.expertId)
      .pipe(
        tap(() => (this.stage = 'completed')),
        catchError(() => (this.stage = 'error'))
      )
      .subscribe();
  }
}
