import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoAuthService, IUser } from '@techspert-io/auth';
import { combineLatest, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OmnisearchService } from '../../../features/omnisearch/services/omnisearch.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit {
  showOmnisearch = false;
  isOmnisearchAuthorised = false;
  omnisearchSalesCarrot = false;
  innerWidth: number;

  get loggedInUser(): IUser {
    return this.cognitoAuthService.loggedInUser;
  }

  get loggedInAuth0(): Observable<boolean> {
    return this.cognitoAuthService.loggedInAuth0$;
  }

  get showReportsItem(): boolean {
    return (
      this.loggedInUser &&
      this.loggedInUser.userRoles.includes('clientAdmin') &&
      Object.values(this.loggedInUser.clients).some(
        (d) => d.features.clientContractReportEnabled
      )
    );
  }

  constructor(
    private cognitoAuthService: CognitoAuthService,
    private omnisearchService: OmnisearchService,
    private router: Router
  ) {
    this.configureOmnisearch();
  }

  ngOnInit(): void {
    combineLatest([
      this.omnisearchService.omnisearchEnabled$,
      this.cognitoAuthService.loggedInConnect$,
    ])
      .pipe(tap(([enabled]) => (this.isOmnisearchAuthorised = enabled)))
      .subscribe();

    this.omnisearchService
      .getShowOmnisearch()
      .pipe(tap((show) => (this.showOmnisearch = show)))
      .subscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.configureOmnisearch();
  }

  showOmni(): void {
    this.omnisearchService.setShowOmnisearch(true);
    this.omnisearchService.setFocused(true);
  }

  logout(): void {
    this.cognitoAuthService.logOut().subscribe();
  }

  navigateRoot(): void {
    this.router.navigate(['/']);
  }

  private configureOmnisearch(): void {
    this.innerWidth = window.innerWidth;
    this.omnisearchService.setShowOmnisearch(window.innerWidth > 600);
  }
}
