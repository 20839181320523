<div class="omnisearch-item flex gap-2" [attr.data-test-id]="'client-result-expert-' + omnisearchResponse.id">
  <div *ngIf="loadingFiles$ | async as loadingFiles" class="result-container flex flex-col gt-sm:flex-row gap-2">
    <div class="info flex grow gap-3 justify-start content-start">
      <div class="flex flex-col justify-center items-center gap-1" style="min-width: 48px">
        <container-element [ngSwitch]="omnisearchResponse.portalPhase">
          <img src="../../../../../assets/images/new_expert.svg" matTooltip="New profile" *ngSwitchCase="'newExperts'">

          <img src="../../../../../assets/images/scheduled_expert.svg" matTooltip="Upcoming Connection"
            *ngSwitchCase="'acceptedExperts'">

          <img src="../../../../../assets/images/completed_expert.svg" matTooltip="Completed Connection"
            *ngSwitchCase="'completedConnections'">

          <img src="../../../../../assets/images/rejected_expert.svg" matTooltip="Rejected Profile"
            *ngSwitchCase="'declinedExperts'">
        </container-element>

        <mat-icon *ngIf="omnisearchResponse.isClientExpert || omnisearchResponse.portalPhase" class="cursor-pointer"
          [@bounce]="animationState['favouriteState']" (@bounce.done)="bounceEnd('favouriteState')"
          [class.txpPink]="omnisearchResponse.favourited" (click)="onToggleFavourited()">
          {{ omnisearchResponse.favourited ? 'favorite' : 'favorite_outline' }}
        </mat-icon>
      </div>
      <div class="flex flex-col gap-2">
        <div class="flex justify-start items-center">
          <div class="title" [innerHTML]="omnisearchResponse.name | searchHighlight: searchTerm | safeHtml"
            data-test-id="omnisearch-result-expert-name">
          </div>
          <mat-icon *ngIf="omnisearchResponse.files.length && !showAttachments" class="file-menu"
            (click)="showAttachments = true">
            attach_file
          </mat-icon>
          <mat-icon *ngIf="omnisearchResponse.files.length" [matMenuTriggerFor]="menu" class="file-menu">
            attach_file
          </mat-icon>
          <mat-menu #menu="matMenu">
            <button mat-menu-item class="menu-button justify-start content-center gap-2"
              [class.dimmed]="loadingFiles[file.fileKey]" (click)="download(file)"
              *ngFor="let file of omnisearchResponse.files">

              <ng-container [ngTemplateOutlet]="fileImpl"
                [ngTemplateOutletContext]="{file, loadingFiles, dropdown: true}">

              </ng-container>
            </button>
            <button mat-menu-item (click)="downloadAll(omnisearchResponse.files)">
              <span class="file-name">Download all</span>
            </button>
          </mat-menu>
        </div>

        <div class="geo-expertise">
          <div class="opp-name flex justify-start items-center" *ngIf="omnisearchResponse.oppName?.includes('-')"
            data-test-id="omnisearch-result-expert-opportunity-name"
            [innerHTML]="omnisearchResponse.oppName.split('-')[0].trim() | searchHighlight: searchTerm | safeHtml">
          </div>
          <div class="geo-profile flex gap-1 justify-start items-center"
            *ngIf="omnisearchResponse.geographicTarget && omnisearchResponse.profileType"
            data-test-id="omnisearch-result-geo-target-and-profile-type">
            <span [innerHTML]="omnisearchResponse.geographicTarget | searchHighlight: searchTerm | safeHtml"
              data-test-id="omnisearch-result-geo-target"></span>
            <span [innerHTML]="omnisearchResponse.profileType | searchHighlight: searchTerm | safeHtml"
              data-test-id="omnisearch-result-profile-type"></span>
          </div>
          <div class="expertise" *ngFor="let expertise of omnisearchResponse.expertise | slice:0:5"
            [innerHTML]="expertise | lowercase | searchHighlight: searchTerm | safeHtml"
            data-test-id="omnisearch-result-expert-expertise">
          </div>
        </div>
      </div>
    </div>
    <div class="bio-container flex flex-col justify-start content-start gap-2" *ngIf="omnisearchResponse.bio"
      data-test-id="omnisearch-result-expert-bio">
      <div class="affiliation" [innerHTML]="omnisearchResponse.portalAffiliations | safeHtml">
      </div>
      <span class="bio"
        [innerHTML]="omnisearchResponse.bio | bioEllipsis: omnisearchResponse.bio | searchHighlight: searchTerm | safeHtml">
      </span>
    </div>
    <ng-container *ngIf="showAttachments">
      <div *ngFor="let file of omnisearchResponse.files">
        <div [class.dimmed]="loadingFiles[file.fileKey]" class="flex gap-2 justify-start content-center"
          (click)="download(file)">

          <ng-container [ngTemplateOutlet]="fileImpl" [ngTemplateOutletContext]="{file, loadingFiles, dropdown: false}">
          </ng-container>
        </div>
      </div>
    </ng-container>
    <div class="button-container flex flex-col gap-2 justify-start content-start">
      <button mat-flat-button class="flex-grow"
        [ngClass]="{'primary': !engagementRequested, 'success': engagementRequested}" (click)="requestEngagement()"
        [disabled]="engagementRequested" data-test-id="omnisearch-result-request-engagement-button">
        <span *ngIf="engagementRequested">Call requested</span>

        <span *ngIf="!engagementRequested">
          {{omnisearchResponse.portalPhase === 'completedConnections' ? 'Request another call' : 'Request a call'}}
        </span>
      </button>
      <button mat-stroked-button class="flex-grow" *ngIf="omnisearchResponse.portalPhase"
        [matTooltip]="omnisearchResponse.entity | urlTooltip" (click)="navigateToExpert(omnisearchResponse)"
        data-test-id="omnisearch-result-view-in-project-button">
        <span class="stroked">View in project</span>
      </button>
    </div>
  </div>
</div>

<ng-template #fileImpl let-file="file" let-loadingFiles="loadingFiles" let-dropdown="dropdown">
  <mat-icon *ngIf="!dropdown" class="icon-color">
    {{file | fileDisplayIcon }}
  </mat-icon>

  <span class="file-name" [matTooltip]="file | fileDisplayName">
    {{file | fileDisplayName:34}}
  </span>

  <mat-icon class="icon-color" style="margin-right: 0 !important">
    {{loadingFiles[file.fileKey] ? 'downloading' : downloadedFiles[file.fileKey] ? 'download_done' : 'download'}}
  </mat-icon>
</ng-template>