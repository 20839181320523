<div class="flex flex-col basis-1" data-test-id="rejection-reason-dialog">
  <div class="flex justify-between content-center">
    <h3 mat-dialog-title data-test-id="rejection-reason-dialog-title">Reason for rejection</h3>
    <button mat-icon-button mat-dialog-close>
      <mat-icon data-test-id="rejection-reason-dialog-close">clear</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="flex flex-col gap-2">
    <p data-test-id="rejection-reason-dialog-instructions">
      Let us know why this expert wasn't right, so we can re-calibrate our searching and improve our recommendations
    </p>

    <mat-form-field style="width: 100%;" appearance="outline" data-test-id="rejection-reason-dialog-notes">
      <mat-label data-test-id="rejection-reason-dialog-reason-input-label">Rejection notes</mat-label>
      <textarea matInput style="min-height: 200px; resize: none" [(ngModel)]="rejectionReason"
        data-test-id="rejection-reason-dialog-reason-input"></textarea>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions class="flex gap-2">
    <button type="button" mat-stroked-button mat-dialog-close color="primary" class="flex-1 primaryColor-border"
      data-test-id="rejection-reason-dialog-cancel">
      Close window
    </button>
    <button class="flex-1" type="submit" mat-flat-button color="primary" (click)="confirm()"
      data-test-id="rejection-reason-dialog-confirm">
      Confirm
    </button>
  </mat-dialog-actions>
</div>