import { Pipe, PipeTransform } from '@angular/core';
import { OmnisearchEntity } from '../models/omnisearch.models';

@Pipe({
  name: 'urlTooltip',
})
export class UrlTooltipPipe implements PipeTransform {
  transform(entity: OmnisearchEntity): string {
    switch (entity) {
      case 'opportunities':
        return 'Go to project';
      case 'portalExperts':
        return 'Go to expert';
    }
  }
}
