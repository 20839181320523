import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sparkle',
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    [style.width]="width"
    [style.height]="height"
    zoomAndPan="magnify"
    viewBox="0 0 375 374.999991"
    preserveAspectRatio="xMidYMid meet"
    version="1.0"
  >
    <defs>
      <clipPath id="9e336ab0bd">
        <path
          d="M 45.210938 79.148438 L 340.710938 79.148438 L 340.710938 374.648438 L 45.210938 374.648438 Z M 45.210938 79.148438 "
          clip-rule="nonzero"
        />
      </clipPath>
      <clipPath id="4a8525c4f0">
        <path
          d="M 0 0 L 172.5 0 L 172.5 172.5 L 0 172.5 Z M 0 0 "
          clip-rule="nonzero"
        />
      </clipPath>
      <clipPath id="2172672314">
        <path
          d="M 242.601562 65.730469 L 340.851562 65.730469 L 340.851562 163.980469 L 242.601562 163.980469 Z M 242.601562 65.730469 "
          clip-rule="nonzero"
        />
      </clipPath>
    </defs>
    <g clip-path="url(#9e336ab0bd)">
      <path
        [style.fill]="fillColour"
        d="M 193.128906 79.148438 C 178.714844 200.964844 167.027344 212.652344 45.210938 227.070312 C 45.464844 227.097656 45.710938 227.128906 45.964844 227.160156 L 45.96875 227.160156 C 167.070312 241.535156 178.742188 253.425781 193.128906 374.988281 C 207.546875 253.171875 219.234375 241.484375 341.050781 227.070312 C 219.234375 212.652344 207.546875 200.964844 193.128906 79.148438 Z M 193.128906 79.148438 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
    </g>
    <g clip-path="url(#4a8525c4f0)">
      <path
        [style.fill]="fillColour"
        d="M 86.265625 0 C 77.859375 71.042969 71.042969 77.859375 0 86.265625 C 0.148438 86.285156 0.292969 86.300781 0.441406 86.320312 C 71.070312 94.703125 77.878906 101.640625 86.265625 172.535156 C 94.675781 101.492188 101.492188 94.675781 172.535156 86.265625 C 101.492188 77.859375 94.675781 71.042969 86.265625 0 Z M 86.265625 0 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
    </g>
    <g clip-path="url(#2172672314)">
      <path
        [style.fill]="fillColour"
        d="M 291.824219 65.730469 C 287.027344 106.269531 283.140625 110.160156 242.601562 114.957031 C 242.683594 114.964844 242.769531 114.976562 242.851562 114.984375 L 242.851562 114.988281 L 242.855469 114.988281 C 283.152344 119.769531 287.039062 123.726562 291.824219 164.179688 C 296.625 123.644531 300.511719 119.753906 341.050781 114.957031 C 300.511719 110.160156 296.625 106.269531 291.824219 65.730469 Z M 291.824219 65.730469 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
    </g>
  </svg>`,
  styleUrls: [],
})
export class SparkleComponent {
  @Input() fillColour = '#fad870';
  @Input() width = 26;
  @Input() height = 26;
}
