import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { IOpportunity } from '@techspert-io/opportunities';
import { IExpert } from '../../../shared/models/expert.interface';

interface IExpertChanges extends SimpleChange {
  previousValue: IExpert[];
  currentValue: IExpert[];
}

interface IExpertListChanges extends SimpleChanges {
  experts: IExpertChanges;
}

@Component({
  selector: 'app-expert-list',
  templateUrl: './expert-list.component.html',
  styleUrls: ['./expert-list.component.scss'],
})
export class ExpertListComponent implements OnInit, OnChanges {
  @Input() experts: IExpert[];
  @Input() expertLoadingIds: Record<string, boolean>;
  @Input() viewStage: string = '';
  @Input() opportunity: IOpportunity;
  @Input() isFileDownloadAuthorised: boolean;
  @Input() isClientUser: boolean = false;
  @Input() isExpertApprovalRequired: boolean;
  @Input() showEchoAsk: boolean;

  expertsFiltered: IExpert[] = [];
  onHoldExperts: IExpert[] = [];

  ngOnInit(): void {
    this.filterExperts(this.experts);
  }

  ngOnChanges(sc: IExpertListChanges): void {
    if (sc.experts && sc.experts.previousValue) {
      this.filterExperts(sc.experts.currentValue);
    }
  }

  filterExperts(experts: IExpert[]): void {
    this.expertsFiltered = experts.filter((e) => !e.onHold);
    this.onHoldExperts = experts.filter((e) => e.onHold);
  }
}
