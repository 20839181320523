import { ExpertFile } from '@techspert-io/expert-files';
import { IExpertPhases } from '../../../shared/state/expert.service';

export enum OmnisearchEntity {
  portalExperts = 'portalExperts',
  opportunities = 'opportunities',
  clients = 'clients',
}

export interface IOmnisearchExpert {
  id: string;
  entity: OmnisearchEntity.portalExperts;
  name: string;
  expertise: string[];
  oppId: string;
  oppName: string;
  clientId: string;
  portalAffiliations: string[];
  profileType: string;
  geographicTarget: string;
  portalPhase: keyof IExpertPhases;
  bio: string;
  favourited: boolean;
  files: ExpertFile[];
}

export interface IOmnisearchOpportunity {
  id: string;
  entity: OmnisearchEntity.opportunities;
  name: string;
  clientId: string;
}

export type OmnisearchQueryResponse =
  | IOmnisearchExpert
  | IOmnisearchOpportunity;

export interface IRedisExpertFields {
  expertise: boolean;
  name: boolean;
  bio: boolean;
  geographicTarget: boolean;
  portalAffiliations: boolean;
  profileType: boolean;
}
