import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BookingSource, BookingType } from './request-engagement.service';

export interface IEngagementRequestPayload {
  expertId: string;
  notes: string;
  segmentId: string;
  loggedInUserId?: string;
  targetOpportunityId?: string;
  bookingType: BookingType;
  bookingSource: BookingSource;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(protected http: HttpClient) {}

  sendEngagementRequestNotification(
    engagementRequestPayload: IEngagementRequestPayload
  ): Observable<void> {
    return this.http
      .post<void>(`/notification/requestEngagement`, engagementRequestPayload)
      .pipe(
        catchError((err) => {
          console.error(err);
          return EMPTY;
        })
      );
  }

  expertNoShow(expertId: string): Observable<void> {
    return this.http.get<void>(
      `/notification/portal/expert/${expertId}/noShow`
    );
  }
}
