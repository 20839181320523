import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IOpportunitySegmentData } from '../models/opportunitySegments.models';

@Injectable()
export class OpportunitySegmentsService {
  private readonly baseUrl = '/opportunity-segments';

  constructor(private http: HttpClient) {}

  get(opportunitySegmentId: string): Observable<IOpportunitySegmentData> {
    return this.http.get<IOpportunitySegmentData>(
      `${this.baseUrl}/${opportunitySegmentId}`
    );
  }
}
