import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExpertFile } from '@techspert-io/expert-files';
import { Observable } from 'rxjs';
import { IExpertFile } from '../../files/models/expert-file.model';
import { AssistantStatus, IThreadMessage } from '../models/assistant.models';
import { IAssistantThread } from '../models/assistantThread.models';

export interface ISendAssistantMessage {
  resourceIds: string[];
  resourceType: 'expert' | 'opportunity' | 'segment';
  message: string;
  openAiThreadId?: string;
}

type RunStatus = 'pending' | 'failed' | 'completed';

export type EnrichedFile = ExpertFile & {
  segmentName: string;
  callCount: number;
};

export interface IQueryableEntity {
  resourceId: string;
  resource: 'segment' | 'expert';
  name: string;
  files: EnrichedFile[];
}

export interface IQueryInitialiseAssistantRes {
  status: AssistantStatus;
  externalFileMap: Record<string, IExpertFile>;
}

@Injectable()
export class AssistantService {
  constructor(protected http: HttpClient) {}

  sendMessage(payload: ISendAssistantMessage) {
    return this.http.post<{
      runId: string;
      openAiThreadId: string;
    }>('/client-portal/assistant/message', payload);
  }

  pollMessages(
    resourceIds: string[],
    resourceType: 'expert' | 'opportunity' | 'segment',
    runId: string,
    openAiThreadId: string
  ) {
    const params = new URLSearchParams({
      resourceIds: resourceIds.join(','),
      resourceType,
      runId,
      openAiThreadId,
    });

    return this.http.get<{ status: RunStatus; thread: IThreadMessage[] }>(
      `/client-portal/assistant/messages?${params.toString()}`
    );
  }

  initialise(
    resourceIds: string[],
    entity: string
  ): Observable<IQueryInitialiseAssistantRes> {
    const params = new URLSearchParams({
      resourceIds: resourceIds.join(','),
      entity,
    });

    return this.http.post<IQueryInitialiseAssistantRes>(
      `/client-portal/assistant?${params.toString()}`,
      {}
    );
  }

  getAssistantEntities(opportunityId: string): Observable<IQueryableEntity[]> {
    return this.http.get<IQueryableEntity[]>(
      `/client-portal/assistant/entities/${opportunityId}`
    );
  }

  getThreads(opportunityId: string) {
    return this.http.get<IAssistantThread[]>(
      `/client-portal/assistant/threads?opportunityId=${opportunityId}`
    );
  }

  getThreadMessages(threadId: string) {
    return this.http.get<IThreadMessage[]>(
      `/client-portal/assistant/threads/${threadId}/messages`
    );
  }

  deleteThread(threadId: string) {
    return this.http.delete<void>(
      `/client-portal/assistant/threads/${threadId}`
    );
  }
}
