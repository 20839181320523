import { NgModule } from '@angular/core';
import { GROWTHBOOK } from '../token';

const baseGrowthbook = {
  getFeatureValue: () => true,
  init: () => Promise.resolve(),
};

@NgModule()
export class GrowthbookTestingModule {
  static forRoot(options: { getFeatureValue?: () => unknown } = {}) {
    return {
      ngModule: GrowthbookTestingModule,
      providers: [
        {
          provide: GROWTHBOOK,
          useValue: {
            ...baseGrowthbook,
            ...options,
          },
        },
      ],
    };
  }
}
