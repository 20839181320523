import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { BaseUrlInterceptor } from './services/base-url.interceptor';
import { BearerTokenInterceptor } from './services/bearer-token.interceptor';
import { CognitoAuthService } from './services/cognito-auth.service';
import {
  AUTH0_DOMAIN,
  CONNECT_API_BASE_URL,
  LOCAL_STORAGE,
} from './services/token';

@NgModule()
export class AuthModule {
  static forRoot(
    auth0Domain: string,
    connectApiBaseUrl: string
  ): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: BaseUrlInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: BearerTokenInterceptor,
          multi: true,
        },
        {
          provide: CONNECT_API_BASE_URL,
          useValue: connectApiBaseUrl,
        },
        {
          provide: LOCAL_STORAGE,
          useValue: localStorage,
        },
        {
          provide: AUTH0_DOMAIN,
          useValue: auth0Domain,
        },
        CognitoAuthService,
      ],
    };
  }
}
