import { NgModule } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';

const pipes = [SafeHtmlPipe];

@NgModule({
  declarations: [...pipes],
  exports: [...pipes],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { subscriptSizing: 'dynamic', appearance: 'outline' },
    },
  ],
})
export class PatternsModule {}
