<div class="statistics mat-typography flex flex-col gt-sm:flex-row" *ngIf="expertStore.experts$ | async as observables">
    <mat-card class="stat-card flex-1 gap-3" style="background-color: #57CF69" (click)="adjustTab(1)">
        <div class="title">New Profiles to review</div>
        <div class="central-container">
            {{observables.newExperts.length}}
        </div>
        <div class="flex">
            <div class="review">Review your new profiles</div>
            <mat-icon>arrow_forward</mat-icon>
        </div>
    </mat-card>
    <mat-card class="stat-card flex-1 gap-3" style="background-color: #1e2b4e" (click)="adjustTab(2)">
        <div class="title">Scheduled Connections</div>
        <div class="central-container">
            {{observables.acceptedExperts.length}}
        </div>
        <div class="flex">
            <div class="review">Review scheduled profiles</div>
            <mat-icon>arrow_forward</mat-icon>
        </div>
    </mat-card>
    <mat-card class="stat-card flex-1 gap-3" style="background-color: #B5BFCC" (click)="adjustTab(3)">
        <div class="title">Completed Connections</div>
        <div class="central-container">
            {{observables.completedConnections.length}}
        </div>
        <div class="flex">
            <div class="review">View completed profiles</div>
            <mat-icon>arrow_forward</mat-icon>
        </div>
    </mat-card>
</div>

<div *ngIf="emptyExperts && showOmnisearchBanner" class="flex justify-center content-center"
    data-test-id="geographic-progress-omnisearch-banner">
    <mat-card class="omnisearch-banner primaryColorBG">
        <mat-card-content>
            Want a head start on scheduling expert calls? Use
            <b class="omnisearch secondaryColor" (click)="openOmnisearch.emit()"
                data-test-id="geographic-progress-omnisearch-banner-link">Omnisearch</b>
            to see if your colleagues have spoken with similar experts
        </mat-card-content>
    </mat-card>
</div>

<div class="progress-container flex flex-col gap-2">
    <img *ngIf="emptyExperts" class="empty-progress" src="./../../../../assets/images/empty-progress.png">

    <div class="progress-header flex justify-between items-center" *ngIf="!emptyExperts">
        <h4>Project Progress Tracker</h4>
        <button mat-stroked-button color="primary" class="flex export primaryColor-border"
            (click)="downloadProgressCsv()" data-test-id="geographic-progress-export">
            <mat-icon class="secondaryColor">ic_file_download</mat-icon>
            <span data-test-id="geographic-progress-export-label">Export progress</span>
        </button>
    </div>
    <table class="mat-typography" mat-table *ngIf="!emptyExperts" [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="summary">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                matTooltip="Segment summaries are available once you have completed your first call for that segment">
                <span>Summaries</span>
                <mat-icon>info_outline</mat-icon>
            </th>
            <td mat-cell *matCellDef="let element">
                <button mat-stroked-button color="primary" [disabled]="!element.transcriptionSummaryKey" class="export"
                    (click)="downloadSummary(element)" data-test-id="geographic-progress-download-segment-summary">
                    <mat-icon class="secondaryColor">ic_file_download</mat-icon>
                    <span data-test-id="geographic-progress-download-segment-summary-label">
                        Download
                    </span>
                </button>
            </td>
            <td mat-footer-cell *matFooterCellDef>TOTAL</td>
        </ng-container>
        <ng-container matColumnDef="geography">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Geography
            </th>
            <td mat-cell *matCellDef="let element" data-test-id="geographic-progress-segment-summary-table-geography">
                {{element.geography}}
            </td>
            <td mat-footer-cell *matFooterCellDef>
                {{opportunity.createSegmentTranscriptSummary ? '' : 'TOTAL' }}
            </td>
        </ng-container>
        <ng-container matColumnDef="segment">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Profile Type
            </th>
            <td mat-cell *matCellDef="let element"
                data-test-id="geographic-progress-segment-summary-table-profile-type">
                {{element.segment}}
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="screening">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Screening</th>
            <td mat-cell *matCellDef="let element" data-test-id="geographic-progress-segment-summary-table-screening">
                <div class="center-align">{{element.screening}}</div>
            </td>
            <td mat-footer-cell *matFooterCellDef
                data-test-id="geographic-progress-segment-summary-table-screening-total">
                <div class="center-align">
                    <div class="total">
                        {{opportunity.opportunitySegmentStatsTotal?.screening}}
                    </div>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="inPortal">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>New</th>
            <td mat-cell *matCellDef="let element" data-test-id="geographic-progress-segment-summary-table-in-portal">
                <div class="center-align">{{element.inPortal}}</div>
            </td>
            <td mat-footer-cell *matFooterCellDef
                data-test-id="geographic-progress-segment-summary-table-in-portal-total">
                <div class="center-align">
                    <div class="total">
                        {{opportunity.opportunitySegmentStatsTotal?.inPortal}}
                    </div>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="scheduled">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <span>Upcoming</span>
            </th>
            <td mat-cell *matCellDef="let element" data-test-id="geographic-progress-segment-summary-table-upcoming">
                <div class="center-align">
                    {{element.scheduled}}</div>
            </td>
            <td mat-footer-cell *matFooterCellDef
                data-test-id="geographic-progress-segment-summary-table-upcoming-total">
                <div class="center-align">
                    <div class="total">
                        {{opportunity.opportunitySegmentStatsTotal?.scheduled}}
                    </div>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="completed">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Completed</th>
            <td mat-cell *matCellDef="let element" data-test-id="geographic-progress-segment-summary-table-completed">
                <div class="center-align">{{element.completed}}</div>
            </td>
            <td mat-footer-cell *matFooterCellDef
                data-test-id="geographic-progress-segment-summary-table-completed-total">
                <div class="center-align">
                    <div class="total">
                        {{opportunity.opportunitySegmentStatsTotal?.completed}}
                    </div>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="confirmed">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                matTooltip="Confirmed connections is the total number of scheduled and completed calls"
                data-test-id="geographic-progress-segment-summary-table-confirmed-header">
                <b style="color: black">Confirmed</b>
                <mat-icon>info_outline</mat-icon>
            </th>
            <td mat-cell *matCellDef="let element" data-test-id="geographic-progress-segment-summary-table-confirmed">
                <div class="center-align">{{element.confirmed}}</div>
            </td>
            <td mat-footer-cell *matFooterCellDef
                data-test-id="geographic-progress-segment-summary-table-confirmed-total">
                <div class="center-align">
                    <div class="total">
                        <b>{{opportunity.opportunitySegmentStatsTotal?.confirmed}}</b>
                    </div>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="target">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <b style="color: black">Target</b>
            </th>
            <td mat-cell *matCellDef="let element" data-test-id="geographic-progress-segment-summary-table-target">
                <div class="center-align">
                    <div class="center-align">
                        <div class="total">
                            <b>{{element.target}}</b>
                        </div>
                    </div>
                </div>
            </td>
            <td mat-footer-cell *matFooterCellDef data-test-id="geographic-progress-segment-summary-table-target-total">
                <div class="center-align">
                    <div class="total">
                        <b>{{opportunity.opportunitySegmentStatsTotal?.target}}</b>
                    </div>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="body-row"
            [attr.data-test-id]="'geographic-progress-segment-summary-table-row-' + row.opportunitySegmentId"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns" class="footer-row"></tr>
    </table>
</div>