import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { OmnisearchModule } from '@techspert-io/omnisearch';
import { TxpLogoComponent } from './components/txp-logo/txp-logo.component';
import { CtaBannerComponent } from './cta-banner/cta-banner.component';
import { PortalFAQLinkDirective } from './directives/portal-faqlink.directive';
import { PortalNewLinkDirective } from './directives/portal-newLink.directive';
import { TopBarComponent } from './top-bar.component';

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    OmnisearchModule,
    MatIconModule,
    MatButtonModule,
    RouterModule,
  ],
  exports: [TopBarComponent, CtaBannerComponent],
  declarations: [
    TopBarComponent,
    TxpLogoComponent,
    CtaBannerComponent,
    PortalFAQLinkDirective,
    PortalNewLinkDirective,
  ],
})
export class TopBarModule {}
