<form #timeForm="ngForm" [formGroup]="availabiltyForm" (submit)="sendRequest()">
  <div class="flex justify-between content-center" data-test-id="request-time-change-form-dialog">
    <h3 mat-dialog-title class="text-blue" data-test-id="request-time-change-form-title">
      Request a consultation time
    </h3>
    <button mat-icon-button mat-dialog-close>
      <mat-icon data-test-id="request-time-change-form-close">clear</mat-icon>
    </button>
  </div>

  <h3 *ngIf="error" style="margin: auto">{{error}}</h3>

  <ng-container *ngIf="!error">
    <mat-dialog-content>
      <div class="flex flex-col gap-4">
        <h4 data-test-id="request-time-change-form-instruction-step">1. Please confirm your time zone</h4>

        <mat-form-field>
          <mat-label data-test-id="request-time-change-form-user-timezone-select-label">Your timezone</mat-label>
          <mat-select formControlName="timezone" data-test-id="request-time-change-user-timezone-select">
            <mat-option *ngFor="let option of tzOptions" [value]="option"
              data-test-id="request-time-change-user-timezone-select-option">
              {{option}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="alert flex gap-2" *ngIf="showAlert" data-test-id="request-time-change-form-expert-timezone-hint">
          <mat-icon class="info">info_outline</mat-icon>

          <div class="flex flex-col gap-2">
            <b class="text-blue" *ngIf="expertTimezone"
              data-test-id="request-time-change-form-expert-timezone-hint-title">
              {{expertName}} is in {{expertTimezone}} ({{this.offset}} hrs)
            </b>

            <span class="instructions" data-test-id="request-time-change-form-expert-timezone-hint-message">
              Please provide a few times you are available to connect with <b>{{expertName}}</b>. We'll do everything we
              can to accommodate your request. Please try to bear this in mind when
              suggesting times.
            </span>
          </div>

          <mat-icon class="close" (click)="showAlert = false"
            data-test-id="request-time-change-form-expert-timezone-hint-close">
            close
          </mat-icon>
        </div>

        <h4 data-test-id="request-time-change-form-instruction-step">2. Choose date and time</h4>

        <div formGroupName="slots" class="flex flex-col gap-4">
          <mat-form-field>
            <mat-label data-test-id="request-time-change-form-date-label">Date</mat-label>
            <input matInput type="date" formControlName="date" [min]="minDate"
              data-test-id="request-time-change-form-date">
            <mat-error data-test-id="request-time-change-form-date-error">
              Date cannot be in the past
            </mat-error>
          </mat-form-field>

          <div class="flex gap-8">
            <mat-form-field class="flex-1">
              <mat-label data-test-id="request-time-change-form-start-time-label">Start time</mat-label>
              <input matInput type="time" formControlName="startTime"
                data-test-id="request-time-change-form-start-time">
            </mat-form-field>
            <mat-form-field class="flex-1">
              <mat-label data-test-id="request-time-change-form-end-time-label">End time</mat-label>
              <input matInput type="time" formControlName="endTime" data-test-id="request-time-change-form-end-time">
            </mat-form-field>
          </div>

          <button mat-flat-button color="primary" type="button" (click)="submitDates()"
            data-test-id="request-time-change-form-add-times-to-request-button">
            Add time to request
          </button>
        </div>

        <h4 data-test-id="request-time-change-form-instruction-step">3. Review your requested times</h4>

        <p *ngIf="!slots.length" [class.error]="timeForm.submitted"
          data-test-id="request-time-change-form-no-times-selected-text">
          No times selected
        </p>

        <table *ngIf="slots.length" mat-table [dataSource]="slots" class="flex grow">
          <ng-container matColumnDef="date">
            <td mat-cell *matCellDef="let element" data-test-id="request-time-change-proposed-slots-table-date">
              {{element.client.date | date}}
            </td>
          </ng-container>

          <ng-container matColumnDef="startTime">
            <td mat-cell *matCellDef="let element" data-test-id="request-time-change-proposed-slots-table-start-time">
              {{element.client.start}}
              <span class="text-dim" [matTooltip]="element.expert.label">({{element.expert.start}})</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="endTime">
            <td mat-cell *matCellDef="let element" data-test-id="request-time-change-proposed-slots-table-end-time">
              {{element.client.end}}
              <span class="text-dim" [matTooltip]="element.expert.label">({{element.expert.end}})</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="opts">
            <td mat-cell *matCellDef="let element; let idx = index">
              <button mat-icon-button type="button" (click)="remove(idx)"
                data-test-id="request-time-change-remove-proposed-slots-button">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            data-test-id="request-time-change-proposed-slots-table-row"></tr>
        </table>

        <mat-checkbox *ngIf="!commentCheckbox" formControlName="additionalComments" color="primary"
          data-test-id="request-time-change-form-enable-comments-checkbox">
          I would like to add additional comments
        </mat-checkbox>

        <mat-form-field *ngIf="commentCheckbox" appearance="outline">
          <mat-label data-test-id="request-time-change-form-additional-comments-label">Additional comments</mat-label>
          <textarea matInput placeholder="Additional comments" formControlName="notes" class="free-text-area"
            data-test-id="request-time-change-form-additional-comments"></textarea>
        </mat-form-field>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button type="button" mat-stroked-button mat-dialog-close color="primary" class="flex-1 primaryColor-border"
        data-test-id="request-time-change-form-cancel">
        Close window
      </button>
      <button class="flex-1" type="submit" mat-flat-button color="primary"
        data-test-id="request-time-change-form-send-request">
        Send request
      </button>
    </mat-dialog-actions>
  </ng-container>
</form>