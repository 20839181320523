import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, map, tap } from 'rxjs/operators';
import { ExpertScreenerActionStatusMap } from '../../../../../shared/models/expert-screener-actions.models';
import { IExpert } from '../../../../../shared/models/expert.interface';

export interface IOpportunityFilterForm {
  geographicTargets: string[];
  profileTypes: string[];
  affiliations: string[];
  expertName?: string;
  bioContent?: string;
  showNearPass: boolean;
}

@Component({
  selector: 'app-expert-filter',
  templateUrl: './expert-filter.component.html',
  styleUrls: ['./expert-filter.component.scss'],
})
export class ExpertFilterComponent implements OnInit, OnChanges {
  @Input() experts: IExpert[];
  @Input() viewStage: string;
  @Output() filterExperts = new EventEmitter<IExpert[]>();

  geographiesList: string[] = [];
  profileTypesList: string[] = [];
  affiliationsList: string[] = [];

  filterForm = new FormGroup({
    geographicTargets: new FormControl<string[]>([]),
    profileTypes: new FormControl<string[]>([]),
    affiliations: new FormControl<string[]>([]),
    expertName: new FormControl<string>(null),
    bioContent: new FormControl<string>(null),
    showNearPass: new FormControl(true),
  });

  get formHasValues(): boolean {
    return Object.entries(this.filterForm.value).some(([field, val]) => {
      if (field === 'showNearPass') {
        return !val;
      }

      return Array.isArray(val) ? val.length : !!val;
    });
  }

  ngOnInit(): void {
    this.filterForm.valueChanges
      .pipe(
        debounceTime(200),
        map((formValues) => this.filterExpertsImpl(this.experts, formValues)),
        tap((filteredExperts) => this.filterExperts.emit(filteredExperts))
      )
      .subscribe();
  }

  ngOnChanges(): void {
    this.setFormOptions(this.experts);
  }

  resetForm(): void {
    this.filterForm.reset({
      geographicTargets: [],
      profileTypes: [],
      affiliations: [],
      showNearPass: true,
    });
  }

  private setFormOptions(experts: IExpert[]): void {
    type DistinctKeysTypes =
      | 'geographicTarget'
      | 'profileType'
      | 'portalAffiliations';

    const getDistinct = (field: DistinctKeysTypes): string[] =>
      [...new Set(experts.flatMap<string>((e) => e[field] || []))]
        .map((e) => `${e}`.trim())
        .sort((a, b) => a.localeCompare(b));

    this.geographiesList = getDistinct('geographicTarget');
    this.profileTypesList = getDistinct('profileType');
    this.affiliationsList = getDistinct('portalAffiliations');

    if (!this.affiliationsList.length) {
      this.filterForm.controls['affiliations'].disable();
    }
  }

  private filterExpertsImpl(
    experts: IExpert[],
    filter: Partial<IOpportunityFilterForm>
  ): IExpert[] {
    return experts
      .filter((e) =>
        filter.geographicTargets?.length
          ? filter.geographicTargets.includes(e.geographicTarget)
          : true
      )
      .filter((e) =>
        filter.profileTypes?.length
          ? filter.profileTypes.includes(e.profileType)
          : true
      )
      .filter((e) =>
        filter.affiliations?.length
          ? (e.portalAffiliations || []).some((a) =>
              filter.affiliations.includes(a)
            )
          : true
      )
      .filter((e) =>
        filter.expertName
          ? `${e.firstName} ${e.lastName}`
              .toLowerCase()
              .trim()
              .includes(filter.expertName.toLowerCase())
          : true
      )
      .filter((e) =>
        filter.bioContent
          ? `${e.bio}${e.screenerResponse}`
              .toLowerCase()
              .trim()
              .includes(filter.bioContent.toLowerCase())
          : true
      )
      .filter((e) =>
        !filter.showNearPass
          ? !e.actions.some(
              (a) =>
                a.actionType === 'screener' &&
                a.status === ExpertScreenerActionStatusMap.CompleteNearPass
            )
          : true
      );
  }
}
