import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  INotification,
  NotificationTypeMap,
} from '../../models/notification.models';

@Component({
  selector: 'app-notification',
  styleUrls: ['./notification.component.scss'],
  template: ` <div
    class="notif flex gap-2 justify-between"
    [ngClass]="notificationTypeClass"
    data-test-id="notification-item"
    (click)="route()"
  >
    <div class="flex flex-col gap-1" style="width: 100%">
      <div class="flex justify-between center-align">
        <div *ngFor="let content of textContent">
          <div
            *ngIf="content.text"
            [innerHTML]="content.text.text | markdown"
            class="notification-message"
            data-test-id="notification-message"
          ></div>
        </div>
        <span class="time" data-test-id="notification-date-created">{{
          notification.dateCreated * 1000 | date : 'h:mma'
        }}</span>
      </div>
      <div class="flex justify-between">
        <span>{{ notification.firstName }} {{ notification.lastName }}</span>
        <span
          class="segment"
          [matTooltip]="notification.segment"
          matTooltipPosition="right"
        >
          {{ notification.segment }}
        </span>
      </div>
    </div>
  </div>`,
})
export class NotificationComponent {
  @Input() notification: INotification;
  @Output() archiveNotification = new EventEmitter<string>();
  @Output() routeAction = new EventEmitter<string>();

  get textContent() {
    return this.notification.content.filter((c) => c.contentType === 'message');
  }

  get actionContent() {
    return this.notification.content.filter(
      (c) => c.contentType === 'actionRow'
    );
  }

  get notificationTypeClass() {
    if (
      [
        NotificationTypeMap.expertCallRecording,
        NotificationTypeMap.expertCallScheduled,
      ].includes(this.notification.type)
    ) {
      return 'border-system';
    }

    if (
      [NotificationTypeMap.expertMovedToProject].includes(
        this.notification.type
      )
    ) {
      return 'border-success';
    }

    return 'border-required';
  }

  archive() {
    this.archiveNotification.emit(this.notification.notificationId);
  }

  route() {
    const actions = this.notification.content.flatMap((c) =>
      c.contentType === 'actionRow' ? c.actions : []
    );

    if (actions.length) {
      const link = actions.find(Boolean).action.link;

      this.routeAction.emit(link);
    }
  }
}
