import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'quoteTimestamp' })
export class QuoteTimestampPipe implements PipeTransform {
  transform(value: number): string {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value % 3600) / 60);
    const seconds = Math.floor(value % 60);
    const milliseconds = Math.round((value % 1) * 1000);

    const secondsAdjusted = [
      seconds.toString().padStart(2, '0'),
      milliseconds.toString().padStart(3, '0'),
    ].join('.');

    return [
      hours.toString().padStart(2, '0'),
      minutes.toString().padStart(2, '0'),
      secondsAdjusted,
    ].join(':');
  }
}
