export interface INotification {
  notificationId: string;
  groupId: string;
  type: NotificationTypeMap;
  archiveAllowed: boolean;
  archived: boolean;
  /**
   * Refers to another item depending on notification type:
   *  - expert: availabilityRequest, expertAccepted, expertRejected, expertUnrejected, expertCallCompleted, expertNoShow
   *  - messageId/emailId: expertCallActionEmailDeliveryFail
   * */
  referenceId: string;
  /** Connect User notification for */
  connectId: string;
  content: INotificationContentItem[];
  firstName: string;
  lastName: string;
  segment: string;
  dateCreated: number;
  lastUpdated: number;
  ttl?: number;
}

type INotificationContentItem =
  | INotificationContentMessage
  | INotificationContentActionRow;

export interface INotificationContentMessage {
  contentType: 'message';
  text: INotificationText;
}

export interface INotificationContentActionRow {
  contentType: 'actionRow';
  actions: INotificationActionItem[];
}

export interface INotificationActionItem {
  type: 'button';
  text: INotificationText;
  action: {
    link: string;
    type: NotificationActionTypeMap;
  };
}

export interface INotificationText {
  type: NotificationTextTypeMap;
  text: string;
}

export enum NotificationTypeMap {
  availabilitiesUpdated = 'availabilitiesUpdated',
  availabilityExpiring = 'availabilityExpiring',
  expertCallScheduled = 'expertCallScheduled',
  expertMovedToProject = 'expertMovedToProject',
  expertCallRecording = 'expertCallRecording',
}

export enum NotificationTextTypeMap {
  plainText = 'plain_text',
  markDown = 'mrkdwn',
}

export enum NotificationActionTypeMap {
  url = 'url',
  routerLinkConnect = 'routerLinkConnect',
}
