import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CognitoAuthService } from '@techspert-io/auth';
import {
  IOpportunity,
  IOpportunitySegmentBaseStats,
  IOpportunitySegmentStats,
} from '@techspert-io/opportunities';
import * as Excel from 'exceljs';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { tap } from 'rxjs/operators';
import { FileStoreService } from '../../../shared/services/filestore.service';
import { ExpertStoreService } from '../../../shared/state/expert.service';
import { WORKBOOK } from '../directives/expert-export.directive';
import { AuthDialogInceptorService } from '../services/auth-dialog-inceptor.service';
import { WorksheetService } from '../services/worksheet.service';

interface IDataSource extends IOpportunitySegmentStats {
  transcriptionSummaryKey?: string;
}

@Component({
  selector: 'app-geographic-progress',
  templateUrl: './geographic-progress.component.html',
  styleUrls: ['./geographic-progress.component.scss'],
  providers: [
    {
      provide: WORKBOOK,
      useValue: new Excel.Workbook(),
    },
  ],
})
export class GeographicProgressComponent implements OnInit, AfterViewInit {
  @Input() opportunity: IOpportunity;
  @Input() isAuthorised: boolean;
  @Output() adjustTabIndex = new EventEmitter();
  @Output() openOmnisearch = new EventEmitter();

  public displayedColumns = [];
  public dataSource: MatTableDataSource<IDataSource>;
  public emptyExperts: boolean = false;

  get showOmnisearchBanner(): boolean {
    return (
      this.cognitoAuthService.loggedInUser &&
      this.cognitoAuthService.loggedInUser.clients[this.opportunity.clientId] &&
      this.cognitoAuthService.loggedInUser.clients[this.opportunity.clientId]
        .features.omnisearchEnabled
    );
  }

  constructor(
    public expertStore: ExpertStoreService,
    private cognitoAuthService: CognitoAuthService,
    private worksheetService: WorksheetService,
    private authDialogInceptorService: AuthDialogInceptorService,
    private fileStore: FileStoreService,
    private gaService: GoogleAnalyticsService,
    @Inject(WORKBOOK) private workbook: Excel.Workbook
  ) {}

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    if (this.opportunity.opportunitySegmentStats?.length) {
      this.displayedColumns = [
        ...(this.opportunity.createSegmentTranscriptSummary ? ['summary'] : []),
        'geography',
        'segment',
        'screening',
        'inPortal',
        'scheduled',
        'completed',
        'confirmed',
        'target',
      ];
      this.dataSource = new MatTableDataSource(
        this.opportunity.opportunitySegmentStats
          .map((s) => ({
            ...s,
            transcriptionSummaryKey: this.opportunity.opportunitySegments.find(
              (oS) => oS.opportunitySegmentId === s.opportunitySegmentId
            )?.transcriptionSummaryKey,
          }))
          .sort(
            (a, b) =>
              a.geography
                .toLowerCase()
                .localeCompare(b.geography.toLowerCase()) ||
              a.segment.toLowerCase().localeCompare(b.segment.toLowerCase())
          )
      );
      this.emptyExperts = this.isEmpty(
        this.opportunity.opportunitySegmentStatsTotal
      );
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  getTotal(phase: string): number {
    return this.dataSource
      ? this.dataSource.data.reduce((curr, acc) => (curr += acc[phase]), 0)
      : 0;
  }

  adjustTab(val: number): void {
    this.adjustTabIndex.emit(val);
  }

  downloadSummary(segment: IDataSource): void {
    if (!segment.transcriptionSummaryKey) {
      return;
    }

    const fileName = `[ECHO] - Summary_${segment.geography}_${segment.segment}.txt`;

    this.authDialogInceptorService.authIntercept(this.isAuthorised, () =>
      this.fileStore
        .downloadExpertFile(segment.transcriptionSummaryKey, fileName)
        .pipe(
          tap(() =>
            this.gaService.gtag('event', 'download', {
              event_category: 'segment_transcript_summary',
              event_label: `${segment.geography}_${segment.segment}`,
              value: segment.opportunitySegmentId,
              dimension1: this.cognitoAuthService.loggedInUser.id,
            })
          )
        )
        .subscribe()
    );
  }

  async downloadProgressCsv(): Promise<void> {
    const formattedOpportunityName = this.opportunity.opportunityName
      .replace(/[?:\\\/[\]*]/g, '')
      .trim()
      .slice(0, 31);

    const worksheetExists = this.workbook.getWorksheet(
      formattedOpportunityName
    );

    if (worksheetExists) {
      this.workbook.removeWorksheet(formattedOpportunityName);
    }

    this.formatWorkbook(
      this.workbook.addWorksheet(formattedOpportunityName, {
        properties: {
          defaultColWidth: 23,
          defaultRowHeight: 22,
        },
      })
    );

    await this.worksheetService.save(this.workbook, 'export');
  }

  private isEmpty(total: IOpportunitySegmentBaseStats): boolean {
    return (
      !total ||
      (total.completed === 0 &&
        total.confirmed === 0 &&
        total.scheduled === 0 &&
        total.inPortal === 0 &&
        total.screening === 0)
    );
  }

  private formatWorkbook(worksheet: Excel.Worksheet): void {
    worksheet.columns = [
      { header: 'Geography', key: 'geography' },
      { header: 'Profile Type', key: 'profile_type' },
      { header: 'Screening', key: 'screening' },
      { header: 'New Profiles', key: 'in_portal' },
      {
        header: 'Upcoming Connections',
        key: 'connections_scheduled',
      },
      {
        header: 'Completed Connections',
        key: 'connections_completed',
      },
      {
        header: 'Confirmed Connections',
        key: 'confirmed_connections',
      },
      {
        header: 'Target',
        key: 'target',
      },
    ];

    // Add row using key mapping to columns
    for (const row of this.dataSource.data) {
      worksheet.addRow({
        geography: row.geography,
        profile_type: row.segment,
        screening: row.screening,
        in_portal: row.inPortal,
        connections_scheduled: row.scheduled,
        connections_completed: row.completed,
        confirmed_connections: row.confirmed,
        target: row.target,
      });
    }

    // Final row
    worksheet.addRow({
      geography: 'PROJECT TOTALS',
      profile_type: '',
      screening: this.dataSource.data.reduce(
        (total, b) => (total += b.screening),
        0
      ),
      in_portal: this.dataSource.data.reduce(
        (total, b) => (total += b.inPortal),
        0
      ),
      connections_scheduled: this.dataSource.data.reduce(
        (total, b) => (total += b.scheduled),
        0
      ),
      connections_completed: this.dataSource.data.reduce(
        (total, b) => (total += b.completed),
        0
      ),
      confirmed_connections: this.dataSource.data.reduce(
        (total, b) => (total += b.confirmed),
        0
      ),
      target: this.dataSource.data.reduce((total, b) => (total += b.target), 0),
    });

    // Styling entire table
    worksheet.eachRow({ includeEmpty: true }, (row, rowNumber: number) => {
      // colour background of every other row
      if (rowNumber % 2 === 0) {
        row.eachCell((cell, columnNumber: number) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb:
                columnNumber < worksheet.columns.length - 1
                  ? 'F5F5F5'
                  : 'e1e1e1',
            },
          };
          cell.font = {
            name: 'Arial',
            family: 2,
            bold: !(columnNumber < worksheet.columns.length - 1),
            size: 10,
          };
          cell.alignment = { vertical: 'middle' };
          cell.border = {
            left: { style: 'thin', color: { argb: 'd8cccc' } },
            top: { style: 'thin', color: { argb: 'd8cccc' } },
            right: { style: 'thin', color: { argb: 'd8cccc' } },
            bottom: { style: 'thin', color: { argb: 'd8cccc' } },
          };
        });
      } else {
        row.eachCell((cell, columnNumber: number) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb:
                columnNumber < worksheet.columns.length - 1 || rowNumber === 1
                  ? 'ffffff'
                  : 'EBEBEB',
            },
          };
          cell.font = {
            name: 'Arial',
            family: 2,
            bold: !(columnNumber < worksheet.columns.length - 1),
            size: 10,
          };
          cell.alignment = { vertical: 'middle' };
          cell.border = {
            left: { style: 'thin', color: { argb: 'd8cccc' } },
            top: { style: 'thin', color: { argb: 'd8cccc' } },
            right: { style: 'thin', color: { argb: 'd8cccc' } },
            bottom: { style: 'thin', color: { argb: 'd8cccc' } },
          };
        });
      }

      // initial row
      if (rowNumber === 1) {
        row.eachCell((cell) => {
          cell.font = {
            name: 'Arial',
            family: 2,
            bold: true,
            size: 10,
          };
          cell.border = {
            bottom: { style: 'double', color: { argb: '000000' } },
          };
        });
      }

      // final row
      if (rowNumber === worksheet.rowCount) {
        row.eachCell((cell) => {
          cell.font = {
            name: 'Arial',
            family: 2,
            bold: true,
            size: 10,
            color: { argb: 'FFFFFF' },
          };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '565252' },
          };
        });
      }
    });
  }
}
