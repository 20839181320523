import {
  IOmnisearchExpert,
  IOmnisearchOpportunity,
  IRedisExpertFields,
  OmnisearchEntity,
} from '../models/omnisearch.models';

export const omnisearchOpportunityData: IOmnisearchOpportunity = {
  entity: OmnisearchEntity.opportunities,
  id: 'opp-id',
  name: 'Test Opportunity',
  clientId: 'client-id',
};

export const omnisearchExpertData: IOmnisearchExpert = {
  entity: OmnisearchEntity.portalExperts,
  id: 'expert-id',
  name: 'Test Expert',
  expertise: [],
  oppId: 'opp-id',
  oppName: 'opp-name',
  clientId: 'client-id',
  profileType: 'profile',
  geographicTarget: 'GB',
  portalAffiliations: [],
  portalPhase: 'completedConnections',
  bio: 'bio',
  favourited: false,
  files: [],
};

export const redisExpertFieldsMock: IRedisExpertFields = {
  expertise: false,
  name: false,
  bio: false,
  geographicTarget: false,
  portalAffiliations: false,
  profileType: false,
};
