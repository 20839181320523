import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, combineLatest, map, scan, startWith, tap } from 'rxjs';
import { ISavedQuote, ISavedQuoteBase } from '../models/savedQuotes.models';

@Injectable()
export class AssistantQuotesService {
  constructor(protected http: HttpClient) {}

  private quotes$ = new Subject<ISavedQuote[]>();
  private quotesInner$ = this.quotes$.pipe(
    scan<ISavedQuote[], ISavedQuote[]>((prev, curr) => [...prev, ...curr], []),
    startWith([])
  );

  private quotesUpdates$ = new Subject<ISavedQuote>();
  private updatedQuotes$ = this.quotesUpdates$.pipe(
    scan<ISavedQuote, Record<string, ISavedQuote>>(
      (prev, curr) => ({ ...prev, [curr.savedQuoteId]: curr }),
      {}
    ),
    map((data) => Object.values(data)),
    startWith([])
  );

  private deletedQuote$ = new Subject<string>();
  private deletedQuoteIds$ = this.deletedQuote$.pipe(
    scan<string, string[]>((prev, curr) => [...prev, curr], []),
    startWith([])
  );

  allQuotes$ = combineLatest([
    this.quotesInner$,
    this.updatedQuotes$,
    this.deletedQuoteIds$,
  ]).pipe(
    map(([quotes, update, deletedQuoteIds]) =>
      this.joinQuotes(quotes, deletedQuoteIds, update)
    ),
    startWith<Record<string, ISavedQuote[]>>({})
  );

  saveQuote(quote: ISavedQuoteBase) {
    return this.http
      .post<ISavedQuote>('/client-portal/assistant/quotes', quote)
      .pipe(tap((quote) => this.quotesUpdates$.next(quote)));
  }

  deleteQuote(quoteId: string) {
    return this.http
      .delete<void>(`/client-portal/assistant/quotes/${quoteId}`)
      .pipe(
        tap(() => {
          this.deletedQuote$.next(quoteId);
        })
      );
  }

  getQuotesByExpertId(expertId: string) {
    return this.http
      .get<ISavedQuote[]>(
        `/client-portal/assistant/quotes?expertId=${expertId}`
      )
      .pipe(tap((quotes) => this.quotes$.next(quotes)));
  }

  getQuotesByTranscriptFileId(transcriptFileId: string) {
    return this.http
      .get<ISavedQuote[]>(
        `/client-portal/assistant/quotes?transcriptFileId=${transcriptFileId}`
      )
      .pipe(tap((quotes) => this.quotes$.next(quotes)));
  }

  private joinQuotes(
    quotes: ISavedQuote[],
    deletedQuoteIds: string[],
    update: ISavedQuote[]
  ) {
    const quoteMap = quotes.reduce<Record<string, ISavedQuote>>(
      (prev, curr) => ({ ...prev, [curr.savedQuoteId]: curr }),
      {}
    );

    return [
      ...Object.values(quoteMap).filter((q) =>
        update.every((u) => u.savedQuoteId !== q.savedQuoteId)
      ),
      ...update,
    ]
      .filter((q) => !deletedQuoteIds.includes(q.savedQuoteId))
      .reduce<Record<string, ISavedQuote[]>>(
        (prev, curr) => ({
          ...prev,
          [curr.expertId]: [...(prev[curr.expertId] || []), curr],
        }),
        {}
      );
  }
}
