import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IExpert } from '../../../shared/models/expert.interface';
import {
  IConfirmAvailability,
  IExpertApproval,
  IExpertOnHold,
  IExpertReadyToAcceptReturn,
  IExpertRejection,
  IExpertSchedule,
  IFavouriteExpert,
  IRequestDifferentTime,
  IUpdateClientNotes,
} from '../models/experts.models';

@Injectable()
export class ExpertsService {
  private readonly baseUrl = '/client-portal/experts';

  constructor(private http: HttpClient) {}

  confirmAvailability(
    expertId: string,
    body: IConfirmAvailability['payload']
  ): Observable<IExpert> {
    return this.http.patch<IExpert>(
      `${this.baseUrl}/${expertId}/confirm-availability`,
      body
    );
  }

  requestAnotherTime(
    expertId: string,
    body: IRequestDifferentTime['payload']
  ): Observable<IExpert> {
    return this.http.patch<IExpert>(
      `${this.baseUrl}/${expertId}/request-time`,
      body
    );
  }

  seenByClient(expertId: string): Observable<IExpert> {
    return this.http.patch<IExpert>(
      `${this.baseUrl}/${expertId}/seen-by-client`,
      expertId
    );
  }

  updateClientNotes(
    expertId: string,
    body: IUpdateClientNotes['payload']
  ): Observable<IExpert> {
    return this.http.patch<IExpert>(`${this.baseUrl}/${expertId}/notes`, body);
  }

  updateExpertOnHold(
    expertId: string,
    body: IExpertOnHold['payload']
  ): Observable<IExpert> {
    return this.http.patch<IExpert>(
      `${this.baseUrl}/${expertId}/on-hold`,
      body
    );
  }

  updateExpertRejection(
    expertId: string,
    body: IExpertRejection['payload']
  ): Observable<IExpert> {
    return this.http.patch<IExpert>(`${this.baseUrl}/${expertId}/reject`, body);
  }

  updateExpertFavourite(
    expertId: string,
    body: IFavouriteExpert['payload']
  ): Observable<IExpert> {
    return this.http.patch<IExpert>(
      `${this.baseUrl}/${expertId}/favourite`,
      body
    );
  }

  updateExpertApproval(
    expertId: string,
    body: IExpertApproval['payload']
  ): Observable<IExpert> {
    return this.http.patch<IExpert>(
      `${this.baseUrl}/${expertId}/approval`,
      body
    );
  }

  scheduleExpert(
    expertId: string,
    body: IExpertSchedule['payload']
  ): Observable<IExpert> {
    return this.http.post<IExpert>(
      `${this.baseUrl}/${expertId}/schedule-expert`,
      body
    );
  }

  requestExpertBio(expertId: string): Observable<IExpert> {
    return this.http.patch<IExpert>(
      `${this.baseUrl}/${expertId}/request-bio`,
      {}
    );
  }

  getExpertById(expertId: string): Observable<IExpert> {
    return this.http.get<IExpert>(`/experts/${expertId}`);
  }

  getExpertsByOpportunityId(opportunityId: string): Observable<IExpert[]> {
    return this.http.get<IExpert[]>(
      `${this.baseUrl}/opportunity/${opportunityId}`
    );
  }

  isExpertReadyToAccept(
    expertId: string
  ): Observable<IExpertReadyToAcceptReturn> {
    return this.http.get<IExpertReadyToAcceptReturn>(
      `${this.baseUrl}/isReadyToAccept/${expertId}`
    );
  }
}
