import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-thread-deletion-confirmation',
  template: `<div class="flex justify-between content-center">
      <h3 mat-dialog-title>Chat deletion</h3>
      <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <mat-dialog-content class="flex flex-col gap-2">
      <p>
        Are you sure you wish to delete this chat <b>{{ threadName }}</b
        >? This action cannot be undone.
      </p>
    </mat-dialog-content>

    <mat-dialog-actions class="flex gap-2">
      <button
        type="button"
        mat-stroked-button
        mat-dialog-close
        color="primary"
        class="flex-1 primaryColor-border"
      >
        Close window
      </button>
      <button
        class="flex-1"
        type="submit"
        mat-flat-button
        color="primary"
        (click)="confirmDeletion()"
      >
        Confirm
      </button>
    </mat-dialog-actions>`,
})
export class ThreadDeletionConfirmationComponent {
  constructor(
    private dialogRef: MatDialogRef<ThreadDeletionConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public threadName: string
  ) {}

  confirmDeletion() {
    this.dialogRef.close(true);
  }
}
