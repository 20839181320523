export type SurveyDataType =
  | 'array'
  | 'integer'
  | 'number'
  | 'object'
  | 'string';

export enum ExpertScreenerActionStatusMap {
  Voided = 'voided',
  Pending = 'pending',
  Cancelled = 'cancelled',
  Complete = 'complete',
  CompletePassed = 'complete-passed',
  CompleteFailed = 'complete-failed',
  CompleteNearPass = 'complete-near-pass',
  CompleteNearPassAccepted = 'complete-near-pass-accepted',
}

export interface IDataAccessLogs {
  dateCreated: number;
  lastUpdated: number;
  createdBy?: string;
  lastUpdatedBy?: string;
}

export interface IExpertActionBase {
  expertActionId: string;
  clientId: string;
  opportunityId: string;
  expertId: string;
  ownerUserId: string;
  actionType: 'call' | 'screener' | 'respondent';
  status: string;
}

export interface IExpertScreenerAction
  extends IExpertScreenerActionBase,
    IDataAccessLogs {}

export interface IExpertScreenerActionBase extends IExpertActionBase {
  actionType: 'screener';
  contactLookupId?: string;
  contactId?: string;
  distributionId?: string;
  dateSent?: number;
  dateCompleted?: number;
  surveyId?: string;
  /** @deprecated - no longer sending reminders via Qualtrics */
  reminderIds?: string[];
  distributionType?: string;
  distributionLink?: string;
  distributionProvider?: 'External' | 'Qualtrics';
  screenerResult?: IScreenerResult;
  surveyResult?: SurveyResultStatus;
  status: ExpertScreenerActionStatusMap;
  notes?: string;
}

export interface IExpertScreenerActionQuery {
  from?: string;
  to?: string;
  ownerUserId: string[];
  status: IExpertScreenerAction['status'][];
}

/** @deprecated - verdict system removed */
export enum SurveyVerdictMap {
  pass = 'pass',
  fail = 'fail',
  query = 'query',
  informational = 'informational',
}

export interface IScreenerResult {
  /** @deprecated - verdict system removed */
  verdict?: SurveyVerdictMap;
  /** @deprecated - verdict system removed */
  stats?: Record<SurveyVerdictMap, number>;
  responses: {
    id: string;
    question: string;
    questionType: SurveyDataType;
    answer: string;
    /** @deprecated - verdict system removed */
    verdict?: SurveyVerdictMap;
  }[];
  dateCompleted: number;
}

export type SurveyResultStatus = 'completed' | 'over-quota' | 'terminated';
