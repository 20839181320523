import { Component } from '@angular/core';

@Component({
  selector: 'app-txp-logo',
  template: `<img style="width: 156px; height: 54px" [src]="logoSource" />`,
  styles: [
    `
      :host {
        display: flex;
      }
    `,
  ],
})
export class TxpLogoComponent {
  get logoSource(): string {
    const showSanta = new Date().getMonth() === 11;
    const logoSrc = showSanta ? 'Techspert-logo-christmas' : 'Techspert-logo';

    return `../../assets/images/${logoSrc}.svg`;
  }
}
