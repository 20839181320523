import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CognitoAuthService } from '@techspert-io/auth';
import { IOpportunity } from '@techspert-io/opportunities';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { switchMap, takeWhile, tap } from 'rxjs/operators';
import { RequestExpertEngagementComponent } from '../../public/opportunity/expert-list/expert/dialogs/request-expert-engagement/request-expert-engagement.component';
import { OpportunityStoreService } from '../state/opportunity.service';
import { NotificationService } from './notification.service';

export type BookingSource =
  | 'opportunity_page'
  | 'omnisearch_results'
  | 'call_complete_page';
export type BookingType = 'booking';

@Injectable({ providedIn: 'root' })
export class RequestEngagementService {
  private dialogOpen = false;

  constructor(
    private notificationService: NotificationService,
    private opportunityService: OpportunityStoreService,
    private dialog: MatDialog,
    private gaService: GoogleAnalyticsService,
    private cognitoAuthService: CognitoAuthService,
    private toastr: ToastrService
  ) {}

  request(
    expertId: string,
    bookingSource: BookingSource,
    bookingType: BookingType = 'booking'
  ): Observable<void> {
    type EngagementRes = {
      project?: IOpportunity;
      segmentId: string;
      notes: string;
    };

    if (this.dialogOpen) {
      return of();
    }

    this.dialogOpen = true;

    return this.opportunityService.getActiveOpportunities().pipe(
      switchMap((opps) =>
        this.dialog
          .open<
            RequestExpertEngagementComponent,
            { opps: IOpportunity[]; bookingType: BookingType },
            EngagementRes
          >(RequestExpertEngagementComponent, {
            width: '480px',
            data: { opps, bookingType },
            autoFocus: false,
          })
          .afterClosed()
          .pipe(
            tap(() => {
              this.dialogOpen = false;
            }),
            takeWhile((text) => !!text),
            switchMap(({ notes, project, segmentId }) =>
              this.notificationService
                .sendEngagementRequestNotification({
                  loggedInUserId: this.cognitoAuthService.loggedInUser?.id,
                  segmentId,
                  ...(project
                    ? { targetOpportunityId: project.opportunityId }
                    : {}),
                  expertId,
                  notes,
                  bookingType,
                  bookingSource,
                })
                .pipe(
                  tap(() =>
                    this.gaService.gtag('event', 'click', {
                      event_category: {
                        booking: 'engagement_request',
                        additional_info: 'additional_info_request',
                      }[bookingType],
                      dimension1: this.cognitoAuthService.loggedInUser?.id,
                      sourceExpertId: expertId,
                      destOpportunityId:
                        project?.opportunityId || 'new_opportunity',
                      actionLocation: bookingSource,
                    })
                  ),
                  tap(() =>
                    this.toastr.success(
                      'Thank you - your request has been shared with the Techspert team',
                      'Engagement request'
                    )
                  )
                )
            )
          )
      )
    );
  }
}
