import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bioEllipsis',
})
export class BioEllipisPipe implements PipeTransform {
  transform(bio: string): string {
    return bio.length > 400 ? `${bio.slice(0, 400)}...` : bio;
  }
}
