import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CognitoAuthService } from '../services/cognito-auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private cognitoAuthService: CognitoAuthService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> {
    return combineLatest([
      this.cognitoAuthService.loggedInAuth0$,
      this.cognitoAuthService.loggedInConnect$,
    ]).pipe(
      map(([auth0, connect]) => {
        if (!connect && !auth0) {
          return true;
        }
        if (auth0 && !connect) {
          this.router.navigate(['not-authorised']);
          return false;
        }
        return auth0 && !!connect;
      })
    );
  }
}
