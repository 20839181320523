import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { OmnisearchService } from '../../services/omnisearch.service';

@Component({
  selector: 'app-omnisearch',
  templateUrl: './omnisearch.component.html',
  styleUrls: ['./omnisearch.component.scss'],
})
export class OmnisearchComponent implements OnInit {
  @ViewChild('search') search: ElementRef;

  loadingQuery$ = this.omnisearchService.loading$;
  searchForm = this.omnisearchService.searchForm;

  constructor(private omnisearchService: OmnisearchService) {}

  ngOnInit(): void {
    this.omnisearchService.searchForm.controls['search'].valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap((query) => this.omnisearchService.setQuery(query))
      )
      .subscribe();
  }

  setFocus(focus: boolean): void {
    if (!focus) {
      this.search.nativeElement.blur();
    }
    this.omnisearchService.setFocused(focus);
  }

  emptySearch(): void {
    this.omnisearchService.searchForm.setValue({
      search: '',
    });
  }
}
