import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ISharedExpertEmail } from '../models/sharedExpertEmails.models';

interface IShareExpert {
  recipients: string[];
  notes: string;
}

@Injectable()
export class SharedExpertEmailsService {
  private readonly baseUrl = '/client-portal/experts/share';

  constructor(private http: HttpClient) {}

  share(expertId: string, body: IShareExpert): Observable<ISharedExpertEmail> {
    return this.http.post<ISharedExpertEmail>(
      `${this.baseUrl}?expertId=${expertId}`,
      body
    );
  }
}
