<div class="flex justify-between content-center">
  <h3 mat-dialog-title class="primary-color" data-test-id="expert-no-show-dialog-title">
    Report Expert No Show
  </h3>
  <button mat-icon-button mat-dialog-close data-test-id="expert-no-show-dialog-close-button">
    <mat-icon>clear</mat-icon>
  </button>
</div>

<ng-container *ngIf="stage === 'confirmation'">
  <mat-dialog-content>
    <p class="instructions" data-test-id="expert-no-show-dialog-instruction">
      Expert not yet joined the call? Click 'reply all' in your calendar invite to inform us and a member of the
      techspert
      team will be in touch immediately (during the hours {{officeHours.start | availabilityTime}} - {{officeHours.end
      |
      availabilityTime}} {{timezoneAbbr}}). They will:
    </p>

    <ol class="instructions">
      <li data-test-id="expert-no-show-dialog-project-manager-action">
        Confirm receipt of your alert that the expert isn't yet on the call
      </li>
      <li data-test-id="expert-no-show-dialog-project-manager-action">Call the expert directly</li>
      <li data-test-id="expert-no-show-dialog-project-manager-action">Follow up with an email to the expert</li>
      <li data-test-id="expert-no-show-dialog-project-manager-action">Update you via email: either the expert will be
        joining momentarily, or we may need to reschedule your call</li>
    </ol>
  </mat-dialog-content>

  <mat-dialog-actions class="flex gap-2 justify-start content-center">
    <button mat-stroked-button color="primary" mat-dialog-close class="flex-1"
      data-test-id="expert-no-show-dialog-cancel-button">Close</button>
    <button mat-flat-button color="primary" (click)="sendRequest()" class="flex-1"
      data-test-id="expert-no-show-dialog-confirm-button">Confirm</button>
  </mat-dialog-actions>
</ng-container>

<mat-dialog-content *ngIf="stage === 'loading'">
  <app-loader></app-loader>
</mat-dialog-content>

<ng-container *ngIf="stage === 'completed'">
  <mat-dialog-content class="flex flex-col">
    <p class="instructions" data-test-id="expert-no-show-dialog-result-text">
      Thank you for informing us. We will respond to you as soon as possible.
    </p>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-flat-button color="primary" mat-dialog-close class="flex-1"
      data-test-id="expert-no-show-dialog-understood-button">Understood</button>
  </mat-dialog-actions>
</ng-container>

<ng-container *ngIf="stage === 'error'">
  <mat-dialog-content class="flex flex-col">
    <p class="instructions" data-test-id="expert-no-show-dialog-result-text">
      Error processing request, please contact support at <a
        href="mailto:callsupport@techspert.com">callsupport&#64;techspert.com</a>.
    </p>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-flat-button color="primary" mat-dialog-close class="flex-1"
      data-test-id="expert-no-show-dialog-understood-button">Understood</button>
  </mat-dialog-actions>
</ng-container>