import { Component, EventEmitter, Input, Output } from '@angular/core';

interface IBanner {
  id: string;
  text: string;
  cta: string;
  link: string;
  expires: string;
}

@Component({
  selector: 'app-cta-banner',
  template: `
    <div class="banner">
      <span>
        {{ banner.text }} <a (click)="openLink()">{{ banner.cta }}</a>
      </span>

      <mat-icon (click)="close()">clear</mat-icon>
    </div>
  `,
  styleUrls: ['./cta-banner.component.scss'],
})
export class CtaBannerComponent {
  @Input() banner: IBanner;
  @Output() closeBanner = new EventEmitter<void>();

  openLink() {
    window.open(this.banner.link, '_blank');
    this.closeBanner.emit();
  }

  close() {
    this.closeBanner.emit();
  }
}
