import { Pipe, PipeTransform } from '@angular/core';
import { IExpertFile } from '../models/expert-file.model';

@Pipe({ name: 'fileDisplayName' })
export class FileDisplayNamePipe implements PipeTransform {
  transform(file: IExpertFile, truncateAfter?: number): string {
    if (truncateAfter && file.fileName.length > truncateAfter) {
      const trancatedName = file.fileName.substr(0, truncateAfter);
      return `${trancatedName}..${this.getExtension(file.fileExtension)}`;
    }
    return `${file.fileName}${this.getExtension(file.fileExtension)}`;
  }

  private getExtension(ext: string): string {
    return ext ? `.${ext}` : '';
  }
}
