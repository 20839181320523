import { Injectable } from '@angular/core';
import * as Excel from 'exceljs';

@Injectable({ providedIn: 'root' })
export class WorksheetService {
  async save(workbook: Excel.Workbook, fileName: string): Promise<void> {
    try {
      const xls64 = await workbook.xlsx.writeBuffer();

      const data = new Blob([xls64], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const a = document.createElement('a');

      const url = URL.createObjectURL(data);
      a.href = url;
      a.download = `${fileName}.xlsx`;
      document.body.appendChild(a);

      a.click();

      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error(err);
    }
  }
}
