import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService as Auth0Service, IdToken } from '@auth0/auth0-angular';
import { Observable, ReplaySubject, of } from 'rxjs';
import { exhaustMap, first, map, tap } from 'rxjs/operators';
import {
  ClientPortalUserClient,
  IClientFeature,
  IConnectUser,
  IUser,
  UserType,
} from '../models/user';
import { AUTH0_DOMAIN, LOCAL_STORAGE } from './token';

@Injectable()
export class CognitoAuthService {
  private redirectKeyName = 'redirect_url';

  loggedInUser: IUser;
  loggedInAuth0$ = new ReplaySubject<boolean>(1);
  loggedInConnect$ = new ReplaySubject<IUser | undefined>(1);
  authError$ = this.auth0Service.error$;

  constructor(
    private http: HttpClient,
    private auth0Service: Auth0Service,
    private router: Router,
    @Inject(LOCAL_STORAGE) private storage: Storage,
    @Inject(AUTH0_DOMAIN) private auth0Domain: string
  ) {}

  initAuth(): Observable<IUser> {
    return this.auth0Service.idTokenClaims$.pipe(
      first(),
      map((claims) => this.getConnectId(claims)),
      tap((connectId) => this.loggedInAuth0$.next(!!connectId)),
      exhaustMap((connectId) => this.getConnectInfoForCreds(connectId)),
      tap((user) => (this.loggedInUser = user)),
      tap((user) => this.loggedInConnect$.next(user))
    );
  }

  logOut(): Observable<void> {
    this.loggedInUser = null;
    this.loggedInConnect$.next(undefined);
    this.loggedInAuth0$.next(false);
    this.setRedirectUrl(this.router.url);
    return this.auth0Service.logout();
  }

  getAccessToken(): Observable<string> {
    return this.auth0Service.getAccessTokenSilently();
  }

  getRedirectUrl(): string {
    return this.storage.getItem(this.redirectKeyName);
  }

  setRedirectUrl(url: string | null): void {
    if (url) {
      this.storage.setItem(this.redirectKeyName, url);
    } else {
      this.storage.removeItem(this.redirectKeyName);
    }
  }

  isClientFeatureEnabled(
    feature: keyof Omit<IClientFeature, 'omnisearchEnabled'>,
    clientId: string,
    userTypes: UserType[] = ['PM', 'Client']
  ): Observable<boolean> {
    return this.loggedInConnect$.pipe(
      map(
        (user) =>
          !!user &&
          !!user.clients &&
          user.clients[clientId] &&
          user.clients[clientId].features[feature] &&
          userTypes.includes(user.userType)
      )
    );
  }

  private getConnectInfoForCreds(connectId?: string): Observable<IUser | null> {
    if (!connectId) {
      return of(null);
    }

    interface IClientRes extends IConnectUser {
      clients: Record<string, ClientPortalUserClient>;
    }

    return this.http.get<IClientRes>(`/client-portal/users`).pipe(
      map((connectUser) => ({
        ...connectUser,
        id: connectUser.connectId,
      }))
    );
  }

  private getConnectId(idToken: IdToken): string {
    return (idToken || {})[`https://${this.auth0Domain}/connectId`];
  }
}
