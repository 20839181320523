import {
  Component,
  Inject,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { CognitoAuthService, IUser } from '@techspert-io/auth';
import * as moment from 'moment-timezone';

export interface IRequestConferenceDialogData {
  contacts: IUser[];
  callTime: string;
  expertTimezone: string;
}

export interface IRequestConferenceDialogResult {
  primaryRecipient: string;
  recipients: string[];
  clientTimezone: string;
}

@Component({
  selector: 'app-request-conference-dialog',
  templateUrl: './request-conference-dialog.component.html',
  styleUrls: ['./request-conference-dialog.component.scss'],
})
export class RequestConferenceDialogComponent implements OnInit {
  get expertCallTime(): string {
    return moment(this.data.callTime)
      .tz(this.data.expertTimezone)
      .format('ddd MMM DD YYYY, LT');
  }

  get clientCallTime(): string {
    return moment(this.data.callTime)
      .tz(
        this.clientContacts.find(
          (d) => d.email === this.conferenceForm.value.primaryRecipient
        )?.timezone.name ||
          this.currentUser.timezone.name ||
          'UTC'
      )
      .format('ddd MMM DD YYYY, LT');
  }

  get currentUser(): IUser {
    return this.authService.loggedInUser;
  }

  get recipientsCtrls(): FormArray<FormControl<string>> {
    return this.conferenceForm.controls.recipients;
  }

  get clientContacts(): IUser[] {
    return this.data.contacts;
  }

  @ViewChildren(MatInput) recipientInputs: QueryList<MatInput>;

  conferenceForm = new FormGroup({
    primaryRecipient: new FormControl<string>(null, [
      Validators.required,
      Validators.email,
    ]),
    recipients: new FormArray<FormControl<string>>([]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: IRequestConferenceDialogData,
    private dialogRef: MatDialogRef<
      RequestConferenceDialogComponent,
      IRequestConferenceDialogResult
    >,
    private authService: CognitoAuthService
  ) {}

  ngOnInit(): void {
    this.setupClientContacts(this.clientContacts);
  }

  addRecipient(email: string = null, focus = false): void {
    this.recipientsCtrls.push(
      new FormControl(email, [Validators.required, Validators.email])
    );

    if (focus) {
      setTimeout(() => this.recipientInputs.last.focus(), 0);
    }
  }

  removeRecipient(idx: number): void {
    this.recipientsCtrls.removeAt(idx);
  }

  recipientKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.addRecipient();
      setTimeout(() => this.recipientInputs.last.focus(), 0);
    }
  }

  confirm(): void {
    if (this.conferenceForm.valid) {
      const primaryRecipient = this.conferenceForm.value.primaryRecipient;
      const recipients = [
        ...new Set(
          this.conferenceForm.value.recipients.map((d) =>
            d.trim().toLowerCase()
          )
        ),
      ]
        .filter(Boolean)
        .filter((d) => d !== primaryRecipient);
      this.dialogRef.close({
        primaryRecipient,
        recipients,
        clientTimezone:
          this.clientContacts.find((d) => d.email === primaryRecipient)
            ?.timezone.name || this.currentUser.timezone.name,
      });
    }
  }

  private setupClientContacts(contacts: IUser[]): void {
    const contactEmails = contacts.map((c) => c.email);
    const primaryContact = contactEmails.find(
      (e) => e === this.currentUser.email
    );

    this.conferenceForm.setValue({
      primaryRecipient: primaryContact || null,
      recipients: [],
    });
    contactEmails
      .sort((a, b) => a.localeCompare(b))
      .filter((email) => email !== primaryContact)
      .forEach((email) => this.addRecipient(email));
  }
}
