import { Component } from '@angular/core';
import { CognitoAuthService } from '@techspert-io/auth';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent {
  auth$ = this.cognitoAuthService.loggedInConnect$.pipe(map(() => 'done'));

  constructor(private cognitoAuthService: CognitoAuthService) {}
}
