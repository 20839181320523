import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-acceptance-withdrawal-blocked-dialog',
  templateUrl: './acceptance-withdrawal-blocked-dialog.component.html',
  styleUrls: ['./acceptance-withdrawal-blocked-dialog.component.scss'],
})
export class AcceptanceWithdrawalBlockedDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AcceptanceWithdrawalBlockedDialogComponent>,
    public dialog: MatDialog
  ) {}
}
