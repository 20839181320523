export interface IAnnotation {
  type: string;
  text: string;
  start_index: number;
  end_index: number;
  file_citation: {
    file_id: string;
    quote: string;
  };
}

export interface IThreadMessage {
  id: string;
  created_at: number;
  role: 'user' | 'assistant';
  expertFileMap: Record<string, string>;
  expert_file_ids: string[];
  content: {
    type: 'text';
    text: {
      value: string;
      annotations: IAnnotation[];
    };
  }[];
}

export enum AssistantStatus {
  Pending = 'pending',
  Active = 'active',
  Failed = 'failed',
}
