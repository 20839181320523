import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { IExpert } from '../../../../shared/models/expert.interface';

@Pipe({ name: 'expertListValue' })
export class ExpertListValuePipe implements PipeTransform {
  transform(expert: IExpert, field: keyof IExpert): string {
    switch (field) {
      case 'firstName':
        return `${expert.firstName} ${expert.lastName}`.trim();

      case 'lastUpdated':
        return moment(expert.lastUpdated * 1000).format('ddd MMM DD YYYY, LT');

      case 'callTime':
        return moment(expert.callTime).format('ddd MMM DD YYYY, LT');

      default:
        return this.defaultTransform(expert[field]);
    }
  }

  private defaultTransform(value: IExpert[keyof IExpert]): string {
    if (Array.isArray(value)) {
      return value.flatMap((v) => v).join(', ');
    }

    return String(value);
  }
}
