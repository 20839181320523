import { ModuleWithProviders, NgModule } from '@angular/core';
import { LOCAL_STORAGE } from '@techspert-io/auth';
import { OpportunityNavigationService } from '../services/opportunityNavigation.service';

@NgModule({
  providers: [OpportunityNavigationService],
})
export class NavigationTestingModule {
  static forTest(): ModuleWithProviders<NavigationTestingModule> {
    return {
      ngModule: NavigationTestingModule,
      providers: [
        {
          provide: LOCAL_STORAGE,
          useValue: {
            getItem: (): string => '{}',
            setItem: (): void => void 0,
          },
        },
      ],
    };
  }
}
