import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  InjectionToken,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { ExpertFile } from '@techspert-io/expert-files';
import { MarkedOptions, marked } from 'marked';
import { AssistantFilesService } from '../services/assistantFiles.service';

const copySvg = `<svg id="copy-snippet" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M384 336H192c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16l140.1 0L400 115.9V320c0 8.8-7.2 16-16 16zM192 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H192c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V416H272v32c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16H96V128H64z"/></svg>`;
const downloadSvg = `<svg id="download-snippet" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/></svg>`;

type Marked = (
  src: string,
  options?: MarkedOptions & {
    async: true;
  }
) => string;

export const MARKED = new InjectionToken<Marked>('WORKBOOK');

@Directive({
  selector: '[appPromptResponse]',
  providers: [
    {
      provide: MARKED,
      useValue: marked,
    },
  ],
})
export class PromptResponseDirective implements OnInit {
  @Input('appPromptResponse') value: string;
  @Input() files: ExpertFile[];
  @Input() externalFileMap: Record<string, ExpertFile> = {};
  @Input() copySvg = copySvg;
  @Input() downloadSvg = downloadSvg;

  @Output() tabChange = new EventEmitter<number>();

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private assistantFilesService: AssistantFilesService,
    @Inject(MARKED) private marked: Marked
  ) {}

  ngOnInit(): void {
    const adjustedValue = this.value
      .replace(/【([0-9]+)†source】/g, '')
      .replace(/【([0-9]+)†file】/g, '')
      .replace(/【\d+:\d+†source】/g, '')
      .replace(/【\d+:\d+†file】/g, '')
      .replace(/【([0-9]+)†(.*?)】/g, (r) => `【${r.split('†')[1]}`)
      .replace(/【\d+:\d+†(.*?)】/g, (r) => `【${r.split('†')[1]}`);

    const timestamps = [
      ...new Set(adjustedValue.match(/(\d{2}:\d{2}:\d{2}\.\d{3})/g)),
    ];

    const baseAttributes =
      'href="#" style="color: blue;" title="Click to load transcript and call recording at this timecode"';

    const reducedValue = timestamps.reduce(
      (acc, timestamp) =>
        acc.replace(
          RegExp(timestamp, 'g'),
          `<a id="${timestamp}" ${baseAttributes}>${timestamp}</a>`
        ),
      adjustedValue
    );

    const replacedValue = this.files.reduce(
      (acc, file) =>
        acc.replace(
          RegExp(`${file.fileId}.txt|\`${file.fileId}.txt\``, 'g'),
          `<a id="${file.fileId}" ${baseAttributes}>${file.fileName}.txt</a>`
        ),
      reducedValue
    );

    const replacedValueWithOpenAi = Object.entries(this.externalFileMap).reduce(
      (acc, [key, value]) =>
        acc.replace(
          RegExp(`${key}|\`${key}\``, 'gi'),
          `<a id="${value.fileId}" ${baseAttributes}>${value.fileName}.txt</a>`
        ),
      replacedValue
    );

    const replacedMarkdown = replacedValueWithOpenAi.replace(
      /```([\s\S]*?)```/g,
      (data) =>
        [
          `<div class="code-sample-body">`,
          `<pre>${data.replace(/```/g, '').trim()}</pre>`,
          `<div class="button-container">`,
          this.copySvg,
          this.downloadSvg,
          `</div>`,
          `</div>`,
        ].join('')
    );

    this.renderer.setProperty(
      this.el.nativeElement,
      'innerHTML',
      this.marked(replacedMarkdown).trim()
    );
  }

  @HostListener('click', ['$event'])
  clickLink(event: MouseEvent): void {
    const target = event.target as HTMLElement;

    event.preventDefault();

    const element =
      target.tagName.toLowerCase() === 'path' ? target.parentElement : target;

    if (element.id === 'download-snippet') {
      return this.downloadSnippet(element);
    }

    if (element.id === 'copy-snippet') {
      return this.copySnippet(element);
    }

    const file = this.files.find((f) => target.id === f.fileId);

    if (file) {
      const audioFile =
        file.groupId &&
        this.files.find(
          (f) => f.groupId === file.groupId && f.type === 'zoomRecording'
        );

      this.assistantFilesService.setSelectedFile({
        transcriptFile: file,
        audioFile,
      });

      this.tabChange.emit(1);
    }

    if (!target.innerText.match(/^\d{2}:\d{2}:\d{2}\.\d{3}$/)) {
      return;
    }

    const fileGroupsIds = [
      ...new Set(this.files.filter((f) => f.groupId).map((f) => f.groupId)),
    ];

    if (fileGroupsIds.length === 1) {
      const closestTimestamp = target.innerText;

      const audioFile = this.files.find(
        (f) => f.groupId === fileGroupsIds[0] && f.type === 'zoomRecording'
      );

      this.assistantFilesService.setSelectedFile({
        transcriptFile: this.files.find(Boolean),
        audioFile,
        closestTimestamp,
      });

      this.tabChange.emit(1);
    } else {
      const closestTimestamp = target.innerText;

      const transcripts = this.files.filter(
        (f) => f.type === 'enhancedTranscript' || f.type === 'zoomTranscript'
      );

      const transcriptContent = transcripts.find((t) =>
        (this.assistantFilesService.transcriptCache[t.fileKey] || []).includes(
          closestTimestamp
        )
      );

      if (transcriptContent) {
        const audioFile = this.files.find(
          (f) =>
            f.groupId === transcriptContent.groupId &&
            f.type === 'zoomRecording'
        );

        this.assistantFilesService.setSelectedFile({
          transcriptFile: transcriptContent,
          audioFile,
          closestTimestamp,
        });

        this.tabChange.emit(1);
      }
    }
  }

  private downloadSnippet(target: HTMLElement) {
    const blob = new Blob([target.parentElement.parentElement.textContent], {
      type: 'text/csv;charset=utf-8;',
    });

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.href = url;
    a.download = 'ECHO-output.csv';
    a.click();
  }

  private copySnippet(target: HTMLElement) {
    navigator.clipboard.writeText(
      target.parentElement.parentElement.textContent
    );
  }
}
