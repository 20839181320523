import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-on-hold-svg',
  template: `<svg
    [style.width]="large ? '28px' : '24px'"
    [style.height]="large ? '28px' : '24px'"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M9,16 L11,16 L11,8 L9,8 L9,16 L9,16 Z M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 L12,2 Z M12,20 C7.59,20 4,16.41 4,12 C4,7.59 7.59,4 12,4 C16.41,4 20,7.59 20,12 C20,16.41 16.41,20 12,20 L12,20 Z M13,16 L15,16 L15,8 L13,8 L13,16 L13,16 Z"
        id="path-1"
      ></path>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <mask id="mask-2" [style.fill]="colour">
        <use xlink:href="#path-1"></use>
      </mask>
      <use id="Colour" [style.fill]="colour" xlink:href="#path-1"></use>
    </g>
  </svg>`,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class OnHoldSvgComponent {
  @Input() colour = 'white';
  @Input() large = false;
}
