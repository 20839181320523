import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { IExpert } from '../../../../../shared/models/expert.interface';

interface IChanges<T> extends SimpleChange {
  previousValue: T;
  currentValue: T;
}
interface IExpertTimelineChanges extends SimpleChanges {
  expert: IChanges<IExpert>;
  viewStage: IChanges<string>;
}

export interface IExpertTimelineItem {
  icon: string;
  title: string;
  dateTime: string;
  name?: string;
  class: string;
}

@Component({
  selector: 'app-expert-timeline',
  templateUrl: './expert-timeline.component.html',
  styleUrls: ['./expert-timeline.component.scss'],
})
export class ExpertTimelineComponent implements OnInit, OnChanges {
  @Input() expert: IExpert;
  @Input() viewStage: string;

  items: IExpertTimelineItem[] = [];

  ngOnInit(): void {
    this.items = this.constructItems();
  }

  ngOnChanges(sc: IExpertTimelineChanges): void {
    if (
      sc.viewStage?.currentValue !== sc.viewStage?.previousValue ||
      JSON.stringify(sc.expert?.currentValue) !==
        JSON.stringify(sc.expert?.previousValue)
    ) {
      this.items = this.constructItems();
    }
  }

  private constructItems(): IExpertTimelineItem[] {
    const approval = this.expert.clientApproved
      ? [
          {
            icon: 'lock_open',
            title: 'Approved',
            dateTime: this.expert.clientApproved.dateCreated,
            name: `${this.expert.clientApproved.firstName || ''} ${
              this.expert.clientApproved.lastName || ''
            }`.trim(),
            class: 'approval-timeline-item',
          },
        ]
      : [];
    const acceptance = this.expert.clientAccepted
      ? [
          {
            icon: 'done',
            title: 'Accepted',
            dateTime: this.expert.clientAccepted.dateCreated,
            name: `${this.expert.clientAccepted.firstName || ''} ${
              this.expert.clientAccepted.lastName || ''
            }`.trim(),
            class: 'accepted-timeline-item',
          },
        ]
      : [];
    const upcommingConnection =
      this.expert.callTime && this.viewStage === 'accepted'
        ? [
            {
              icon: 'schedule',
              title: 'Scheduled for',
              dateTime: this.expert.callTime,
              class: 'pending-connection-timeline-item',
            },
          ]
        : [];
    const completedConnection =
      this.expert.callTime && this.viewStage === 'completed'
        ? [
            {
              icon: 'check_circle',
              title: 'Completed on',
              dateTime: this.expert.callTime,
              class: 'completed-connection-timeline-item',
            },
          ]
        : [];

    return [
      ...approval,
      ...acceptance,
      ...upcommingConnection,
      ...completedConnection,
    ];
  }
}
