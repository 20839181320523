import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-reason-rejection-dialog',
  templateUrl: './rejection-reason-dialog.component.html',
})
export class ReasonRejectionDialogComponent {
  rejectionReason: string;

  constructor(
    private dialogRef: MatDialogRef<ReasonRejectionDialogComponent>
  ) {}

  confirm(): void {
    this.dialogRef.close({
      rejectionReason: this.rejectionReason,
      rejected: true,
    });
  }
}
