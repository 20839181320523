import { Component, Input } from '@angular/core';
import { IExpert } from '../../../../../shared/models/expert.interface';

@Component({
  selector: 'app-expert-unit',
  templateUrl: './expert-unit.component.html',
  styleUrls: ['./expert-unit.component.scss'],
})
export class ExpertUnitComponent {
  @Input() expert: IExpert;

  unitsMapping: Record<string, string> = {
    '=1': '1 unit',
    other: '# units',
  };
}
