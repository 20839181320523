import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[audioTime]',
})
export class AudioTimeDirective implements OnInit, OnChanges {
  @Input() audioTime: number;

  constructor(private elementRef: ElementRef<HTMLAudioElement>) {}

  ngOnInit() {
    this.elementRef.nativeElement.currentTime = this.audioTime;
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.audioTime) {
      this.elementRef.nativeElement.currentTime =
        simpleChanges.audioTime.currentValue;
    }
  }
}
