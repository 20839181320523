import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-expert-expand-all-toggle',
  template: `<ng-container>
    <button
      [matTooltip]="collapsed ? 'Expand all' : 'Collapse all'"
      (click)="toggle.emit()"
    >
      <svg
        width="20px"
        height="20px"
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        *ngIf="!collapsed"
      >
        <g
          id="Symbols"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="table-header"
            transform="translate(-18.000000, -18.000000)"
            fill="#B4BECB"
          >
            <g id="Group" transform="translate(16.000000, 16.000000)">
              <rect id="Rectangle" x="2" y="13" width="20" height="2"></rect>
              <rect
                id="Rectangle-Copy-2"
                x="2"
                y="9"
                width="20"
                height="2"
              ></rect>
              <polygon
                id="Path"
                transform="translate(11.899495, 20.263456) rotate(-45.000000) translate(-11.899495, -20.263456) "
                points="12.3994949 19.763456 12.3994949 22.763456 14.3994949 22.763456 14.3994949 17.763456 9.39949494 17.763456 9.39949494 19.763456"
              ></polygon>
              <polygon
                id="Path"
                transform="translate(11.899495, 3.535534) rotate(-45.000000) translate(-11.899495, -3.535534) "
                points="11.3994949 4.03553391 11.3994949 1.03553391 9.39949494 1.03553391 9.39949494 6.03553391 14.3994949 6.03553391 14.3994949 4.03553391"
              ></polygon>
            </g>
          </g>
        </g>
      </svg>

      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        *ngIf="collapsed"
        data-test-id="expert-expand-all"
      >
        <g
          id="ic_expand"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <path
            d="M4,20 L20,20 L20,22 L4,22 L4,20 Z M4,2 L20,2 L20,4 L4,4 L4,2 Z M13,9 L16,9 L12,5 L8,9 L11,9 L11,15 L8,15 L12,19 L16,15 L13,15 L13,9 Z"
            id="Shape"
            fill="#B4BECB"
          ></path>
        </g>
      </svg></button
  ></ng-container>`,
  styles: [
    `
      button {
        border: none;
        background: transparent;
        cursor: pointer;
        display: flex;
        padding-left: 0;
      }
    `,
  ],
})
export class ExpertExpandAllToggleComponent {
  @Input() collapsed: boolean;
  @Output() toggle = new EventEmitter<void>();
}
